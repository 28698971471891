import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../BoatOnComponent'
import AppRoute from '../../../constants/AppRoute'
import { history } from '../../../helpers'
import BoatOnModal from '../../common/BoatOnModal'
import BoatOnModalCore from '../../common/BoatOnModalCore'
import BoatOnAlert from '../../common/UsefullComponents/BoatOnAlert'
import dictionary from './Dictionary/BoatOnBlockDico'
import { groupActions } from '../../../actions'

const pageRedirects = [
    {
        blockId: 1,
        link: AppRoute.LogBook.Informations,
    },
    {
        blockId: 36,
        link: AppRoute.LogBook.Inventory,
    },
    {
        blockId: 32,
        link: AppRoute.LogBook.Fleet,
    },
    {
        blockId: 35,
        link: AppRoute.LogBook.Settings.MyUsers,
    },
    {
        blockId: 20,
        link: AppRoute.LogBook.Repair,
    },
    {
        blockId: 7,
        link: AppRoute.LogBook.ExpensesAndIncome,
    },
    {
        blockId: 16,
        link: AppRoute.LogBook.Informations,
    },
    {
        blockId: 47,
        link: AppRoute.LogBook.OrderForm,
    },
    {
        blockId: 41,
        link: AppRoute.LogBook.Home,
    },
    {
        blockId: 54,
        link: AppRoute.LogBook.Calendar,
    },
    {
        blockId: 54, //TODO
        link: AppRoute.LogBook.WorkTime,
    },
]

class BoatOnBlock extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModal: null,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    componentDidUpdate() {
        // if(this.props.selectedGroup?.id !== this.props.currentGroupId){
        //     groupActions.changeCurrentGroup(this.props.currentGroupId)        }
    }

    handleCloseModal() {
        this.setState({ openModal: null })
        this.historyPush(history, AppRoute.LogBook.Pricing)
    }

    _openAccessModal() {
        this.setState({ openModal: `openModalNoAccess` })
    }

    _getAccessRights(blockIds) {
        const { selectedGroup, onlyOneId, addedCondition } = this.props
        if (Array.isArray(blockIds)) {
            let access = false
            for (const blockId of blockIds) {
                const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                    block => block?.blocType?.id === blockId,
                )
                if (
                    !onlyOneId &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(blockId)))
                ) {
                    access = false
                    break
                } else if (
                    onlyOneId &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(blockId)))
                ) {
                    access = false
                } else if (
                    onlyOneId &&
                    userAccessToBlock?.access === true &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(blockId)))
                ) {
                    access = true
                    break
                }
                if (blockId === 66) console.info(access)
            }
            return { access }
        } else {
            const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                block => block?.blocType?.id === blockIds,
            )
            if (userAccessToBlock?.access !== undefined)
                return {
                    access: userAccessToBlock.access,
                }
        }
        return { error: true }
    }

    _findRedirection() {
        const { selectedGroup } = this.props
        const userRoleAccess = selectedGroup?.userRole?.userRoleAccess

        if (
            !userRoleAccess ||
            !Array.isArray(userRoleAccess) ||
            userRoleAccess.length === 0
        )
            return null

        const redirect = pageRedirects.find(pageRedirect =>
            userRoleAccess.find(
                access =>
                    access?.blocType?.id &&
                    pageRedirect.blockId === access?.blocType?.id &&
                    access.access,
            ),
        )

        if (!redirect) return null
        return redirect.link
    }

    bodyModal() {
        return (
            <BoatOnAlert
                severity={`info`}
                description={this.displayText('contactYourAdmin')}
            />
        )
    }

    _renderModalNoAccess() {
        const { openModal } = this.state

        return (
            <BoatOnModal
                openCondition={openModal}
                handleClose={this.handleCloseModal}
                titles={{
                    openModalNoAccess: this.displayText('noAccess'),
                }}
                modalCores={{
                    openModalNoAccess: (
                        <BoatOnModalCore
                            body={this.bodyModal()}
                            actions={[
                                {
                                    label: this.displayText('understand'),
                                    action: this.handleCloseModal,
                                    minWidth: `120px`,
                                },
                            ]}
                        />
                    ),
                }}
                maxWidth={{ openModalNoAccess: `sm` }}
            />
        )
    }

    render() {
        const {
            blockIds,
            children,
            blockProps,
            page,
            selectedGroup,
            childrenNoAccess,
        } = this.props
        const { openModal } = this.state

        if (openModal === `openModalNoAccess`)
            return this._renderModalNoAccess()

        if (!selectedGroup) return null

        if (blockIds === null) {
            console.error(
                `This access to this block is not defined because their is no blockIds`,
            )
            return null
        }

        if (!children) {
            console.error(`The children is not defined`)
            return null
        }

        //const { access, error } = this._getAccessRights(blockIds)
        const access = true
        const error = false

        if (error) {
            console.error(`This blockIds doesn't exist ${blockIds}`)
            if (childrenNoAccess) {
                return childrenNoAccess
            } else {
                return null
            }
        }

        if (access === true) {
            if (blockProps) return <div {...blockProps}>{children}</div>
            return children
        } else if (!access && page === true) {
            const redirect = this._findRedirection()
            if (redirect === null) {
                this._openAccessModal()
            } else {
                this.historyPush(history, redirect)
            }
        }
        return null
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        selectedGroup: state.block.selectedGroup,
        currentGroupId: state.group.currentGroupId,
        groupLoading: state.group.loading,
        groups: state.group.groupsMembers,
    }
}

export default connect(mapStateToProps)(BoatOnBlock)
