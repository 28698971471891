import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/WorkTimePageCss'
import WorkTimePageDico from './Dictionary/WorkTimePageDico'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import { deleteAbsence, filterActions, typesActions } from '../../../../actions'
import PersonIcon from '@mui/icons-material/Person'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button as ButtonMui,
    Checkbox,
    Divider,
    IconButton,
    Popover,
    Tooltip,
    Typography,
} from '@material-ui/core'
import BobFilter from '../BobFilter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { Button as ButtonBON } from '../../../common/BoatOnButton'
import BoatOnModal from '../../../common/BoatOnModal'
import WorkTimeModal from './Modal/WorkTimeModal'
import AbsenceModal from './Modal/AbsenceModal'
import dayjs from 'dayjs'
import Chronometre from './Chronometre/Chronometre'
import {
    downloadTimesheetWorktimePdf,
    workTimeActions,
} from '../../../../actions/bob/workTime.actions'
import anchorLoader from '../../../../images/anchorLoader.gif'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import moment from 'moment'
import { settingsActions } from '../../../../actions/bob/settings.actions'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import VisibilityIcon from '@mui/icons-material/Visibility'
import WarningIcon from '@mui/icons-material/Warning'
import { absencesActions } from '../../../../actions/bob/absences.actions'
import worktimesUtils, {
    DEFAULT_NB_HOURS_PER_DAY,
} from '../../../../utils/worktimesUtils'
import { redirectActions } from '../../../../actions/redirect.actions'
import WorkTimeBar from './Bar/WorkTimeBar'
import ValidTimesheetExportModal from './Modal/ValidTimesheetExportModal'
import BoatOnAlert from '../../../common/UsefullComponents/BoatOnAlert'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)

class WorkTimePage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = WorkTimePageDico

        this.state = {
            selectedItem: null,
            openModal: null,
            isActionMenuOpen: false,
            expandedWeekAccordion: [], // Stocke par numéro de semaine les accordéons ouverts
            structuredData: {},
            data: [],
            userGroup: null,
            userPaidVacationsData: null,
            worktimesExportOptions: [],
            isAllChecked: false,
            canExport: false,
            selectedModal: false,
        }

        this._renderDaysOffTaken = this._renderDaysOffTaken.bind(this)
        this._renderDaysOffToTake = this._renderDaysOffToTake.bind(this)
        this._renderDaysOffPending = this._renderDaysOffPending.bind(this)
        this._renderActionMenuPopover = this._renderActionMenuPopover.bind(this)
        this.openActionMenu = this.openActionMenu.bind(this)
        this.closeActionMenu = this.closeActionMenu.bind(this)
        this.handleAccordionChange = this.handleAccordionChange.bind(this)
        this.handleAddWorkTime = this.handleAddWorkTime.bind(this)
        this.handleAddLeave = this.handleAddLeave.bind(this)
        this.checkWeek = this.checkWeek.bind(this)
        this.checkDay = this.checkDay.bind(this)
        this._formatDate = this._formatDate.bind(this)
        this._formatTime = this._formatTime.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleCloseTimesheetModal = this.handleCloseTimesheetModal.bind(
            this,
        )
        this.checkAll = this.checkAll.bind(this)
        this.handleUnfoldAll = this.handleUnfoldAll.bind(this)
        this.getCurrentUserLink = this.getCurrentUserLink.bind(this)
        this.getUserLinkById = this.getUserLinkById.bind(this)
        this.renderWeeksAccordions = this.renderWeeksAccordions.bind(this)
        this.downloadTimeSheet = this.downloadTimeSheet.bind(this)
        this.validateExport = this.validateExport.bind(this)
    }

    componentDidMount() {
        const {
            user,
            groupMembers,
            groupId,
            paidVacationsAllowed,
            dispatch,
        } = this.props
        // Récupération des absences et worktime
        dispatch(typesActions.requestLeaveTypes())
        if (groupMembers.length > 0 && user) {
            const userLink = this.getCurrentUserLink()

            // Récupération des heures de travail et absences de l'utilisateur
            dispatch(absencesActions.requestUserAbsences(userLink.id))
            dispatch(absencesActions.requestUserAbsencesByManager(userLink.id))
            dispatch(workTimeActions.getWorkTimes(userLink.id))
            dispatch(workTimeActions.getWorkTimesByManager(userLink.id))

            // récupération du total des jours de congés posés
            dispatch(absencesActions.getTotalAbsenceForUserLink(userLink.id))
        }

        // Récupération des réglages des temps de travail et des congés payés
        if (groupId) {
            dispatch(
                settingsActions.getGroupWorkTimeSettings({ groupId: groupId }),
            )
            dispatch(
                settingsActions.getPaidVacationsSettings({ groupId: groupId }),
            )
        }

        if (paidVacationsAllowed === null)
            dispatch(absencesActions.getUserLinkPaidVacationsAllowed)

        // Ouvre l'accordéon de la semaine actuelle
        const expandedWeekAccordion = [dayjs().year() + '-' + dayjs().isoWeek()]
        this.setState({ expandedWeekAccordion })
    }

    _addDayToStructuredData(acc, data, year, weekNumber) {
        if (!acc?.[year]) {
            acc[year] = {}
        }
        if (!acc?.[year]?.[weekNumber]) {
            acc[year][weekNumber] = []
        }

        acc[year][weekNumber].push(data)

        return acc
    }

    _generateStructureData(acc, data) {
        let startDate = new Date(data.delimitedDate.startDate)
        const endDate = new Date(data.delimitedDate.endDate)
        const startWeekNumber = dayjs(startDate).isoWeek()
        const endWeekNumber = dayjs(endDate).isoWeek()
        let nbDiffWeeks =
            endWeekNumber < startWeekNumber
                ? dayjs(startDate).isoWeeksInYear() -
                  startWeekNumber +
                  endWeekNumber
                : endWeekNumber - startWeekNumber
        let weekNumber = dayjs(startDate).isoWeek()
        let year = dayjs(startDate).year()

        do {
            acc = this._addDayToStructuredData(acc, data, year, weekNumber)
            startDate = dayjs(startDate).add(1, 'week')
            weekNumber = dayjs(startDate).isoWeek()
            year = dayjs(startDate).year()
        } while (nbDiffWeeks-- > 0)

        return acc
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            absencesLoading,
            groupMembers,
            user,
            absences,
            absencesManager,
            workTimes,
            workTimesManager,
            groupId,
            subscriptions,
            groupLoading,
            workTimeGroups,
            paidVacationSettings,
            groupPaidVacationsAllowed,
            groupAbsences,
            group,
            redirectParams,
            redirectEvent,
        } = this.props
        const { userGroup, openModal } = this.state

        // Redirection vers l'inventaire si l'utilisateur n'a pas les droits
        if (group && subscriptions.length === 0 && groupLoading < 1) {
            return this.historyPush(history, AppRoute.LogBook.Inventory)
        }

        if (
            groupMembers &&
            groupMembers.length > 0 &&
            user &&
            (groupMembers.length !== prevProps.groupMembers.length ||
                user !== prevProps.user)
        ) {
            const userLink = this.getCurrentUserLink()

            // Récupération des heures de travail et absences de l'utilisateur
            this.props.dispatch(
                absencesActions.requestUserAbsences(userLink.id),
            )
            this.props.dispatch(
                absencesActions.requestUserAbsencesByManager(userLink.id),
            )
            this.props.dispatch(workTimeActions.getWorkTimes(userLink.id))
            this.props.dispatch(
                workTimeActions.getWorkTimesByManager(userLink.id),
            )

            return this.setState({
                userLink,
            })
        }
        // Récupération des congés payés autorisés pour tous les membres du groupe
        if (
            groupMembers?.length > 0 &&
            paidVacationSettings &&
            Object.keys(paidVacationSettings).length !== 0 &&
            absencesLoading === 0 &&
            (!groupPaidVacationsAllowed || groupId !== prevProps.groupId)
        ) {
            return this.props.dispatch(
                absencesActions.getUserLinkPaidVacationsAllowed(
                    groupMembers,
                    paidVacationSettings,
                ),
            )
        }

        // Récupération de toutes les absences pour tous les membres du groupe
        if (group && !groupAbsences && absencesLoading === 0) {
            return this.props.dispatch(
                absencesActions.getAllAbsencesForGroupMembers({
                    group: group,
                }),
            )
        }

        // Création de la structure des données
        // En fusionant les absences, absences manager, worktimes et worktimes manager
        if (
            prevProps.absences !== absences ||
            prevProps.absencesManager !== absencesManager ||
            prevProps.workTimes !== workTimes ||
            prevProps.workTimesManager !== workTimesManager
        ) {
            const mergedAbsences = (absences || [])
                .concat(absencesManager || [])
                // Suppression des doublons
                // Pour empecher les evt créés et assignés au même user de s'afficher 2 fois
                .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
                .map(a => {
                    return {
                        ...a,
                        type: 'absence',
                        delimitedDate: {
                            ...a.delimitedDate,
                            startDate: new Date(
                                a.delimitedDate.startDate,
                            ).setHours(0, 0, 0, 0),
                            endDate: new Date(a.delimitedDate.endDate).setHours(
                                0,
                                0,
                                0,
                                0,
                            ),
                        },
                    }
                })

            const mergedWorkTimes = (workTimes || [])
                .concat(
                    workTimesManager?.map(workTime => ({
                        ...workTime,
                        management: true,
                    })) || [],
                )
                .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
                .filter(wt => {
                    //Affiche uniquement les wt qui ont des données correctes
                    return (
                        wt.delimitedDate.startDate && wt.delimitedDate.endDate
                    )
                })
                .map(w => {
                    // Transformation des heures string en date pour faciliter les comparaisons
                    return {
                        ...w,
                        type: 'workTime',
                        delimitedDate: {
                            ...w.delimitedDate,
                            startDate: new Date(
                                w.delimitedDate.startDate,
                            ).setHours(0, 0, 0, 0),
                            endDate: new Date(w.delimitedDate.endDate).setHours(
                                0,
                                0,
                                0,
                                0,
                            ),
                        },
                        startTime: new dayjs()
                            .hour(w.startTime.split(':')[0])
                            .minute(w.startTime.split(':')[1])
                            .second(w.startTime.split(':')[2]),
                        endTime: new dayjs()
                            .hour(w.endTime?.split(':')[0])
                            .minute(w.endTime?.split(':')[1])
                            .second(w.endTime?.split(':')[2]),
                        breakTimes: w.breakTimes
                            .map(b => {
                                return {
                                    id: b.id,
                                    startTime: new dayjs()
                                        .hour(b.startTime.split(':')[0])
                                        .minute(b.startTime.split(':')[1])
                                        .second(b.startTime.split(':')[2]),
                                    endTime: b.endTime
                                        ? new dayjs()
                                              .hour(b.endTime?.split(':')[0])
                                              .minute(b.endTime?.split(':')[1])
                                              .second(b.endTime?.split(':')[2])
                                        : null,
                                }
                            })
                            // Tri les pauses par date de début
                            .sort((b1, b2) =>
                                b1.startTime.diff(b2.startTime) > 0 ? 1 : -1,
                            ),
                    }
                })
            // Trier les événements par ordre chronologique décroissant
            // et grouper par numéro de semaine
            const data = mergedAbsences
                .concat(mergedWorkTimes)
                .sort((d1, d2) => {
                    return d1.delimitedDate.startDate <
                        d2.delimitedDate.startDate
                        ? 1
                        : -1
                })

            const structuredData = data.reduce((acc, data) => {
                acc = this._generateStructureData(acc, data)
                return acc
            }, {})

            const worktimesExportOptions = []

            data.forEach(worktime => {
                worktimesExportOptions.push({
                    id: worktime.id,
                    DateOptions: [],
                })
            })

            this.setState({
                structuredData: structuredData,
                data: data,
                worktimesExportOptions: worktimesExportOptions,
            })
        }

        // Récupération des réglages des temps de travail et des congés payés
        if (groupId && groupId !== prevProps.groupId) {
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: groupId,
                }),
            )

            this.props.dispatch(
                settingsActions.getPaidVacationsSettings({
                    groupId: groupId,
                }),
            )
        }

        // Récupération des settings du groupe de l'utilisateur
        // Ou le groupe par défaut
        if (
            workTimeGroups?.length &&
            (workTimeGroups !== prevProps.workTimeGroups || !userGroup)
        ) {
            const user = this.getCurrentUserLink()
            if (user) {
                const userGroup =
                    workTimeGroups.find(wtg =>
                        wtg.userLinks.find(ul => ul.id === user.id),
                    ) ?? workTimeGroups.find(wtg => wtg.byDefault)
                if (userGroup) {
                    this.setState({
                        userGroup: userGroup,
                    })
                }
            }
        }

        // Calcul et tri des congés payés
        if (
            groupPaidVacationsAllowed &&
            userGroup &&
            absencesLoading === 0 &&
            groupLoading === 0 &&
            (!this.state.userPaidVacationsData ||
                groupPaidVacationsAllowed !==
                    prevProps.groupPaidVacationsAllowed ||
                userGroup !== prevState.userGroup ||
                absences !== prevProps.absences)
        ) {
            this.setState({
                userPaidVacationsData: worktimesUtils.calculatePaidVacations(
                    this.getCurrentUserLink(),
                    groupPaidVacationsAllowed,
                    absences ?? [],
                    userGroup,
                    paidVacationSettings,
                ),
            })
        }

        // Si une redirection est en cours
        // Et que tous les chargements sont terminés
        // Et que la modale n'est pas ouverte
        // Ouverture de la modale
        if (
            redirectParams?.contentType &&
            redirectParams?.contentId &&
            redirectEvent &&
            // absencesLoading === 0 &&
            // workTimesLoading === 0 &&
            groupLoading === 0 &&
            openModal === null
            // &&
            // workTimes !== null &&
            // absences !== null
        ) {
            if (redirectParams.contentType === 'WORKTIME') {
                this.setState({
                    openModal: 'workTime',
                    selectedItem: {
                        ...redirectEvent,
                        delimitedDate: {
                            ...redirectEvent.delimitedDate,
                            startDate: new Date(
                                redirectEvent.delimitedDate.startDate,
                            ).setHours(0, 0, 0, 0),
                            endDate: new Date(
                                redirectEvent.delimitedDate.endDate,
                            ).setHours(0, 0, 0, 0),
                        },
                        startTime: new dayjs()
                            .hour(redirectEvent.startTime.split(':')[0])
                            .minute(redirectEvent.startTime.split(':')[1])
                            .second(redirectEvent.startTime.split(':')[2]),
                        endTime: new dayjs()
                            .hour(redirectEvent.endTime.split(':')[0])
                            .minute(redirectEvent.endTime.split(':')[1])
                            .second(redirectEvent.endTime.split(':')[2]),
                        breakTimes: redirectEvent.breakTimes
                            .map(b => {
                                return {
                                    id: b.id,
                                    startTime: new dayjs()
                                        .hour(b.startTime.split(':')[0])
                                        .minute(b.startTime.split(':')[1])
                                        .second(b.startTime.split(':')[2]),
                                    endTime: new dayjs()
                                        .hour(b.endTime.split(':')[0])
                                        .minute(b.endTime.split(':')[1])
                                        .second(b.endTime.split(':')[2]),
                                }
                            })
                            // Tri les pauses par date de début
                            .sort((b1, b2) =>
                                b1.startTime.diff(b2.startTime) > 0 ? 1 : -1,
                            ),
                    },
                })
                this.props.dispatch(redirectActions.resetRedirectionParams())
            } else if (redirectParams.contentType === 'ABSENCE') {
                this.setState({
                    openModal: 'absence',
                    selectedItem: {
                        ...redirectEvent,
                        type: 'absence',
                        delimitedDate: {
                            ...redirectEvent.delimitedDate,
                            startDate: new Date(
                                redirectEvent.delimitedDate.startDate,
                            ).setHours(0, 0, 0, 0),
                            endDate: new Date(
                                redirectEvent.delimitedDate.endDate,
                            ).setHours(0, 0, 0, 0),
                        },
                    },
                })
                this.props.dispatch(redirectActions.resetRedirectionParams())
            }
        }
    }

    handleAddWorkTime() {
        this.setState({
            openModal: 'workTime',
        })
    }

    handleAddLeave() {
        this.setState({
            openModal: 'absence',
        })
    }

    handleCloseModal() {
        const { groupMembers, group, paidVacationSettings } = this.props

        this.setState({
            openModal: null,
            selectedItem: null,
        })

        this.props.dispatch(
            absencesActions.getUserLinkPaidVacationsAllowed(
                groupMembers,
                paidVacationSettings,
            ),
        )
        this.props.dispatch(
            absencesActions.getAllAbsencesForGroupMembers({
                group: group,
            }),
        )
    }

    handleCloseTimesheetModal() {
        this.setState({
            openModal: null,
            selectedItem: null,
        })
    }

    getCurrentUserLink() {
        const { groupMembers, user } = this.props
        const { userLink } = this.state

        if (userLink) {
            return userLink
        }

        const ul = groupMembers.find(
            gm =>
                (user?.email && gm?.user?.email === user.email) ||
                gm?.userSubscribe?.mail === user.email,
        )

        if (ul && !userLink) {
            this.setState({
                userLink: ul,
            })
        }

        return ul
    }

    getUserLinkById(id) {
        const { groupMembers } = this.props
        return groupMembers.find(gm => gm.id === id)
    }

    /**
     * Ajoute ou enlève le numéro de la semaine cliquée à la liste des semaines ouvertes
     * @param {*} yearWeekNumber
     */
    handleAccordionChange(yearWeekNumber) {
        const { expandedWeekAccordion } = this.state

        let newState = [...expandedWeekAccordion]
        const index = newState.indexOf(yearWeekNumber)

        if (index > -1) {
            newState.splice(index, 1)
        } else {
            newState.push(yearWeekNumber)
        }

        this.setState({
            expandedWeekAccordion: newState,
        })
    }

    _renderDaysOffTaken() {
        const { paidVacationSettings } = this.props
        const { userPaidVacationsData } = this.state

        let nbMinutes = 0
        if (
            this.props.absences?.length &&
            paidVacationSettings &&
            Object.keys(paidVacationSettings).length !== 0 &&
            userPaidVacationsData
        ) {
            nbMinutes = userPaidVacationsData.reduce((acc, a) => {
                return acc + a.absenceTime
            }, 0)
        }

        return this._renderDayOffCard(
            nbMinutes,
            '#6BC070',
            this.displayText('days_off_taken'),
        )
    }

    _renderDayOffCard(minutesTaken, color, text) {
        const { classes } = this.props

        const { days, hours, minutes } = worktimesUtils.minutesToWorkingDays(
            minutesTaken,
        )

        return (
            <div
                className={classes.card}
                style={{
                    background: color,
                }}
            >
                <p className={classes.cardValue}>
                    {`${days} ${this.displayText(
                        'days',
                    )} ${hours} ${this.displayText(
                        'hours',
                    )} ${minutes} ${this.displayText('minutes')}`}
                </p>
                <p className={classes.cardLabel}>{text}</p>
            </div>
        )
    }

    _renderDaysOffToTake() {
        const { paidVacationSettings } = this.props
        const { userPaidVacationsData } = this.state

        let nbMinutes = 0
        if (
            paidVacationSettings &&
            Object.keys(paidVacationSettings).length !== 0 &&
            userPaidVacationsData
        ) {
            nbMinutes = userPaidVacationsData.reduce((acc, a) => {
                return acc + (a.allowed - a.absenceTime)
            }, 0)
        }

        return this._renderDayOffCard(
            nbMinutes,
            '#5FA7F6',
            this.displayText('days_off_remaining'),
        )
    }

    _renderDaysOffPending() {
        // Récupération de toutes les absences en attente de validation
        const absences = this.props?.absences
            ? this.props.absences.filter(
                  absence =>
                      absence.absenceReasonType.id === 1 &&
                      absence.absenceStatus.id === 1,
              )
            : []

        // Calcul du nombre total de minutes d'absence
        const totalMinutes =
            !absences || absences.length === 0
                ? 0
                : absences.reduce((acc, currentValue) => {
                      const hoursInDay = DEFAULT_NB_HOURS_PER_DAY
                      const nbDays =
                          dayjs(currentValue.delimitedDate.endDate).diff(
                              dayjs(currentValue.delimitedDate.startDate),
                              'day',
                          ) + 1
                      if (!currentValue.absenceTime)
                          return acc + hoursInDay * 60 * nbDays
                      const startTime = moment(
                          currentValue.absenceTime.startTime,
                          'HH:mm',
                      )
                      const endTime = moment(
                          currentValue.absenceTime.endTime,
                          'HH:mm',
                      )
                      const diff = moment
                          .duration(endTime.diff(startTime))
                          .asMinutes()
                      return acc + diff * nbDays
                  }, 0)

        return this._renderDayOffCard(
            totalMinutes,
            '#F4BA5C',
            this.displayText('days_off_pending'),
        )
    }

    /**
     * Ouvre le menu d'action pour l'élément sélectionné
     */
    openActionMenu(e, item) {
        this.setState({
            anchorPopup: e.currentTarget,
            selectedItem: {
                ...item,
                delimitedDate: { ...item.delimitedDate },
                breakTimes: item.breakTimes ? [...item.breakTimes] : undefined,
            },
            isActionMenuOpen: true,
        })
    }

    closeActionMenu() {
        this.setState({
            isActionMenuOpen: false,
            selectedItem: null,
        })
    }

    checkDay(item, date, isChecked) {
        const { worktimesExportOptions, isAllChecked, data } = this.state
        const dataWorktimesExportOptions = [...worktimesExportOptions]

        const exportOption = dataWorktimesExportOptions.find(
            option => option.id === item.id,
        )
        if (exportOption) {
            if (isChecked) {
                if (
                    !exportOption.DateOptions.find(d =>
                        dayjs(d).isSame(date, 'date'),
                    )
                ) {
                    exportOption.DateOptions.push(date)
                }
            } else {
                exportOption.DateOptions = exportOption.DateOptions.filter(
                    d => !dayjs(d).isSame(date, 'date'),
                )
            }
        }

        if (!isChecked && isAllChecked) {
            this.setState({
                isAllChecked: false,
            })
        } else if (
            isChecked &&
            this._areAllDatesChecked(data, dataWorktimesExportOptions)
        ) {
            this.setState({
                isAllChecked: true,
            })
        }

        this.setState({
            worktimesExportOptions: dataWorktimesExportOptions,
        })
    }

    _isDataWorktimesExportOptionsNotEmpty() {
        const { worktimesExportOptions } = this.state
        return worktimesExportOptions.some(
            option => option.DateOptions.length > 0,
        )
    }

    _isDayChecked(item, date) {
        const { worktimesExportOptions } = this.state
        const exportOption = worktimesExportOptions.find(
            option => option.id === item.id,
        )
        if (exportOption) {
            return exportOption.DateOptions.some(d =>
                dayjs(d).isSame(date, 'date'),
            )
        }
        return false
    }

    checkWeek(items, yearNumber, weekNumber, isChecked) {
        const { worktimesExportOptions, isAllChecked, data } = this.state
        const dataWorktimesExportOptions = [...worktimesExportOptions]
        const filteredItems = items.filter(item => item.type !== 'absence')

        for (const item of filteredItems) {
            const datesInWeek = this._getDatesInWeek(
                item,
                yearNumber,
                weekNumber,
            )
            const exportOption = dataWorktimesExportOptions.find(
                option => option.id === item.id,
            )
            if (exportOption) {
                datesInWeek.forEach(date => {
                    if (isChecked) {
                        if (
                            !exportOption.DateOptions.find(d =>
                                dayjs(d).isSame(date, 'date'),
                            )
                        ) {
                            exportOption.DateOptions.push(date)
                        }
                    } else if (!isChecked) {
                        exportOption.DateOptions = exportOption.DateOptions.filter(
                            d => !dayjs(d).isSame(date, 'date'),
                        )
                    }
                })
            }
        }

        if (!isChecked && isAllChecked) {
            this.setState({
                isAllChecked: false,
            })
        } else if (
            isChecked &&
            this._areAllDatesChecked(data, dataWorktimesExportOptions)
        ) {
            this.setState({
                isAllChecked: true,
            })
        }

        this.setState({
            worktimesExportOptions: dataWorktimesExportOptions,
        })
    }

    _areAllItemsAbsences(items) {
        const allAbsences = items.every(item => item.type === 'absence')

        return !allAbsences
    }

    _areWeekDatesChecked(items, yearNumber, weekNumber) {
        const filteredItems = items.filter(item => item.type !== 'absence')

        for (const item of filteredItems) {
            const { worktimesExportOptions } = this.state
            const datesInWeek = this._getDatesInWeek(
                item,
                yearNumber,
                weekNumber,
            )
            const exportOption = worktimesExportOptions.find(
                option => option.id === item.id,
            )

            if (exportOption) {
                for (const date of datesInWeek) {
                    if (
                        !exportOption.DateOptions.find(d =>
                            dayjs(d).isSame(date, 'date'),
                        )
                    ) {
                        return false
                    }
                }
            } else {
                return false
            }
        }
        return true
    }

    checkAll(check) {
        const { isAllChecked, data, worktimesExportOptions } = this.state

        const worktimesDatas = [...data]
        const worktimes = worktimesDatas.filter(item => item.type !== 'absence')
        const dataWorktimesExportOptions = [...worktimesExportOptions]

        if (!check) {
            dataWorktimesExportOptions.forEach(option => {
                option.DateOptions = []
            })
        } else {
            worktimes.forEach(worktime => {
                let allDates = []
                const startDate = new Date(worktime.delimitedDate.startDate)
                const endDate = new Date(worktime.delimitedDate.endDate)
                let currentDate = new Date(startDate)

                while (currentDate <= endDate) {
                    allDates.push(new Date(currentDate))
                    currentDate.setDate(currentDate.getDate() + 1)
                }

                const exportOption = dataWorktimesExportOptions.find(
                    option => option.id === worktime.id,
                )
                if (exportOption) {
                    exportOption.DateOptions = allDates
                }
            })
        }

        this.setState({
            isAllChecked: !isAllChecked,
            worktimesExportOptions: dataWorktimesExportOptions,
        })
    }

    _areAllDatesChecked(data, worktimesExportOptions) {
        for (const worktime of data) {
            const allDates = []
            const startDate = new Date(worktime.delimitedDate.startDate)
            const endDate = new Date(worktime.delimitedDate.endDate)
            let currentDate = new Date(startDate)

            while (currentDate <= endDate) {
                allDates.push(new Date(currentDate))
                currentDate.setDate(currentDate.getDate() + 1)
            }

            const exportOption = worktimesExportOptions.find(
                option => option.id === worktime.id,
            )
            if (exportOption) {
                for (const date of allDates) {
                    if (
                        !exportOption.DateOptions.find(d =>
                            dayjs(d).isSame(date, 'date'),
                        )
                    ) {
                        return false
                    }
                }
            } else {
                return false
            }
        }
        return true
    }

    _isDateInWorktime(datesArray, worktimeId) {
        const { worktimesExportOptions } = this.state

        const worktime = worktimesExportOptions.find(
            option => option.id === worktimeId,
        )

        if (!worktime) {
            return false
        }

        return datesArray.some(date =>
            worktime.DateOptions.some(
                optionDate =>
                    new Date(optionDate).getTime() === new Date(date).getTime(),
            ),
        )
    }

    getWeekAccordionList() {
        const { structuredData } = this.state

        let weekAccordionList = []
        Object.keys(structuredData).forEach(year => {
            Object.keys(structuredData[year]).forEach(week => {
                weekAccordionList.push(year + '-' + week)
            })
        })
        return weekAccordionList
    }

    handleUnfoldAll(value) {
        if (value) {
            this.setState({
                expandedWeekAccordion: [...this.getWeekAccordionList()],
            })
        } else {
            this.setState({ expandedWeekAccordion: [] })
        }
    }

    _renderActionMenuPopover() {
        const { classes } = this.props
        const { selectedItem } = this.state
        const userLink = this.getCurrentUserLink()
        const isWaitingAbsence =
            selectedItem.absenceStatus && selectedItem.absenceStatus.id === 1

        return (
            <Popover
                id={'popover'}
                open={this.state.isActionMenuOpen}
                anchorEl={this.state.anchorPopup}
                onClose={this.closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.actionMenu}
            >
                <div className={classes.actionMenu}>
                    {/* Consulter évenement */}
                    <BoatOnBlock
                        blockIds={54}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    this.setState({
                                        openModal: selectedItem.type,
                                        isActionMenuOpen: false,
                                    })
                                }}
                                className={classes.actionMenuItem}
                                startIcon={<VisibilityIcon />}
                            >
                                {isWaitingAbsence
                                    ? this.displayText(`viewRequest`)
                                    : this.displayText(`viewEvent`)}
                            </ButtonMui>
                        }
                    />

                    {/* Annuler évenement */}
                    <BoatOnBlock
                        blockIds={69}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    if (selectedItem.type === 'workTime') {
                                        if (
                                            window.confirm(
                                                this.displayText(
                                                    'confirmCancelWorkTime',
                                                ),
                                            )
                                        ) {
                                            this.closeActionMenu()
                                            this.props.dispatch(
                                                workTimeActions.deleteWorkTime({
                                                    workTimeId: selectedItem.id,
                                                    userLink: userLink,
                                                }),
                                            )
                                        }
                                    } else if (
                                        selectedItem.type === 'absence'
                                    ) {
                                        let labelArray = []

                                        if (
                                            selectedItem.absenceStatus.id === 2
                                        ) {
                                            // Validé
                                            labelArray.push(
                                                this.displayText(
                                                    'absenceAlreadyValidated',
                                                ),
                                            )
                                        } else if (
                                            selectedItem.absenceStatus.id === 3
                                        ) {
                                            // Refusé
                                            labelArray.push(
                                                this.displayText(
                                                    'absenceAlreayRejected',
                                                ),
                                            )
                                        }

                                        labelArray.push(
                                            this.displayText(
                                                'confirmCancelAbsence',
                                            ),
                                        )

                                        if (
                                            window.confirm(
                                                labelArray.join('\n\n'),
                                            )
                                        ) {
                                            this.closeActionMenu()
                                            this.props.dispatch(
                                                deleteAbsence({
                                                    absenceId: selectedItem.id,
                                                    currentUserLink: userLink,
                                                }),
                                            )
                                        }
                                    }
                                    // this.props.edit(transacPopup, documents)
                                }}
                                className={classes.actionMenuItem}
                                startIcon={<DoNotDisturbIcon />}
                            >
                                {isWaitingAbsence
                                    ? this.displayText(`cancelRequest`)
                                    : this.displayText(`cancelEvent`)}
                            </ButtonMui>
                        }
                    />
                </div>
            </Popover>
        )
    }

    _formatTime(time) {
        let stringTime = ''
        if (this.context === 'en') {
            stringTime = time.format('hh:mm a').replace(':', 'h')
        } else stringTime = time.format('HH:mm').replace(':', 'h')

        stringTime = stringTime.replace('h00', 'h')
        return stringTime
    }

    _formatDate(date) {
        const url = window.location.pathname
        const context = getContextFromUrl(url)

        const days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ]

        const monthNames = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ]

        if (typeof date === 'string') {
            date = new Date(date)
        }

        let str1 = this.displayText(days[date.getDay()])
        let str2 = ''
        if (context === 'fr') {
            str2 = `${date
                .getDate()
                .toString()
                .padStart(2, '0')} ${this.displayText(
                monthNames[date.getMonth()],
            )}`
        } else if (context === 'en') {
            str2 = `${this.displayText(
                monthNames[date.getMonth()],
            )} ${date.getDate()}`
        }

        return (
            <>
                <Typography>{str1}</Typography>
                <Typography>
                    <strong>{str2}</strong>
                </Typography>
            </>
        )
    }

    calculateTotalTime(item, nbDays = 1) {
        let { startTime, endTime, breakTimes } = item

        let total
        if (startTime && endTime) {
            if (!dayjs.isDayjs(startTime))
                startTime = dayjs(startTime, 'HH:mm:ss')
            if (!dayjs.isDayjs(endTime)) endTime = dayjs(endTime, 'HH:mm:ss')

            // Gestion si le worktime fini le lendemain
            if (dayjs(startTime).isAfter(dayjs(endTime))) {
                endTime = dayjs(endTime).add(1, 'day')
            }

            total = dayjs(endTime).diff(dayjs(startTime), 'minute')

            if (
                breakTimes &&
                Array.isArray(breakTimes) &&
                breakTimes.length > 0
            )
                breakTimes.forEach(b => {
                    if (b.startTime && b.endTime) {
                        let breakStart = dayjs.isDayjs(b.startTime)
                            ? b.startTime
                            : dayjs(b.startTime, 'HH:mm:ss')
                        let breakEnd = dayjs.isDayjs(b.endTime)
                            ? b.endTime
                            : dayjs(b.endTime, 'HH:mm:ss')
                        let breakTime = dayjs(breakEnd).diff(
                            dayjs(breakStart),
                            'minute',
                        )
                        total -= breakTime
                    }
                })

            total *= nbDays

            return worktimesUtils.minutesToWorkingDays(total)
        }
    }

    _getTotalWeekHours(weekEvents, yearNumber, weekNumber) {
        const lastDayOfWeek = dayjs()
            .year(yearNumber)
            .isoWeek(weekNumber)
            .endOf('isoWeek')
        const firstDayOfWeek = dayjs()
            .year(yearNumber)
            .isoWeek(weekNumber)
            .startOf('isoWeek')

        const totalWeekHours = weekEvents.reduce(
            (acc, currentValue) => {
                if (
                    !currentValue.startTime ||
                    !currentValue.endTime ||
                    currentValue.management
                )
                    return acc
                let { startDate, endDate } = currentValue.delimitedDate
                if (lastDayOfWeek < endDate) endDate = lastDayOfWeek
                if (firstDayOfWeek > startDate) startDate = firstDayOfWeek
                const nbDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1

                let { totalHours, minutes } = this.calculateTotalTime(
                    currentValue,
                    nbDays,
                )

                if (acc.minutes + parseInt(minutes) >= 60) {
                    totalHours = parseInt(totalHours) + 1
                    minutes = parseInt(minutes) - 60
                }

                return {
                    hours: acc.hours + parseInt(totalHours),
                    minutes: acc.minutes + parseInt(minutes),
                }
            },
            { hours: 0, minutes: 0 },
        )

        if (totalWeekHours.minutes === 0 && totalWeekHours.hours === 0)
            return undefined

        return `${totalWeekHours.hours
            .toString()
            .padStart(2, '0')}h${totalWeekHours.minutes
            .toString()
            .padStart(2, '0')}`
    }

    _getDatesInWeek(item, yearNumber, weekNumber) {
        const dates = []
        const lastDayOfWeek = dayjs()
            .year(yearNumber)
            .isoWeek(weekNumber)
            .endOf('isoWeek')
        const firstDayOfWeek = dayjs()
            .year(yearNumber)
            .isoWeek(weekNumber)
            .startOf('isoWeek')

        let startDate = new Date(item.delimitedDate.startDate)
        let endDate = new Date(item.delimitedDate.endDate)
        const startWeekNumber = dayjs(startDate).isoWeek()
        const endWeekNumber = dayjs(endDate).isoWeek()
        const startYear = dayjs(startDate).year()
        const endYear = dayjs(endDate).year()

        if (
            endYear > yearNumber ||
            (endYear === yearNumber && endWeekNumber > weekNumber)
        ) {
            endDate = new Date(lastDayOfWeek)
        }

        if (
            startYear < yearNumber ||
            (startYear === yearNumber && startWeekNumber < weekNumber)
        ) {
            startDate = new Date(firstDayOfWeek)
        }

        const diffBetweenDays = parseInt(
            (endDate - startDate) / (1000 * 60 * 60 * 24),
            10,
        )

        if (!dates.find(date => dayjs(date).isSame(dayjs(endDate)))) {
            dates.push(new Date(endDate))
        }

        for (let i = 0; i < diffBetweenDays; i++) {
            const newDate = new Date(endDate.setDate(endDate.getDate() - 1))
            if (!dates.find(date => dayjs(date).isSame(newDate, 'date'))) {
                dates.push(newDate)
            }
        }

        return dates
    }

    _renderGroupLine(items, yearNumber, weekNumber) {
        const { classes, groupId, workTimeGroups } = this.props
        const dates = []
        const currentWorktimeSettings = workTimeGroups?.find(
            item => item.groupId === groupId,
        )
        const allDays = [
            {
                number: 0,
                key: 'sundaySetting',
            },
            {
                number: 1,
                key: 'mondaySetting',
            },
            {
                number: 2,
                key: 'tuesdaySetting',
            },
            {
                number: 3,
                key: 'wednesdaySetting',
            },
            {
                number: 4,
                key: 'thursdaySetting',
            },
            {
                number: 5,
                key: 'fridaySetting',
            },
            {
                number: 6,
                key: 'saturdaySetting',
            },
        ]
        let workingDay = currentWorktimeSettings ? [] : [0, 1, 2, 3, 4, 5, 6]

        allDays.forEach(day => {
            if (!currentWorktimeSettings) return

            if (currentWorktimeSettings[day.key] !== null)
                workingDay.push(day.number)
        })

        for (const item of items) {
            this._getDatesInWeek(item, yearNumber, weekNumber).forEach(date => {
                if (!dates.find(d => dayjs(d).isSame(date, 'date'))) {
                    if (item.type === 'workTime') dates.push(date)
                    else if (workingDay.includes(new Date(date).getDay()))
                        dates.push(date)
                }
            })
        }

        return dates
            .sort((a, b) => b - a)
            .map(date => (
                <div className={classes.group}>
                    <div
                        style={{
                            borderBottom: '1px solid #F1F1F1',
                            borderRight: '1px solid #F1F1F1',
                        }}
                    >
                        {this._renderColumnDate(date)}
                    </div>
                    <div className={classes.groupLine}>
                        {items
                            .filter(
                                item =>
                                    new Date(item.delimitedDate.startDate) <=
                                        date &&
                                    new Date(item.delimitedDate.endDate) >=
                                        date,
                            )
                            .map(item => this._renderLine(item, date))}
                    </div>
                </div>
            ))
    }

    renderWeeksAccordions() {
        const { classes } = this.props
        const { structuredData, expandedWeekAccordion } = this.state

        if (Object.keys(structuredData).length === 0)
            return (
                <p className={classes.noData}>{this.displayText('noData')}</p>
            )

        return Object.keys(structuredData)
            .sort((a, b) => b - a)
            .map(yearNumber => {
                return (
                    <div
                        className={classes.yearContainer}
                        key={`year-${yearNumber}`}
                    >
                        <Typography variant="h6">{yearNumber}</Typography>
                        {Object.keys(structuredData[yearNumber])
                            .sort((a, b) => b - a)
                            .map(weekNumber => {
                                const totalWeek = this._getTotalWeekHours(
                                    structuredData[yearNumber][weekNumber],
                                    yearNumber,
                                    weekNumber,
                                )

                                return (
                                    <Accordion
                                        key={`week-${weekNumber}`}
                                        expanded={
                                            expandedWeekAccordion.find(
                                                data =>
                                                    data ===
                                                    yearNumber +
                                                        '-' +
                                                        weekNumber,
                                            )
                                                ? true
                                                : false
                                        }
                                        onChange={() => {
                                            this.handleAccordionChange(
                                                yearNumber + '-' + weekNumber,
                                            )
                                        }}
                                        className={classes.accordion}
                                        classes={{
                                            root: classes.extension,
                                        }}
                                    >
                                        <AccordionSummary
                                            classes={{
                                                root:
                                                    classes.accordionSummaryRoot,
                                                content:
                                                    classes.accordionSummaryContent,
                                                expanded:
                                                    classes.expandedFixRadius,
                                            }}
                                            style={{
                                                height: '54px',
                                                minHeight: '54px',
                                            }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <div className={classes.headerWeek}>
                                                <div
                                                    className={
                                                        classes.weekLabel
                                                    }
                                                >
                                                    {this._areAllItemsAbsences(
                                                        structuredData[
                                                            yearNumber
                                                        ][weekNumber],
                                                    ) && (
                                                        <Checkbox
                                                            color="primary"
                                                            checked={this._areWeekDatesChecked(
                                                                structuredData[
                                                                    yearNumber
                                                                ][weekNumber],
                                                                parseInt(
                                                                    yearNumber,
                                                                ),
                                                                parseInt(
                                                                    weekNumber,
                                                                ),
                                                            )}
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                                this.checkWeek(
                                                                    structuredData[
                                                                        yearNumber
                                                                    ][
                                                                        weekNumber
                                                                    ],
                                                                    parseInt(
                                                                        yearNumber,
                                                                    ),
                                                                    parseInt(
                                                                        weekNumber,
                                                                    ),
                                                                    e.target
                                                                        .checked,
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                    <Typography
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {this.displayText(
                                                            'semaine',
                                                        )}{' '}
                                                        {weekNumber}
                                                    </Typography>
                                                </div>
                                                <div
                                                    className={
                                                        classes.lineGridHeader
                                                    }
                                                >
                                                    {totalWeek && (
                                                        <Typography
                                                            className={
                                                                classes.totalWeekHours
                                                            }
                                                        >
                                                            {this.displayText(
                                                                'totalWeek',
                                                            )}
                                                            {': '}
                                                            {totalWeek}
                                                        </Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            classes={{
                                                root:
                                                    classes.accordionDetailsRoot,
                                            }}
                                        >
                                            {this._renderGroupLine(
                                                structuredData[yearNumber][
                                                    weekNumber
                                                ],
                                                parseInt(yearNumber),
                                                parseInt(weekNumber),
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                    </div>
                )
            })
    }

    render() {
        const {
            classes,
            workTimesLoading,
            absencesLoading,
            workTimes,
            absences,
            isMobile,
        } = this.props
        const {
            data,
            structuredData,
            expandedWeekAccordion,
            isAllChecked,
        } = this.state

        return (
            <div className={classes.page}>
                {/* Titre */}
                <Typography className={classes.title}>
                    {this.displayText('title')}
                </Typography>
                <Divider className={classes.noMobile} />
                <Chronometre />
                {/* Cards */}
                <div
                    className={[classes.row, classes.cardsContainer].join(' ')}
                >
                    {this._renderDaysOffTaken()}
                    {this._renderDaysOffToTake()}
                    {this._renderDaysOffPending()}
                </div>
                {/* Filtres */}
                <BobFilter
                    activeButton={{
                        userAttachedRole: true,
                        rangeDate: true,
                        workEventType: true,
                        absenceState: true,
                        workTime: true,
                    }}
                    buttonLabelOverride={{
                        userAttachedRole: this.displayText('user'),
                    }}
                    searchInput={false}
                    checkedBoxes={false}
                    checkedBoxesWorktime={true}
                    isAllChecked={isAllChecked}
                    checkAll={this.checkAll}
                    totalUnfold={this.getWeekAccordionList().length}
                    unfoldAll={[...expandedWeekAccordion]}
                    handleUnfoldAll={this.handleUnfoldAll}
                    nbEvent={data.length}
                    rightComponent={
                        <ButtonBON
                            dropDown={[
                                {
                                    label: this.displayText(`addWorkTime`),
                                    action: this.handleAddWorkTime,
                                    rightBlockIds: 9,
                                },
                                {
                                    label: this.displayText(`addLeave`),
                                    action: this.handleAddLeave,
                                    rightBlockIds: 8,
                                },
                            ]}
                            variant="contained"
                            color="primary"
                            label={this.displayText('add')}
                            size={`large`}
                            fullWidth={isMobile ? true : false}
                            containMenuWidth={false}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            style={{
                                paddingBlock: 4,
                            }}
                        />
                    }
                />
                {workTimesLoading > 0 ||
                workTimes === null ||
                absencesLoading > 0 ||
                absences === null ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={anchorLoader}
                            alt="anchor"
                            title="anchor"
                            margin="auto"
                            width={100}
                            height={100}
                        />
                        <Typography>
                            {this.displayText('pageLoadingLabel')}
                        </Typography>
                    </div>
                ) : (
                    this.renderWeeksAccordions(structuredData)
                )}
                {/* Action menu popover */}
                {this.state.isActionMenuOpen && this._renderActionMenuPopover()}
                {/* Modal */}
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        workTime: (
                            <WorkTimeModal
                                closeModal={this.handleCloseModal}
                                workTime={this.state.selectedItem || null}
                                noCross={false}
                            />
                        ),
                        absence: (
                            <AbsenceModal
                                closeModal={this.handleCloseModal}
                                event={this.state.selectedItem}
                                noCross={false}
                            />
                        ),
                        timesheet: (
                            <ValidTimesheetExportModal
                                onGoBack={this.handleCloseTimesheetModal}
                                onContinue={this.validateExport}
                            />
                        ),
                        startDownloadModal: (
                            <BoatOnAlert
                                noCross={false}
                                closeModal={this.handleCloseModal}
                                severity={`info`}
                                description={this.displayText(
                                    'startDownloadMessage',
                                )}
                                style={{
                                    marginBottom: 0,
                                }}
                            />
                        ),
                    }}
                    titles={{
                        workTime: this.displayText('workTimeModalTitle'),
                        absence: this.displayText('leaveModalTitle'),
                        timesheet: this.displayText('timesheetModalTitle'),
                        startDownloadModal: this.displayText(
                            'startDownloadTitle',
                        ),
                    }}
                />
                {/* WorkTimeBar */}
                <WorkTimeBar
                    open={this._isDataWorktimesExportOptionsNotEmpty()}
                    downloadTimeSheet={this.downloadTimeSheet}
                />
            </div>
        )
    }

    async validateExport() {
        this.setState({
            canExport: true,
        })
        this.handleCloseTimesheetModal()
        await this.downloadTimeSheet()
    }

    async downloadTimeSheet() {
        const { worktimesExportOptions, canExport } = this.state
        let context = this.context
        let worktimesExportOptionsFiltered = worktimesExportOptions
            .map(option => ({
                ...option,
                DateOptions: option.DateOptions.map(date => new Date(date)),
            }))
            .filter(option => option.DateOptions.length > 0)

        worktimesExportOptionsFiltered.forEach(option => {
            option.DateOptions = option.DateOptions.map(date =>
                dayjs(date)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0)
                    .set('millisecond', 0)
                    .toDate(),
            ).sort((a, b) => a - b)
        })

        if (!canExport) {
            if (worktimesExportOptionsFiltered.length === 1) {
                const worktime = worktimesExportOptionsFiltered[0]
                const startDate = dayjs(worktime.DateOptions[0])
                const endDate = dayjs(
                    worktime.DateOptions[worktime.DateOptions.length - 1],
                )

                const daysDifference = endDate.diff(startDate, 'day')
                const startMonth = startDate.month()
                const endMonth = endDate.month()

                if (
                    daysDifference < startDate.daysInMonth() &&
                    startMonth === endMonth
                ) {
                    this.setState({
                        openModal: 'timesheet',
                        canExport: false,
                    })
                    return
                }
            }
        }

        this.setState({
            canExport: true,
            openModal: 'startDownloadModal',
        })

        if (this.context === 'fr') context = '0'
        else if (this.context === 'en') context = '1'

        await downloadTimesheetWorktimePdf(
            worktimesExportOptionsFiltered,
            context,
        )
    }

    _renderWorkSlider(item) {
        const { classes } = this.props
        if (item?.absenceReasonType?.id && !item.absenceTime)
            return (
                <Typography style={{ marginBottom: 10, fontWeight: 500 }}>
                    {this.displayText('allDay')}
                </Typography>
            )
        const startTime =
            item.startTime || dayjs(item.absenceTime.startTime, 'HH:mm:ss')
        const endTime =
            item.endTime || dayjs(item.absenceTime.endTime, 'HH:mm:ss')
        // J'ai repris le code pas le temps de décortiquer et comprendre et ca fonctionne apparemment
        const diff = endTime.diff(startTime, 'hour', true)
        const daySpan = startTime.hour() < endTime?.hour() ? diff : 24 + diff

        const sections = [
            {
                startTime: startTime,
                endTime: undefined,
                break: false,
                length: undefined,
                displayTime: true,
            },
        ]

        const userLink = this.getCurrentUserLink()

        if (!userLink) return <></>
        // Création des sections pour générer la frise
        if (item.breakTimes && item.breakTimes.length > 0)
            item.breakTimes.forEach((b, index) => {
                // Fin de la section de travail précédente
                sections[sections.length - 1].endTime = b.startTime

                // Calcul de la durée de la section
                sections[sections.length - 1].length =
                    sections[sections.length - 1].startTime?.hour() <
                    sections[sections.length - 1].endTime?.hour()
                        ? sections[sections.length - 1].endTime?.diff(
                              sections[sections.length - 1].startTime,
                              'hour',
                              true,
                          )
                        : 24 +
                          sections[sections.length - 1].endTime?.diff(
                              sections[sections.length - 1].startTime,
                              'hour',
                              true,
                          )

                // Ajout de la section pause
                sections.push({
                    startTime: b.startTime,
                    endTime: b.endTime,
                    break: true,
                    length:
                        b.startTime?.hour() <= b.endTime?.hour()
                            ? b.endTime?.diff(b.startTime, 'hour', true)
                            : 24 + b.endTime?.diff(b.startTime, 'hour', true),
                    displayTime:
                        b.endTime?.diff(b.startTime, 'minute', true) > 10,
                })

                // Début de la section de travail suivante
                sections.push({
                    startTime: b.endTime,
                    endTime: undefined,
                    break: false,
                    length: undefined,
                    displayTime: true,
                })
            })
        // Termine la dernière section
        sections[sections.length - 1].endTime = endTime
        sections[sections.length - 1].length =
            sections[sections.length - 1].startTime?.hour() <
            sections[sections.length - 1].endTime?.hour()
                ? sections[sections.length - 1].endTime?.diff(
                      sections[sections.length - 1].startTime,
                      'hour',
                      true,
                  )
                : 24 +
                  sections[sections.length - 1].endTime?.diff(
                      sections[sections.length - 1].startTime,
                      'hour',
                      true,
                  )

        let _endTime = endTime
        let half = false

        // Si l'évènement se termine un autre jour
        // ex: (start: 22h, end: 4h)
        if (endTime?.hour() < startTime.hour()) {
            _endTime += 1000 * 60 * 60 * 24
        }

        // Si la durée de la journée de travail est inférieur à 4h
        // Alors longueur de la barre réduite
        if (dayjs(_endTime).diff(startTime, 'hour', true) <= 4) {
            half = true
        }

        return (
            <div
                className={[classes.row, half ? classes.half : undefined].join(
                    ' ',
                )}
            >
                {/* Boucle sur les sections pour générer la frise */}
                {sections.map((section, index) => {
                    const width = ((section.length / daySpan) * 100).toFixed(2)

                    return (
                        <div
                            style={{
                                width: `${width}%`,
                            }}
                            key={`item-${item.id}--section-${index}`}
                        >
                            <div
                                className={classes.row}
                                style={{
                                    justifyContent: 'space-between',
                                    minHeight: 22,
                                }}
                            >
                                {/* Affiche les heures:
                            - Début et fin de journée
                            - Si moins de 3 pauses dans la journée:
                                - Au début et à la fin de chaque pause
                            - Si la pause fait plus de 10mn
                        */}
                                <Typography>
                                    {(index === 0 ||
                                        (sections.length < 7 &&
                                            !section.break &&
                                            sections[index - 1].displayTime ===
                                                true)) &&
                                        this._formatTime(section.startTime)}
                                </Typography>
                                <Typography>
                                    {(index === sections.length - 1 ||
                                        (sections.length < 7 &&
                                            !section.break &&
                                            sections[index + 1].displayTime ===
                                                true)) &&
                                        this._formatTime(section.endTime)}
                                </Typography>
                            </div>

                            {/*
                            Worktime: Ligne solide bleue
                            Break: Ligne pointillée grise

                            Si le nombre de pause est >= 3 alors on affiche plus les ronds
                        */}
                            <div
                                className={[
                                    section.break
                                        ? classes.break
                                        : classes.work,
                                    index === 0 ||
                                    (sections.length < 7 && !section.break)
                                        ? classes.startMarker
                                        : undefined,
                                    index === sections.length - 1 ||
                                    (sections.length < 7 && !section.break)
                                        ? classes.endMarker
                                        : undefined,
                                ].join(' ')}
                            ></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    _renderTotal(item) {
        const { classes } = this.props
        const { userGroup } = this.state

        const { totalHours, minutes, totalMinutes } = this.calculateTotalTime(
            item,
        )

        // Check what the total work time is compared to the settings for this day
        let errors = {
            overtime: false,
            undertime: false,
        }

        if (userGroup && !item.absenceTime && !item.absenceStatus) {
            const day = new Date(
                item.delimitedDate.startDate,
            ).toLocaleDateString('en-US', { weekday: 'long' })
            const daySetting = userGroup[day.toLowerCase() + `Setting`]

            // Si le setting pour le jour n'existe pas, alors le temps de travail attendu est 0
            const expectedTotalWorkTime = daySetting
                ? this.calculateTotalTime(daySetting)
                : 0
            if (totalMinutes > expectedTotalWorkTime.totalMinutes) {
                errors.overtime = true
            }
            if (totalMinutes < expectedTotalWorkTime.totalMinutes) {
                errors.undertime = true
            }
        }

        const stringTime = `${Math.floor(totalHours)
            .toString()
            .padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`

        return (
            <div className={classes.totalContainer}>
                <div className={`${classes.otherSpan}`}>
                    <Typography>Total</Typography>
                    <Typography
                        className={[
                            classes.daySum,
                            errors.overtime || errors.undertime
                                ? classes.totalError
                                : undefined,
                        ].join(' ')}
                    >
                        {stringTime}
                    </Typography>
                </div>
                {(errors.overtime || errors.undertime) && (
                    <Tooltip
                        title={
                            errors.overtime
                                ? this.displayText('overtimeError')
                                : this.displayText('undertimeError')
                        }
                    >
                        <WarningIcon className={classes.totalError} />
                    </Tooltip>
                )}
            </div>
        )
    }

    _renderStatus(item) {
        const { classes } = this.props

        return (
            <div className={`${classes.col} ${classes.otherSpan}`}>
                <div
                    className={`${classes.rootLabel} ${
                        item.absenceStatus.id === 1
                            ? classes.orangeLabel
                            : item.absenceStatus.id === 2
                            ? classes.greenLabel
                            : item.absenceStatus.id === 3
                            ? classes.redLabel
                            : undefined
                    }`}
                >
                    <Typography>
                        {this.displayTextApi(item.absenceStatus.translation)}
                    </Typography>
                </div>
            </div>
        )
    }

    _renderColumnType(item) {
        const { classes } = this.props

        return (
            <div
                className={`${classes.col} ${classes.otherSpan}`}
                style={{ marginRight: 20, flex: 1 }}
            >
                <div className={classes.columnFlex}>
                    {this._renderWorkSlider(item)}
                    <div
                        className={`${classes.rootLabel} ${
                            !item?.absenceReasonType
                                ? classes.blueLabel
                                : item.absenceReasonType.id === 4
                                ? classes.brownLabel
                                : item.absenceReasonType.id === 5
                                ? classes.greyLabel
                                : item.absenceReasonType.id === 1
                                ? classes.greenLabel
                                : item.absenceReasonType.id === 3
                                ? classes.redLabel
                                : item.absenceReasonType.id === 6
                                ? classes.purpleLabel
                                : item.absenceReasonType.id === 7
                                ? classes.lightBlueLabel
                                : undefined
                        }`}
                    >
                        <Typography>
                            {!item?.absenceReasonType
                                ? this.displayText('work')
                                : this.displayTextApi(
                                      item.absenceReasonType.translation,
                                  )}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    _renderMenu(item) {
        const { classes } = this.props

        return (
            <div className={`${classes.col} ${classes.otherSpan}`}>
                <div style={{ width: 'fit-content' }}>
                    <IconButton
                        onClick={e => {
                            this.openActionMenu(e, item)
                        }}
                    >
                        <MoreHorizIcon
                            style={{
                                color: `black`,
                            }}
                        />
                    </IconButton>
                </div>
            </div>
        )
    }

    _renderColumnUserAndCheckbox(item, user, date) {
        const { classes } = this.props

        // Si le userLinkId de l'item est différent du userLinkId de l'utilisateur connecté
        // Alors on affiche le badge de l'utilisateur
        // Pour que le manager puisse identifier facilement quel utilisateur a fait la demande
        // const showUserBadge = item.userLinkId !== this.getCurrentUserLink().id
        const showUserBadge = true

        return (
            <>
                {/*item?.type !== 'absence' && (
                    <Checkbox
                        color="primary"
                        checked={this._isDayChecked(item, date)}
                        onClick={e => {
                            this.checkDay(item, date, e.target.checked)
                        }}
                        style={{ marginRight: 20 }}
                        className={classes.noMobile}
                    />
                )*/}
                {showUserBadge && (
                    <div className={classes.badgeContainer}>
                        {user.firstName ? (
                            <div className={classes.userBadge}>
                                {user.firstName[0].toUpperCase() +
                                    user.lastName[0].toUpperCase()}
                            </div>
                        ) : (
                            <div className={classes.userBadge}>
                                <PersonIcon className={classes.userBadgeIcon} />
                            </div>
                        )}
                        {user.firstName && user.lastName ? (
                            <Typography style={{ textOverflow: 'clip' }}>
                                {user.firstName.charAt(0).toUpperCase() +
                                    user.firstName.slice(1) +
                                    ' ' +
                                    user.lastName.charAt(0).toUpperCase() +
                                    user.lastName.slice(1)}
                            </Typography>
                        ) : (
                            <Typography>{user.mail}</Typography>
                        )}
                    </div>
                )}
            </>
        )
    }

    _renderColumnDate(date) {
        const { classes } = this.props

        return (
            <div
                className={`${classes.col} ${classes.textDate} ${classes.otherSpan}`}
            >
                <div className={classes.date}>
                    {this._formatDate(new Date(date))}
                </div>
                <Divider
                    orientation="vertical"
                    className={classes.verticalDivider}
                />
            </div>
        )
    }

    _renderLine(item, date) {
        const { classes } = this.props
        const userLink = this.getUserLinkById(item.userLinkId)

        if (!userLink) {
            return <></>
        }
        const user = userLink?.user ? userLink.user : userLink.userSubscribe

        return (
            <div
                className={`${classes.accordionItem} ${
                    item?.absenceStatus?.id === 1 ||
                    item?.absenceStatus?.id === 3
                        ? classes.fixWidthWithDivider
                        : undefined
                }`}
                key={`item-${item.id}`}
            >
                {(item?.absenceStatus?.id === 1 ||
                    item?.absenceStatus?.id === 3) && (
                    <Divider
                        orientation="vertical"
                        className={`${classes.verticalDivider} ${
                            item?.absenceStatus?.id === 1
                                ? classes.warningDivider
                                : classes.errorDivider
                        }`}
                    />
                )}
                <div className={classes.lineContent}>
                    {this._renderColumnUserAndCheckbox(item, user, date)}
                    {/* {this._renderMobileCheckbox(item, user, date)} */}
                    <div className={classes.lineRow}>
                        {this._renderColumnType(item)}
                        {item?.absenceStatus?.id
                            ? this._renderStatus(item)
                            : this._renderTotal(item)}
                        {this._renderMenu(item)}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
        user: state.authentication.user,
        groupId: state.group.currentGroupId,
        fleetInfos: state.group.fleetInfos,
        sub: state.fetch.sub,
        spendings: state.bob.spendings,
        checkups: state.bob.repairs,
        checkupTypes: state.fetch.checkupTypes,
        loading: state.bob.loading,
        roles: state.group.roles || [],
        group: state.group?.groupsMembers,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        leaveTypes: state.types.leaveTypes,
        absencesLoading: state.absence.loading,
        absences: filterActions.filterAbsenceEvents(
            state.absence.absences,
            state.filter.bobFilter,
            context,
            {
                userAttachedRole: true,
                rangeDate: true,
                workEventType: true,
                absenceState: true,
                workTime: true,
            },
            state.group?.groupsMembers,
        ),
        absencesManager: filterActions.filterAbsenceEvents(
            state.absence.absencesManager,
            state.filter.bobFilter,
            context,
            {
                userAttachedRole: true,
                rangeDate: true,
                workEventType: true,
                absenceState: true,
                workTime: true,
            },
            state.group?.groupsMembers,
        ),
        workTimesLoading: state.workTime.loading,
        workTimesManager: filterActions.filterWorkEvents(
            state.workTime.workTimesManager,
            state.filter.bobFilter,
            context,
            {
                userAttachedRole: true,
                rangeDate: true,
                workEventType: true,
                absenceState: true,
                workTime: true,
            },
            state.group?.groupsMembers,
            state.settings.workTimeSettings,
            state.authentication.user,
        ),
        workTimes: filterActions.filterWorkEvents(
            state.workTime.workTimes,
            state.filter.bobFilter,
            context,
            {
                userAttachedRole: true,
                rangeDate: true,
                workEventType: true,
                absenceState: true,
                workTime: true,
            },
            state.group?.groupsMembers,
            state.settings.workTimeSettings,
            state.authentication.user,
        ),
        workTimeGroups: state.settings.workTimeSettings,
        paidVacationSettings: state.settings.paidVacationSettings,
        boat: state.bob.boat,
        bob: state.bob,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
        groupAbsences: state.absence.absencesGroup,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
        settingsLoading: state.settings.loading,
        redirectEvent: state.redirect.event,
        redirectParams: state.redirect.params,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(WorkTimePage))
