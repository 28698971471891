import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import dictionary from './Dictionary/InventoryListDico'
import styles from './Styles/TopInventoryCss'
import Tooltip from '@material-ui/core/Tooltip'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { putBOBEvents, getElementStock } from '../../../../actions/bob.actions'
import Popover from '@material-ui/core/Popover'
import BoatOnComponent from '../../../common/BoatOnComponent'
import noStockBell from '../../../../images/noStockBell.svg'
import outDatedReplaceImg from '../../../../images/outDatedReplace.svg'
import soonOutDatedStatusImg from '../../../../images/soonOutDatedStatus.svg'
import replaceDateOkImg from '../../../../images/replaceDateOk.svg'
import { Button as ButtonBON } from '../../../common'

import eyeicon from '../../../../images/eyeicon.png'
import Typography from '@material-ui/core/Typography'
import EditList from '../../../../images/EditList.svg'
import BoatOnModal from '../../../common/BoatOnModal'
import InventoryModal from './InventoryModal/InventoryModal'
import InventoryHistoryModal from './InventoryHistoryModal'
import { filterActions } from '../../../../actions/filter.actions'
import BobFilter from '../BobFilter'
import shoppingcart from '../../../../images/shoppingcart.png'
import instockicon from '../../../../images/instockicon.png'
import shoppingbasket from '../../../../images/shoppingbasket.png'
import { IconButton } from '@material-ui/core'
import BoatOnAlert from '../../../common/UsefullComponents/BoatOnAlert'
import { userActions } from '../../../../actions/user.actions'
import AppRoute from '../../../../constants/AppRoute'
import { history } from '../../../../helpers'
import HelpIcon from '@material-ui/icons/Help'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import HourAlert from '../HourAlert'
import Divider from '@material-ui/core/Divider'

const hoursOrangeThreshold = 50
const hoursRedThreshold = 75
const monthsThreshold = 3

class TopInventory extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)
        this.handleOpenModalRepair = this.handleOpenModalRepair.bind(this)
        this.handleClosePopOverMore = this.handleClosePopOverMore.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.newInventory = this.newInventory.bind(this)
        this.handleUnfoldAll = this.handleUnfoldAll.bind(this)
        this.handleClickMenu = this.handleClickMenu.bind(this)
        this.selectAll = this.selectAll.bind(this)
        this.getNbActiveEquipment = this.getNbActiveEquipment.bind(this)

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        this.state = {
            inventories: { equipmentFamilies: {} },
            allEquipement: [],
            unfoldAllEquipment: [],
            unfoldAllFamily: [],
            events: null,
            event: null,
            modalOpen: urlParams.has(`addEngine`) ? `addEngine` : false,
            modalType: `INVENTORY_EQUIPMENT`,
            modalAdd: false,
            openMenu: false,
            addEvents: [],
            idEventsEdited: [],
            equipment: null,
            part: null,
            menuModalIndex: null,
        }

        this.renderMenu = this.renderMenu.bind(this)
        this.save = this.save.bind(this)
        this.saveMenuModalIndex = this.saveMenuModalIndex.bind(this)
        this.redirectPricing = this.redirectPricing.bind(this)
        this.openCustomerPortal = this.openCustomerPortal.bind(this)
    }

    redirectPricing() {
        this.historyPush(history, AppRoute.PricesPage, '', {
            state: {
                particulier: false,
            },
        })
    }

    async openCustomerPortal() {
        const url = await userActions.showClientPortail()
        window.location.assign(url)
    }

    componentDidMount() {
        const { events, loadingEvents, boat } = this.props
        if (events && boat && loadingEvents === 0) {
            this.initState()
        }
        // this.props.dispatch(
        //     typesActions.requestCheckupTypes(
        //         this.props.boat ? this.props.boat.id : null,
        //     ),
        // )
    }

    saveMenuModalIndex(index) {
        this.setState({ menuModalIndex: index })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.modalOpen && nextState.modalOpen) return false
        return true
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.events &&
            ((prevProps.loadingEvents > 0 && this.props.loadingEvents === 0) ||
                JSON.stringify(prevProps.bobFilter) !==
                    JSON.stringify(this.props.bobFilter) ||
                (prevState.modalOpen && !this.state.modalOpen))
        ) {
            this.initState()
        }
    }

    _getEquipmentObject(inventories, family, equipment, event, part) {
        const partObject = this._getPartObject(
            inventories,
            family,
            equipment,
            event,
            part,
        )
        return inventories.equipmentFamilies[family.id]
            ? {
                  ...inventories.equipmentFamilies[family.id].equipments,
                  [equipment.id]: event.detail.equipment
                      ? {
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            event: part ? null : event,
                            parts: partObject,
                        }
                      : inventories.equipmentFamilies[family.id]
                      ? {
                            ...inventories.equipmentFamilies[family.id]
                                .equipments[equipment.id],
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            parts: partObject,
                        }
                      : {
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            parts: partObject,
                        },
              }
            : {
                  [equipment.id]: event.detail.equipment
                      ? {
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            event: part ? null : event,
                            parts: partObject,
                        }
                      : inventories.equipmentFamilies[family.id]
                      ? {
                            ...inventories.equipmentFamilies[family.id]
                                .equipments[equipment.id],
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            parts: partObject,
                        }
                      : {
                            equipmentType: equipment.equipmentType,
                            userEquipment: equipment.userEquipment,
                            parts: partObject,
                        },
              }
    }

    _getPartObject(inventories, family, equipment, event, part) {
        return part
            ? {
                  ...(inventories.equipmentFamilies[family.id] &&
                  inventories.equipmentFamilies[family.id].equipments[
                      equipment.id
                  ]
                      ? inventories.equipmentFamilies[family.id].equipments[
                            equipment.id
                        ].parts
                      : {}),
                  [part.id]: {
                      partType: part.partType,
                      userPart: part.userPart,
                      event: event,
                  },
              }
            : inventories.equipmentFamilies[family.id] &&
              inventories.equipmentFamilies[family.id].equipments[equipment.id]
            ? inventories.equipmentFamilies[family.id].equipments[equipment.id]
                  .parts
            : {}
    }

    initState() {
        const { events } = this.props
        const inventories = { equipmentFamilies: {} }
        const eventsToAdd = JSON.parse(
            JSON.stringify(
                events.filter(
                    event =>
                        event.detail &&
                        event.delimitedDate &&
                        event.delimitedDate.startDate &&
                        !event.detail.archived,
                ),
            ),
        )

        eventsToAdd.forEach(event => {
            if (
                this.props.filteredEvents.some(
                    filteredEvent => filteredEvent.id === event.id,
                )
            ) {
                let { part, equipment } = event.detail

                if (!part && !equipment) return
                if (part && !equipment) equipment = part.equipment

                const family = part
                    ? part.equipment.equipmentFamily
                    : equipment.equipmentFamily
                const equipmentObject = this._getEquipmentObject(
                    inventories,
                    family,
                    equipment,
                    event,
                    part,
                )

                inventories.equipmentFamilies = {
                    ...inventories.equipmentFamilies,
                    [family.id]: {
                        ...inventories.equipmentFamilies[family.id],
                        type: family.type,
                        user: family.userEquipmentFamily,
                        equipments: equipmentObject,
                    },
                }
            }
        })

        this.setState({
            events: eventsToAdd,
            inventories: inventories,
        })
    }

    _orderEvent(events) {
        return events.sort((eventA, eventB) => {
            const partA = eventA.detail.part
            const partB = eventB.detail.part
            const equipmentA = partA ? partA.equipment : eventA.detail.equipment
            const equipmentB = partB ? partB.equipment : eventB.detail.equipment
            const familyA = partA
                ? partA.equipment.equipmentFamily
                : equipmentA.equipmentFamily
            const familyB = partB
                ? partB.equipment.equipmentFamily
                : equipmentB.equipmentFamily
            if (familyA.id < familyB.id) return -1
            else if (familyA.id > familyB.id) return 1
            else if (familyA.id === familyB.id && equipmentA.id > equipmentB.id)
                return 1
            else if (familyA.id === familyB.id && equipmentA.id < equipmentB.id)
                return -1
            return 0
        })
    }

    handleOpenModalRepair() {
        this.setState({
            modalOpen: 'history',
            modalAdd: false,
            openPopOverMore: false,
        })
    }

    handleOpenEdit(
        e,
        {
            equipment = null,
            part = null,
            modal = 'history',
            equipmentFamily = null,
            event = null,
        },
    ) {
        e.stopPropagation()
        if (part !== null) {
            this.setState({
                event: event,
                modalOpen: modal,
                part: part,
                equipment: null,
                modalAdd: false,
                modalType: `INVENTORY_PART`,
            })
        } else {
            this.setState({
                event: event,
                modalOpen: modal,
                equipment: equipment,
                part: null,
                modalAdd: false,
                modalType: `INVENTORY_EQUIPMENT`,
            })
        }
    }

    handleClickMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    handleOpenPopOverMore(e, event, { equipment = null, part = null }) {
        if (part !== null) {
            this.setState({
                event: event,
                part: part,
                equipment: null,
                modalAdd: false,
                openPopOverMore: true,
                anchorEl: e.currentTarget,
            })
        } else {
            this.setState({
                event: event,
                equipment: equipment,
                part: null,
                modalAdd: false,
                openPopOverMore: true,
                anchorEl: e.currentTarget,
            })
        }
    }

    handleClosePopOverMore() {
        this.setState({
            openPopOverMore: false,
            anchorEl: null,
            event: null,
            modalType: `INVENTORY_EQUIPMENT`,
            modalAdd: false,
            part: null,
            equipment: null,
            modalOpen: null,
        })
    }

    getNbActiveEquipment() {
        const { filteredEvents } = this.props
        let nb = 0
        const equipments = []
        const family = []

        if (filteredEvents)
            filteredEvents
                .filter(
                    event =>
                        event.delimitedDate &&
                        event.delimitedDate.startDate &&
                        !event?.detail?.archived,
                )
                .forEach(event => {
                    const equipmentId = event?.detail?.part?.equipment
                        ? event.detail.part.equipment.id
                        : event.detail.equipment?.id

                    if (!equipmentId) return

                    if (!equipments.find(id => id === equipmentId)) {
                        equipments.push(equipmentId)
                        nb += 1
                    }
                    const familyId = event?.detail?.part?.equipment
                        ?.equipmentFamily
                        ? event.detail.part.equipment.equipmentFamily.id
                        : event.detail.equipment.equipmentFamily.id
                    if (!family.find(id => id === familyId)) {
                        family.push(familyId)
                        nb += 1
                    }
                })
        return nb
    }

    handleUnfoldAll(value) {
        var { events } = this.state

        if (value) {
            const unfoldAllEquipment = []
            const unfoldAllFamily = []
            events
                .filter(
                    event =>
                        event.delimitedDate &&
                        event.delimitedDate.startDate &&
                        !event.detail.archived,
                )
                .forEach(event => {
                    const equipmentId = event?.detail?.part?.equipment?.id
                        ? event.detail.part.equipment.id
                        : event?.detail?.equipment?.id
                        ? event.detail.equipment.id
                        : null
                    if (!unfoldAllEquipment.find(id => id === equipmentId))
                        unfoldAllEquipment.push(equipmentId)
                    const familyId = event?.detail?.part?.equipment
                        ?.equipmentFamily?.id
                        ? event.detail.part.equipment.equipmentFamily.id
                        : event?.detail?.equipment?.equipmentFamily?.id
                        ? event.detail.equipment.equipmentFamily.id
                        : null
                    if (!unfoldAllFamily.find(id => id === familyId))
                        unfoldAllFamily.push(familyId)
                })
            this.setState({ unfoldAllEquipment: unfoldAllEquipment })
            this.setState({ unfoldAllFamily: unfoldAllFamily })
        } else {
            this.setState({ unfoldAllEquipment: [] })
            this.setState({ unfoldAllFamily: [] })
        }
    }

    handleUnfold(i, varName) {
        var unfoldAll = this.state[varName]
        const already = unfoldAll.find(id => id === i) ? true : false

        if (!already) {
            unfoldAll.push(i)
        } else {
            unfoldAll = unfoldAll.filter(id => id !== i)
        }
        this.setState({ [varName]: unfoldAll })
    }

    handleCloseModal() {
        this.setState({
            modalOpen: false,
            modalIDRepair: null,
            modalIDDateRepair: null,
            openDoc: null,
            event: null,
            part: null,
            equipment: null,
            menuModalIndex: null,
        })
    }

    renderMenu() {
        const { subscriptions } = this.props

        return (
            <BoatOnBlock
                blockIds={41}
                children={
                    <div
                        style={{
                            padding: '30px 0px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <ButtonBON
                            dropDown={[
                                {
                                    label: this.displayText(`addEquipement`),
                                    action: () =>
                                        this.newInventory(
                                            `INVENTORY_EQUIPMENT`,
                                        ),
                                },
                                {
                                    label: this.displayText(`addPart`),
                                    action: () =>
                                        this.newInventory(`INVENTORY_PART`),
                                    disabled: !userActions.checkSubscriptionPage(
                                        subscriptions,
                                        6,
                                    ),
                                },
                            ]}
                            variant="contained"
                            color="primary"
                            label={this.displayText('addElement')}
                            size={`large`}
                        />
                    </div>
                }
            />
        )
    }

    save() {
        const { events, idEventsEdited } = this.state

        events.forEach(event => {
            if (idEventsEdited.includes(event.id)) {
                if (
                    event.delimitedDate &&
                    (event.delimitedDate.startDate ||
                        event.delimitedDate.endDate)
                ) {
                    return this.props.dispatch(
                        putBOBEvents(event.id, event.title, {
                            delimitedDate: event.delimitedDate,
                            eventTypeId: event.eventType.id,
                            detail: event.detail,
                        }),
                    )
                } else {
                    alert('Vous devez remplir une date de mise en service')
                }
            }
        })
        this.setState({ events: null })
    }

    newInventory(type) {
        this.setState({
            modalOpen: 'repair',
            modalType: type,
            modalAdd: true,
        })
    }

    selectAll(value, events) {
        if (value) {
            const selected = []
            events
                .filter(
                    event =>
                        event.delimitedDate &&
                        event.delimitedDate.startDate &&
                        !event.detail.archived,
                )
                .forEach(event => {
                    if (event.detail) selected.push(event.id)
                })
            this.props.handleSelectAllCheckBox(true, selected)
        } else {
            this.props.handleSelectAllCheckBox(false)
        }
    }

    renderStock(stock, event) {
        const { classes } = this.props
        const isAlreadyReplaceOnce = this.isElementAlreadyReplaceOnce(
            this.props.events,
            {
                equipmentId: event.detail?.equipment?.id,
                partId: event.detail?.part?.id,
            },
        )

        if (
            !isAlreadyReplaceOnce &&
            !event?.detail?.hourReplacement &&
            !event?.delimitedDate?.endDate
        )
            return <></>

        return (
            <div className={classes.stockBox}>
                <Typography className={`${classes.stockText}`} align={`center`}>
                    {this.displayText('stock')}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    {stock === 0 && (
                        <img
                            src={noStockBell}
                            alt="noStockBell"
                            style={{ marginRight: '10px' }}
                            className={classes.hideInTab}
                        />
                    )}
                    <Typography
                        align="center"
                        className={`${stock === 0 && classes.colorRed}`}
                    >
                        {stock}
                    </Typography>
                </div>
            </div>
        )
    }

    getNameElement({ part = null, equipment = null, equipmentFamily = null }) {
        let name = ''

        if (part) {
            if (part.userPart) name = part.userPart.name
            else if (part.partType)
                name = this.displayTextApi(part.partType.translation)
        } else if (equipment) {
            if (equipment.userEquipment) {
                name = equipment.userEquipment.name
            } else if (equipment.equipmentType)
                name = this.displayTextApi(equipment.equipmentType.translation)
        } else if (equipmentFamily) {
            if (equipmentFamily.user) name = equipmentFamily.user.name
            else if (equipmentFamily.type)
                name = this.displayTextApi(equipmentFamily.type.translation)
        }

        return name
    }

    countStock(filteredEvents) {
        const events = JSON.parse(JSON.stringify(filteredEvents)).filter(
            event => {
                if (!event.detail) return false
                return event.detail.part ? true : false
            },
        )
        var dateThreeMonth = new Date(
            new Date().setMonth(new Date().getMonth() + monthsThreshold),
        )

        let allStockTop = 0
        let noStockTop = 0
        let reNewStockTop = 0

        events.forEach(event => {
            if (
                event.detail &&
                event.detail.part &&
                !event.detail.archived &&
                event.delimitedDate &&
                event.delimitedDate.startDate
            ) {
                const isAlreadyReplaceOnce = this.isElementAlreadyReplaceOnce(
                    this.props.events,
                    {
                        equipmentId: event.detail?.equipment?.id,
                        partId: event.detail?.part?.id,
                    },
                )
                const stock = getElementStock(this.props.events, {
                    partId: event.detail.part.id,
                })
                if (event.detail && stock > 0) allStockTop += 1
                else if (
                    event.detail &&
                    stock === 0 &&
                    (event.detail?.hourReplacement ||
                        event?.delimitedDate?.endDate ||
                        isAlreadyReplaceOnce)
                ) {
                    noStockTop += 1
                }
                if (
                    this.isReplaceSoonOutDated(event) ||
                    this.isReplaceSoonOutOfTime(event) ||
                    this.isReplaceOutDated(event) ||
                    this.isReplaceOutOfTime(event)
                )
                    reNewStockTop += 1
            }
        })
        return { allStockTop, noStockTop, reNewStockTop }
    }

    renderStockBox(stock, color, text, icon) {
        const { classes } = this.props

        return (
            <div className={`${classes.bloc} ${color}`}>
                <div className={classes.icons}>
                    <img
                        alt={icon}
                        src={icon}
                        className={classes.iconMenu}
                    ></img>
                </div>
                <div className={classes.infoText}>
                    <div className={classes.numberStock}>{stock}</div>
                    <Typography className={classes.textStock}>
                        {text}
                    </Typography>
                </div>
            </div>
        )
    }

    renderStockBoxes(filteredEvents) {
        const { classes } = this.props

        const { allStockTop, noStockTop, reNewStockTop } = this.countStock(
            filteredEvents,
        )

        return (
            <div className={classes.blocs}>
                {this.renderStockBox(
                    allStockTop,
                    classes.blocGreen,
                    allStockTop > 1
                        ? this.displayText('partsPluriel')
                        : this.displayText('parts'),
                    instockicon,
                )}
                {this.renderStockBox(
                    noStockTop,
                    classes.blocRed,
                    noStockTop > 1
                        ? this.displayText('missingParts')
                        : this.displayText('missingPart'),
                    shoppingcart,
                )}
                {this.renderStockBox(
                    reNewStockTop,
                    classes.blocYellow,
                    reNewStockTop > 1
                        ? this.displayText('renewedParts')
                        : this.displayText('renewedPart'),
                    shoppingbasket,
                )}
            </div>
        )
    }

    getNbEquipment(filteredInventories) {
        let count = 0
        filteredInventories.forEach(equipmentFamily => {
            equipmentFamily.equipments.forEach(equipment => {
                count += 1
            })
        })
        return count
    }

    setLastIdFamily(equipmentFamily, lastIdFamily) {
        if (
            equipmentFamily.equipments &&
            Array.isArray(equipmentFamily.equipments) &&
            equipmentFamily.equipments.length > 0
        ) {
            if (equipmentFamily && equipmentFamily.userEquipmentFamily)
                lastIdFamily = equipmentFamily.userEquipmentFamily.id * -1
            else if (equipmentFamily && equipmentFamily.type)
                lastIdFamily = equipmentFamily.type.id
        }
        return lastIdFamily
    }

    _renderDates(event) {
        const { classes } = this.props
        const startDate = event.delimitedDate
            ? event.delimitedDate.startDate
            : null
        const endDate = event.delimitedDate ? event.delimitedDate.endDate : null

        return (
            <>
                <div className={`${classes.boxDate} ${classes.hideInTab}`}>
                    <Typography
                        className={`${classes.stockText}`}
                        align="center"
                    >
                        {this.displayText('commissioning')}
                    </Typography>
                    <Typography align="center">
                        {startDate
                            ? new Date(startDate).toLocaleDateString()
                            : '-'}
                    </Typography>
                </div>
                {endDate && (
                    <div
                        className={`${classes.boxDate} ${classes.hideInMobile}`}
                    >
                        <Typography
                            className={`${classes.stockText} ${classes.hideInTab}`}
                            align="center"
                        >
                            {this.displayText('replacement')}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                            className={classes.alignItemsCenterMobile}
                        >
                            {(this.isReplaceOutDated(event) && (
                                <img
                                    src={outDatedReplaceImg}
                                    alt="outDatedReplaceImg"
                                    className={classes.infoIcons}
                                />
                            )) ||
                                (this.isReplaceSoonOutDated(event) && (
                                    <img
                                        src={soonOutDatedStatusImg}
                                        alt="soonOutDatedStatusImg"
                                        className={classes.infoIcons}
                                    />
                                )) ||
                                (event?.delimitedDate?.endDate && (
                                    <img
                                        src={replaceDateOkImg}
                                        alt="replaceDateOkImg"
                                        className={`${classes.showInMobile} ${classes.infoIcons}`}
                                    />
                                ))}
                            <Typography
                                align="left"
                                className={`${
                                    this.isReplaceOutDated(event)
                                        ? classes.colorRed
                                        : this.isReplaceSoonOutDated(event)
                                        ? classes.colorOrange
                                        : ''
                                }`}
                            >
                                {endDate
                                    ? new Date(endDate).toLocaleDateString()
                                    : '-'}
                            </Typography>
                        </div>
                    </div>
                )}
            </>
        )
    }

    renderMuiPicker(event) {
        const { classes } = this.props
        const hourActual =
            event.detail && event.detail.hourActual
                ? event.detail.hourActual
                : null
        const hourReplacement =
            event.detail && event.detail.hourReplacement
                ? event.detail.hourReplacement
                : null

        if (hourActual === null && hourReplacement === null)
            return this._renderDates(event)
        const numberOfControle = Math.trunc(hourActual / hourReplacement) + 1
        return (
            <>
                <div className={`${classes.boxDate} ${classes.hideInTab}`}>
                    <Typography
                        className={`${classes.stockText}`}
                        align="center"
                    >
                        {this.displayText('controlHourLabel')}
                    </Typography>
                    <Typography align="center">
                        {hourActual ? (
                            hourActual + 'h'
                        ) : (
                            <>
                                <Tooltip
                                    title={this.displayText(`helpHour`)}
                                    classes={{
                                        tooltip: classes.tooltipHour,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>{' '}
                                - h{' '}
                            </>
                        )}
                    </Typography>
                </div>
                {hourReplacement && hourActual && (
                    <div className={classes.boxDate}>
                        <Typography
                            className={`${classes.stockText} ${classes.hideInTab}`}
                            align="center"
                        >
                            {this.displayText('replaceHourLabel')}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                            className={classes.alignItemsCenterMobile}
                        >
                            {(this.isReplaceOutOfTime(event) && (
                                <img
                                    src={outDatedReplaceImg}
                                    alt="outDatedReplaceImg"
                                    className={classes.infoIcons}
                                />
                            )) ||
                                (this.isReplaceSoonOutOfTime(event) && (
                                    <img
                                        src={soonOutDatedStatusImg}
                                        alt="soonOutDatedStatusImg"
                                        className={classes.infoIcons}
                                    />
                                ))}
                            <Typography
                                align="center"
                                className={`${
                                    this.isReplaceOutOfTime(event)
                                        ? classes.colorRed
                                        : this.isReplaceSoonOutOfTime(event)
                                        ? classes.colorOrange
                                        : ''
                                } ${classes.hideInTab}`}
                            >
                                {hourReplacement * numberOfControle -
                                    hourActual +
                                    'h'}
                            </Typography>
                        </div>
                    </div>
                )}
            </>
        )
    }

    renderIcons(event, { part = null, equipment = null }) {
        const { classes } = this.props

        return (
            <>
                <div className={classes.iconActions}>
                    <BoatOnBlock
                        onlyOneId={true}
                        blockIds={[45, 40]}
                        children={
                            <Tooltip
                                classes={{ tooltip: classes.normalTooltip }}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            {this.displayText('helpEdit')}
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <IconButton
                                    className={classes.iconsEdit}
                                    onClick={e => {
                                        this.handleOpenEdit(e, {
                                            part,
                                            equipment,
                                        })
                                    }}
                                    size={`medium`}
                                >
                                    <img
                                        src={EditList}
                                        alt="edit"
                                        width="30px"
                                    />
                                </IconButton>
                            </Tooltip>
                        }
                    />

                    {equipment && (
                        <Divider
                            orientation="vertical"
                            className={classes.dividerH}
                        />
                    )}
                </div>
                <Popover
                    id={'popover'}
                    open={this.state.openPopOverMore || false}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClosePopOverMore}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{
                        marginTop: '5px',
                    }}
                >
                    <div className={classes.globalIcon}>
                        <div className={classes.iconVisibilityEdit}>
                            <IconButton
                                className={classes.iconsEdit}
                                onClick={() => {
                                    this.handleOpenModalRepair()
                                }}
                                size={`medium`}
                            >
                                <img src={EditList} alt="edit" width="30px" />
                            </IconButton>
                        </div>
                    </div>
                </Popover>
            </>
        )
    }

    _renderPartBorder(status, isLastPart, isLastEquipment, index) {
        const { classes } = this.props

        return `${status !== false ? classes.panelWithStatus : ''} ${
            isLastPart === false || isLastEquipment === false
                ? classes.removeBottomBorder
                : ''
        } ${index === 0 ? classes.removeTopBorder : ''} `
    }

    _renderPart(partInfo, index, isLastPart = false, isLastEquipment = false) {
        const { classes, checkedBoxes } = this.props
        const part = {
            id: parseInt(partInfo[0]),
            partType: partInfo[1].partType,
            userPart: partInfo[1].userPart,
        }

        const event = partInfo[1].event

        const stockPart = getElementStock(this.props.events, {
            partId: part.id,
        })

        const status = this.statusToShow(event)

        return (
            <div
                className={`${classes.panelDetail} ${
                    index % 2 === 1 ? classes.panelDetailBlue : ''
                } ${
                    status === 'IS_STATUS_OUTDATED'
                        ? classes.lateLine
                        : status === 'IS_STATUS_SOON_OUTDATED'
                        ? classes.soonOutDatedLine
                        : status === 'IS_STATUS_OK'
                        ? classes.okLine
                        : ''
                } ${this._renderPartBorder(
                    status,
                    isLastPart,
                    isLastEquipment,
                    index,
                )}`}
                key={`Detail-${part.id}`}
                style={{
                    height: '64px',
                    minHeight: '64px',
                    boxSizing: 'border-box',
                }}
            >
                <div className={classes.detailPanel}>
                    <BoatOnBlock
                        blockIds={[52, 51, 66, 42]}
                        onlyOneId={true}
                        children={
                            <Checkbox
                                checked={
                                    event &&
                                    checkedBoxes.find(id => id === event.id)
                                        ? true
                                        : false
                                }
                                className={classes.iconsEdit}
                                onChange={e => {
                                    this.props.handleCheckBox(event.id, e)
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                                color="primary"
                            ></Checkbox>
                        }
                    />
                    <div className={classes.panelCategorie}>
                        <div className={classes.partType}>
                            <Typography className={classes.equipement}>
                                {(part && this.getNameElement({ part })) ||
                                    null}
                            </Typography>

                            <Typography className={classes.type}>
                                {part && event.detail && event.detail.brand
                                    ? event.detail.brand
                                    : ''}
                            </Typography>
                        </div>

                        <div className={classes.cssDatePart}>
                            {this.renderMuiPicker(event)}

                            <div className={classes.stock}>
                                {this.renderStock(stockPart, event)}
                            </div>
                        </div>
                        {this.renderIcons(event, {
                            part,
                        })}
                    </div>
                </div>
            </div>
        )
    }

    _renderEquipmentWithoutEventBorder(
        isLastEquipment,
        equipment,
        equipmentMinusOne,
    ) {
        const { unfoldAllEquipment } = this.state
        const { classes } = this.props

        return `${
            isLastEquipment === false &&
            !unfoldAllEquipment.find(id => id === equipment.id)
                ? classes.removeBottomBorder
                : ''
        } ${isLastEquipment ? classes.lastBottomBorder : ''} ${
            equipmentMinusOne &&
            Object.entries(equipmentMinusOne[1].parts).length === 0
                ? classes.removeTopBorder
                : ''
        }`
    }

    _renderEquipmentWithoutEvent(
        equipmentInfo,
        equipmentFamily,
        index,
        firstEquipmentFamily,
        isLastEquipment,
        equipmentMinusOne,
    ) {
        const { classes } = this.props
        const { unfoldAllEquipment } = this.state
        const equipment = {
            id: parseInt(equipmentInfo[0]),
            equipmentType: equipmentInfo[1].equipmentType,
            userEquipment: equipmentInfo[1].userEquipment,
        }

        return (
            <Accordion
                key={`Accordion-${equipment.id}`}
                square
                expanded={
                    unfoldAllEquipment.find(id => id === equipment.id)
                        ? true
                        : false
                }
                classes={{
                    root: classes.extension,
                }}
            >
                <AccordionSummary
                    className={`${
                        classes.panelSummary
                    } ${this._renderEquipmentWithoutEventBorder(
                        isLastEquipment,
                        equipment,
                        equipmentMinusOne,
                    )}`}
                    onClick={() => {
                        this.handleUnfold(equipment.id, 'unfoldAllEquipment')
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{
                                width: '48px',
                            }}
                            color="primary"
                        />
                    }
                    style={{
                        height: '60px',
                    }}
                    classes={{
                        root: index % 2 === 1 ? null : classes.backgroundBlue,
                        content: classes.line,
                        expandIcon: classes.expandButton,
                    }}
                >
                    <div className={classes.firstPanel}>
                        <div className={classes.panelCategorieEquipmentNoEvent}>
                            <div className={classes.equipementType}>
                                <Typography className={classes.equipement}>
                                    {(equipment &&
                                        this.getNameElement({ equipment })) ||
                                        null}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.container}
                    children={
                        equipmentInfo[1] && equipmentInfo[1].parts ? (
                            Object.entries(
                                equipmentInfo[1].parts,
                            ).map((part, index) =>
                                this._renderPart(
                                    part,
                                    index,
                                    Object.entries(equipmentInfo[1].parts)
                                        .length ===
                                        index + 1,
                                    isLastEquipment,
                                ),
                            )
                        ) : (
                            <></>
                        )
                    }
                />
            </Accordion>
        )
    }

    _renderFamily(family, firstEquipmentFamily) {
        const { classes } = this.props
        const { unfoldAllFamily } = this.state
        const equipmentFamily = {
            id: parseInt(family[0]),
            type: family[1].type,
            user: family[1].user,
        }

        if (family[1] && family[1].equipments)
            return (
                <Accordion
                    key={`Accordion-${equipmentFamily.id}`}
                    classes={{
                        root: classes.accordionFamily,
                    }}
                    square
                    expanded={
                        unfoldAllFamily.find(id => id === equipmentFamily.id)
                            ? true
                            : false
                    }
                >
                    <AccordionSummary
                        onClick={() =>
                            this.handleUnfold(
                                equipmentFamily.id,
                                'unfoldAllFamily',
                            )
                        }
                        expandIcon={
                            <ExpandMoreIcon
                                style={{
                                    width: '48px',
                                }}
                                color="primary"
                            />
                        }
                        style={{
                            minHeight: '41px',
                            paddingRight: '2px',
                        }}
                        classes={{
                            root: classes.backgroundFamily,
                            content: classes.lineAccordeon,
                            expandIcon: classes.expandButton,
                            expanded: classes.expandedFixRadius,
                        }}
                    >
                        <img
                            src={
                                equipmentFamily?.type?.image
                                    ? require(`images/${equipmentFamily.type.image}`)
                                    : eyeicon
                            }
                            alt="bellicon"
                            className={classes.catIconTop}
                        />
                        <Typography className={classes.categorieFamily}>
                            {this.getNameElement({ equipmentFamily })}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        className={classes.containerFamily}
                        children={
                            <>
                                {' '}
                                {Object.entries(family[1].equipments).map(
                                    (e, index) => (
                                        <>
                                            {this._renderEquipment(
                                                e,
                                                equipmentFamily,
                                                index,
                                                firstEquipmentFamily,
                                                Object.entries(
                                                    family[1].equipments,
                                                ).length ===
                                                    index + 1,
                                                index > 0
                                                    ? Object.entries(
                                                          family[1].equipments,
                                                      )[index - 1]
                                                    : null,
                                            )}
                                        </>
                                    ),
                                )}
                            </>
                        }
                    />
                </Accordion>
            )
        return <></>
    }

    isReplaceOutDated(event) {
        const today = new Date()
        return (
            event?.delimitedDate?.endDate &&
            today >= new Date(event.delimitedDate.endDate)
        )
    }

    isReplaceOutOfTime(event) {
        const lastDateReplacement = 0

        return (
            event.detail.hourActual &&
            event.detail.hourReplacement &&
            ((event.detail.hourActual -
                lastDateReplacement -
                lastDateReplacement) *
                100) /
                (event.detail.hourReplacement - lastDateReplacement) >=
                hoursRedThreshold
        )
    }

    isReplaceSoonOutOfTime(event) {
        const lastDateReplacement = 0

        return (
            event.detail.hourActual &&
            event.detail.hourReplacement &&
            ((event.detail.hourActual -
                lastDateReplacement -
                lastDateReplacement) *
                100) /
                (event.detail.hourReplacement - lastDateReplacement) >=
                hoursOrangeThreshold
        )
    }

    isReplaceSoonOutDated(event) {
        const today = new Date()
        const dateIn3Months = new Date(
            today.setMonth(today.getMonth() + monthsThreshold),
        )
        return (
            event?.delimitedDate?.endDate &&
            dateIn3Months >= new Date(event.delimitedDate.endDate)
        )
    }

    isElementAlreadyReplaceOnce(events, { partId = null, equipmentId = null }) {
        const stock = getElementStock(this.props.events, {
            equipmentId: equipmentId,
            partId: partId,
        })
        for (const event of events)
            if (
                (event.detail &&
                    event.detail.archived &&
                    (event.detail?.part?.id === partId ||
                        event.detail?.equipement?.id === equipmentId)) ||
                stock > 0
            )
                return true
        return false
    }

    isStatusOk(event, stock, isAlreadyReplaceOnce) {
        const today = new Date()
        const dateIn3Months = new Date(
            today.setMonth(today.getMonth() + monthsThreshold),
        )
        if (
            event?.delimitedDate?.endDate &&
            dateIn3Months < new Date(event.delimitedDate.endDate) &&
            (isAlreadyReplaceOnce === true ||
                event?.detail?.hourReplacement ||
                event?.delimitedDate?.endDate) &&
            stock > 0
        ) {
            return true
        }
        return false
    }

    isStatusSoonOutDated(event, stock, isAlreadyReplaceOnce) {
        if (
            (this.isReplaceSoonOutDated(event) &&
                isAlreadyReplaceOnce === true &&
                stock > 0) ||
            (this.isReplaceSoonOutOfTime(event) &&
                isAlreadyReplaceOnce === true &&
                stock > 0)
        ) {
            return true
        }
        return false
    }

    isStatusOutDated(event, stock, isAlreadyReplaceOnce) {
        if (
            this.isReplaceOutDated(event) ||
            this.isReplaceOutOfTime(event) ||
            ((isAlreadyReplaceOnce === true ||
                event?.detail?.hourReplacement ||
                event?.delimitedDate?.endDate) &&
                stock === 0)
        ) {
            return true
        }
        return false
    }

    getAllElementEvent(events, { partId = null, equipmentId = null }) {
        const eventsSelected = []
        if (partId) {
            events.forEach(event => {
                if (
                    event.detail &&
                    event.detail.part &&
                    event.detail.part.id === partId &&
                    !event.delimitedDate &&
                    !event.detail.archived
                )
                    eventsSelected.push(event)
            })
        } else if (equipmentId) {
            events.forEach(event => {
                if (
                    event.detail &&
                    event.detail.equipment &&
                    event.detail.equipment.id === equipmentId &&
                    !event.delimitedDate &&
                    !event.detail.archived
                )
                    eventsSelected.push(event)
            })
        }
        return eventsSelected
    }

    statusToShow(event) {
        const stock = getElementStock(this.props.events, {
            equipmentId: event.detail?.equipment?.id,
            partId: event.detail?.part?.id,
        })
        const isAlreadyReplaceOnce = this.isElementAlreadyReplaceOnce(
            this.props.events,
            {
                equipmentId: event.detail?.equipment?.id,
                partId: event.detail?.part?.id,
            },
        )
        if (this.isStatusOutDated(event, stock, isAlreadyReplaceOnce))
            return 'IS_STATUS_OUTDATED'
        else if (this.isStatusSoonOutDated(event, stock, isAlreadyReplaceOnce))
            return 'IS_STATUS_SOON_OUTDATED'
        else if (this.isStatusOk(event, stock, isAlreadyReplaceOnce))
            return 'IS_STATUS_OK'
        return false
    }

    _renderEquipmentBorder(status, isLastEquipment, equipmentMinusOne) {
        const { classes } = this.props

        return `${status !== false ? classes.panelWithStatus : ''} ${
            isLastEquipment === false ? classes.removeBottomBorder : ''
        } ${isLastEquipment ? classes.lastBottomBorder : ''} ${
            equipmentMinusOne &&
            Object.entries(equipmentMinusOne[1].parts).length === 0
                ? classes.removeTopBorder
                : ''
        }`
    }

    _renderEquipment(
        equipmentInfo,
        equipmentFamily,
        index,
        firstEquipmentFamily,
        isLastEquipment = false,
        equipmentMinusOne,
    ) {
        const { classes, checkedBoxes } = this.props
        const { unfoldAllEquipment } = this.state
        const equipment = {
            id: parseInt(equipmentInfo[0]),
            equipmentType: equipmentInfo[1].equipmentType,
            userEquipment: equipmentInfo[1].userEquipment,
        }
        const event = equipmentInfo[1].event
        const stockEquipment = getElementStock(this.props.events, {
            equipmentId: equipment.id,
        })

        if (!event) {
            return this._renderEquipmentWithoutEvent(
                equipmentInfo,
                equipmentFamily,
                index,
                firstEquipmentFamily,
                isLastEquipment,
                equipmentMinusOne,
            )
        }

        const status = this.statusToShow(event)

        return (
            <Accordion
                key={`Accordion-${equipment.id}`}
                square
                expanded={
                    unfoldAllEquipment.find(id => id === equipment.id)
                        ? true
                        : false
                }
                classes={{
                    root: classes.extension,
                }}
            >
                <AccordionSummary
                    className={`${classes.panelSummary} ${
                        status === 'IS_STATUS_OUTDATED'
                            ? classes.lateLine
                            : status === 'IS_STATUS_SOON_OUTDATED'
                            ? classes.soonOutDatedLine
                            : status === 'IS_STATUS_OK'
                            ? classes.okLine
                            : ''
                    } ${this._renderEquipmentBorder(
                        status,
                        isLastEquipment,
                        equipmentMinusOne,
                    )}`}
                    onClick={() => {
                        this.handleUnfold(equipment.id, 'unfoldAllEquipment')
                    }}
                    expandIcon={
                        <IconButton
                            style={{
                                width: '48px',
                            }}
                        >
                            <ExpandMoreIcon color="primary" />
                        </IconButton>
                    }
                    style={{
                        height: '64px',
                        minHeight: '64px',
                    }}
                    classes={{
                        root: index % 2 === 1 ? null : classes.backgroundBlue,
                        content: classes.line,
                        expandIcon: classes.expandButton,
                        expanded: classes.expanded,
                    }}
                >
                    <div className={classes.firstPanel}>
                        <BoatOnBlock
                            blockIds={[52, 51, 66, 42]}
                            onlyOneId={true}
                            children={
                                <Checkbox
                                    checked={
                                        event &&
                                        checkedBoxes.find(id => id === event.id)
                                            ? true
                                            : false
                                    }
                                    className={classes.iconsEdit}
                                    onChange={e => {
                                        this.props.handleCheckBox(event.id, e)
                                    }}
                                    onClick={e => {
                                        e.stopPropagation()
                                    }}
                                    color="primary"
                                ></Checkbox>
                            }
                        />
                        <div className={classes.panelCategorieEquipment}>
                            <div className={classes.equipementType}>
                                <Typography className={classes.equipement}>
                                    {(equipment &&
                                        this.getNameElement({ equipment })) ||
                                        null}
                                </Typography>

                                <Typography className={classes.type}>
                                    {event && event.detail && event.detail.brand
                                        ? event.detail.brand
                                        : ''}
                                </Typography>
                            </div>

                            <div className={classes.cssDateEquipment}>
                                {this.renderMuiPicker(event)}
                                <div className={classes.stock}>
                                    {this.renderStock(stockEquipment, event)}
                                </div>
                            </div>

                            {this.renderIcons(event, {
                                equipment,
                            })}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.container}
                    children={
                        equipmentInfo[1] && equipmentInfo[1].parts ? (
                            Object.entries(
                                equipmentInfo[1].parts,
                            ).map((part, index) =>
                                this._renderPart(
                                    part,
                                    index,
                                    Object.entries(equipmentInfo[1].parts)
                                        .length ===
                                        index + 1,
                                    isLastEquipment,
                                ),
                            )
                        ) : (
                            <></>
                        )
                    }
                />
            </Accordion>
        )
    }

    render() {
        const {
            events,
            unfoldAllEquipment,
            unfoldAllFamily,
            inventories,
        } = this.state
        const {
            classes,
            loadingEvents,
            subscriptions,
            checkedBoxes,
            filteredEvents,
            boat,
        } = this.props
        let firstEquipmentFamily = null

        const isMobile = window.innerWidth < 600

        return (
            <>
                {loadingEvents !== 0 || !boat || !events || events === null ? (
                    this.renderLoading(`100px`, `100px`, true)
                ) : (
                    <>
                        <div className={classes.root}>
                            {isMobile && (
                                <Typography className={classes.title}>
                                    {this.displayText('inventaire')} -{' '}
                                    {boat.name}
                                </Typography>
                            )}
                            {this.renderStockBoxes(filteredEvents)}
                            <BobFilter
                                fieldName={'events'}
                                editableButton
                                editableButtonBlockIds={41}
                                editableButtonLabel={this.displayText(
                                    'addElement',
                                )}
                                editableButtonDropDown={[
                                    {
                                        label: this.displayText(
                                            `addEquipement`,
                                        ),
                                        action: () =>
                                            this.newInventory(
                                                `INVENTORY_EQUIPMENT`,
                                            ),
                                    },
                                    {
                                        label: this.displayText(`addPart`),
                                        action: () =>
                                            this.newInventory(`INVENTORY_PART`),
                                        disabled: !userActions.checkSubscriptionPage(
                                            subscriptions,
                                            6,
                                        ),
                                    },
                                ]}
                                unfoldAll={[
                                    ...unfoldAllEquipment,
                                    ...unfoldAllFamily,
                                ]}
                                getNbEquipment={this.getNbActiveEquipment}
                                nbEvent={
                                    events.filter(
                                        event =>
                                            event.delimitedDate &&
                                            event.delimitedDate.startDate &&
                                            !event.detail.archived,
                                    ).length
                                }
                                handleUnfoldAll={value =>
                                    this.handleUnfoldAll(
                                        value,
                                        'unfoldAllEquipment',
                                    )
                                }
                                selectAll={value => {
                                    this.selectAll(value, filteredEvents)
                                }}
                                checkedBoxes={checkedBoxes}
                                activeButton={{
                                    stockPart: true,
                                    comissionDate: true,
                                    replacementDate: true,
                                    equipmentFamily: true,
                                }}
                            />

                            {!userActions.checkSubscriptionPage(
                                subscriptions,
                                6,
                            ) && (
                                <BoatOnAlert
                                    severity={`info`}
                                    linkText={this.displayText(`premium`)}
                                    description={this.displayText(`descr`)}
                                    linkAction={
                                        !subscriptions ||
                                        subscriptions.length === 0
                                            ? this.redirectPricing
                                            : this.openCustomerPortal
                                    }
                                />
                            )}
                            <HourAlert />
                            {/* Ici le tableau des equipements  */}
                            {inventories && inventories.equipmentFamilies && (
                                <>
                                    {Object.entries(
                                        inventories.equipmentFamilies,
                                    ).map((family, index) => {
                                        if (index === 0)
                                            firstEquipmentFamily = family
                                        return (
                                            <div key={index}>
                                                {this._renderFamily(
                                                    family,
                                                    firstEquipmentFamily,
                                                )}
                                            </div>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    </>
                )}
                <BoatOnModal
                    openCondition={this.state.modalOpen}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        history: (
                            <InventoryHistoryModal
                                part={this.state.part}
                                equipment={this.state.equipment}
                                save={this.save}
                                saveMenuModalIndex={this.saveMenuModalIndex}
                                menuModalIndex={this.state.menuModalIndex}
                            />
                        ),
                        repair: (
                            <InventoryModal
                                event={this.state.event}
                                type={this.state.modalType}
                            ></InventoryModal>
                        ),
                        addEngine: (
                            <div
                                style={{
                                    height: 110,
                                    padding: 15,
                                    textAlign: `center`,
                                    display: `flex`,
                                    alignItems: `center`,
                                    justifyContents: `center`,
                                    flexDirection: `column`,
                                }}
                            >
                                <p> {this.displayText(`addEngine`)} </p>
                                <ButtonBON
                                    label={this.displayText(`understood`)}
                                    onClick={this.handleCloseModal}
                                />
                            </div>
                        ),
                    }}
                    titles={{
                        repair: this.displayText('inventaire'),
                        history: this.getNameElement({
                            part: this.state.part,
                            equipment: this.state.equipment,
                        }),
                        addEngine: 'Ajouter un moteur',
                    }}
                    maxWidth={{
                        addEngine: `xs`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        boat: state.bob.boat,
        events: state.bob.events,
        filteredEvents: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                commissioningDate: true,
                replacementDate: true,
                onlyActiveDetails: true,
                stock: true,
                inventoryEquipmentFamily: true,
            },
        ),
        loadingEvents: state.bob.loading,
        bobFilter: state.filter.bobFilter,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        currentGroupId: state.group.currentGroupId,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(TopInventory))
