import dayjs from 'dayjs'
import { filterConstants } from '../constants'
import { getElementStock, getNameFromRepair } from './bob.actions'
import { fncDeepFilterOptions } from '../components/common/Utils'

export const filterAction = filter => {
    return { type: filterConstants.FILTER_ACTION, filter: filter }
} // je garde le export ici car deja utiliser on changera en temps voulu

const updateFilterBob = ({
    searchString = null,
    rangeDate = null,
    commissioningDate = null,
    replacementDate = null,
    inStock = null,
    toRenew = null,
    missing = null,
    userEquipmentFamily = null,
    equipmentFamilyType = null,
    checkupTypesChecked = null,
    workEventTypesChecked = null,
    absenceStatesChecked = null,
    workTimeOptionsChecked = null,
    rangeAmount = null,
    rangeHour = null,
    userEquipment = null,
    equipmentType = null,
    userAttachedRoleType = null,
    userAttachedUserRole = null,
    userManagerSearch = null,
    unregisteredManagerSearch = null,
    selectedBoat = null,
    selectedBoatByFleet = null,
    selectedBoatsByFleet = null,
    selectedCrew = null,
    orderStatusTypesChecked = null,
    orderOperatorChecked = null,
    equipmentPartsChecked = null,
    navigationStatusChecked = null,
}) => {
    return dispatch => {
        dispatch({
            type: filterConstants.FILTER_BOB_UPDATE,
            filter: {
                searchString: searchString,
                rangeDate: rangeDate,
                commissioningDate: commissioningDate,
                replacementDate: replacementDate,
                inStock: inStock,
                toRenew: toRenew,
                missing: missing,
                equipmentFamilyType: equipmentFamilyType,
                equipmentType: equipmentType,
                userEquipmentFamily: userEquipmentFamily,
                userEquipment: userEquipment,
                checkupTypesChecked: checkupTypesChecked,
                workEventTypesChecked: workEventTypesChecked,
                absenceStatesChecked: absenceStatesChecked,
                workTimeOptionsChecked: workTimeOptionsChecked,
                rangeAmount: rangeAmount,
                rangeHour: rangeHour,
                userAttachedRoleType: userAttachedRoleType,
                userAttachedUserRole: userAttachedUserRole,
                userManagerSearch: userManagerSearch,
                unregisteredManagerSearch: unregisteredManagerSearch,
                selectedBoat: selectedBoat,
                selectedBoatByFleet: selectedBoatByFleet,
                selectedBoatsByFleet: selectedBoatsByFleet,
                selectedCrew: selectedCrew,
                orderStatusTypesChecked: orderStatusTypesChecked,
                orderOperatorChecked: orderOperatorChecked,
                equipmentPartsChecked: equipmentPartsChecked,
                navigationStatusChecked: navigationStatusChecked,
            },
        })
    }
}

const resetFilterBob = () => {
    return dispatch => {
        dispatch({
            type: filterConstants.FILTER_BOB_RESET,
        })
    }
}

const _isElementAlreadyReplaceOnce = (
    events,
    { partId = null, equipmentId = null },
) => {
    const stock = getElementStock(events, {
        equipmentId: equipmentId,
        partId: partId,
    })
    for (const event of events)
        if (
            (event.detail &&
                event.detail.archived &&
                (event.detail?.part?.id === partId ||
                    event.detail?.equipement?.id === equipmentId)) ||
            stock > 0
        )
            return true
    return false
}

const filterStock = (events, event, bobFilter) => {
    const dateThreeMonth = new Date(
        new Date().setMonth(new Date().getMonth() + 3),
    )

    let stock = getElementStock(events, {
        partId: event.detail && event.detail.part ? event.detail.part.id : null,
        equipmentId:
            event.detail && event.detail.equipment
                ? event.detail.equipment.id
                : null,
    })
    const isAlreadyReplaceOnce = _isElementAlreadyReplaceOnce(events, {
        equipmentId: event.detail?.equipment?.id,
        partId: event.detail?.part?.id,
    })

    if (
        (bobFilter.inStock === false || stock > 0) &&
        (bobFilter.missing === false ||
            (stock === 0 &&
                (event.detail?.hourReplacement ||
                    event?.delimitedDate?.endDate ||
                    isAlreadyReplaceOnce))) &&
        (bobFilter.toRenew === false ||
            !(
                !event.delimitedDate.endDate ||
                (dateThreeMonth.getTime() <
                    new Date(event.delimitedDate.endDate).getTime() &&
                    new Date().getTime() <=
                        new Date(event.delimitedDate.endDate).getTime())
            ))
    ) {
        return true
    }
    return false
}

const filterReplacementDate = (event, bobFilter) => {
    return (
        !bobFilter.replacementDate.start ||
        (event.delimitedDate &&
            bobFilter.replacementDate.start &&
            bobFilter.replacementDate.start.getTime() <
                new Date(event.delimitedDate.endDate).getTime() &&
            (!bobFilter.replacementDate.end ||
                (bobFilter.replacementDate.end &&
                    bobFilter.replacementDate.end.getTime() >
                        new Date(event.delimitedDate.endDate).getTime())))
    )
}
const filterCommissioningDate = (event, bobFilter) => {
    return (
        !bobFilter.commissioningDate.start ||
        (event.delimitedDate &&
            bobFilter.commissioningDate.start &&
            bobFilter.commissioningDate.start.getTime() <
                new Date(event.delimitedDate.startDate).getTime() &&
            (!bobFilter.commissioningDate.end ||
                (bobFilter.commissioningDate.end &&
                    bobFilter.commissioningDate.end.getTime() >
                        new Date(event.delimitedDate.startDate).getTime())))
    )
}
const filterRangeDate = (event, bobFilter) => {
    return (
        !bobFilter.rangeDate ||
        ((!event.delimitedDate ||
            !event.delimitedDate.startDate ||
            !bobFilter.rangeDate.start ||
            new Date(event.delimitedDate.startDate).getTime() >=
                new Date(bobFilter.rangeDate.start).getTime()) &&
            (!event.delimitedDate ||
                !event.delimitedDate.startDate ||
                !bobFilter.rangeDate.end ||
                new Date(event.delimitedDate.startDate).getTime() <=
                    new Date(bobFilter.rangeDate.end).getTime()))
    )
}
const filterAmountRange = (event, bobFilter) => {
    return (
        !bobFilter.rangeAmount.min ||
        !bobFilter.rangeAmount.max ||
        (event.transaction &&
            event.transaction.amountIt &&
            event.transaction.amountIt >= bobFilter.rangeAmount.min &&
            event.transaction.amountIt <= bobFilter.rangeAmount.max)
    )
}
const filterRangeHour = (event, bobFilter) => {
    return (
        (!bobFilter.rangeHour.min && !bobFilter.rangeHour.max) ||
        (event.actualDuration != null &&
            event.actualDuration >= bobFilter.rangeHour.min &&
            event.actualDuration <= bobFilter.rangeHour.max)
    )
}

const _filterCheckupEquipmentFamily = (event, bobFilter) => {
    return (
        bobFilter.equipmentFamilyType.length +
            bobFilter.userEquipmentFamily.length ===
            0 ||
        !bobFilter.equipmentFamilyType ||
        (event?.checkup?.equipments &&
            Array.isArray(event.checkup.equipments) &&
            event.checkup.equipments.length > 0 &&
            event.checkup.equipments.some(
                equipment =>
                    equipment?.equipmentFamily?.type &&
                    bobFilter.equipmentFamilyType.includes(
                        equipment.equipmentFamily.type.id,
                    ),
            )) ||
        (event?.checkup?.parts &&
            Array.isArray(event.checkup.parts) &&
            event.checkup.parts.length > 0 &&
            event.checkup.parts.some(
                part =>
                    part?.equipment?.equipmentFamily?.type &&
                    bobFilter.equipmentFamilyType.includes(
                        part.equipment.equipmentFamily.type.id,
                    ),
            )) ||
        !bobFilter.userEquipmentFamily ||
        (event?.checkup?.equipments &&
            Array.isArray(event.checkup.equipments) &&
            event.checkup.equipments.length > 0 &&
            event.checkup.equipments.some(
                equipment =>
                    equipment?.equipmentFamily?.userEquipmentFamily &&
                    bobFilter.userEquipmentFamily.includes(
                        equipment.equipmentFamily.userEquipmentFamily.id,
                    ),
            )) ||
        (event?.checkup?.parts &&
            Array.isArray(event.checkup.parts) &&
            event.checkup.parts.length > 0 &&
            event.checkup.parts.some(
                part =>
                    part?.equipment?.equipmentFamily?.userEquipmentFamily &&
                    bobFilter.userEquipmentFamily.includes(
                        part.equipment.equipmentFamily.userEquipmentFamily.id,
                    ),
            ))
    )
}

const _filterInventoryEquipmentFamily = (event, bobFilter) => {
    return (
        bobFilter.equipmentFamilyType.length +
            bobFilter.userEquipmentFamily.length ===
            0 ||
        !bobFilter.equipmentFamilyType ||
        (event?.detail?.equipment?.equipmentFamily?.type?.id &&
            bobFilter.equipmentFamilyType.includes(
                event.detail.equipment.equipmentFamily.type.id,
            )) ||
        (event?.detail?.part?.equipment?.equipmentFamily?.type?.id &&
            bobFilter.equipmentFamilyType.includes(
                event.detail.part.equipment.equipmentFamily.type.id,
            )) ||
        !bobFilter.userEquipmentFamily ||
        (event?.detail?.equipment?.equipmentFamily?.userEquipmentFamily?.id &&
            bobFilter.userEquipmentFamily.includes(
                event.detail.equipment.equipmentFamily.userEquipmentFamily.id,
            )) ||
        (event?.detail?.part?.equipment?.equipmentFamily?.userEquipmentFamily
            ?.id &&
            bobFilter.userEquipmentFamily.includes(
                event.detail.part.equipment.equipmentFamily.userEquipmentFamily
                    .id,
            ))
    )
}

const _filterCalendarEquipmentFamily = (event, bobFilter) => {
    return (
        _filterCheckupEquipmentFamily(event, bobFilter) ||
        _filterInventoryEquipmentFamily(event, bobFilter)
    )
}

const _filterManagerAttached = (event, bobFilter, groupMembers) => {
    return (
        bobFilter.userManagerSearch.length +
            bobFilter.unregisteredManagerSearch.length ===
            0 ||
        (event?.attachedTo &&
            event.attachedTo.length > 0 &&
            event.attachedTo.some(attached =>
                groupMembers?.linkRGU.some(
                    member =>
                        (member?.user?.id &&
                            attached?.userRtq?.id &&
                            member.user.id === attached.userRtq.id &&
                            bobFilter.userManagerSearch.find(
                                search => search === member.user.id,
                            )) ||
                        (member?.userSubscribe?.id &&
                            attached?.userSubscribeRtq?.id &&
                            member.userSubscribe.id ===
                                attached.userSubscribeRtq.id &&
                            bobFilter.unregisteredManagerSearch.find(
                                search => search === member.userSubscribe.id,
                            )),
                ),
            )) ||
        ((event?.attachedTo || event.attachedTo.length === 0) &&
            bobFilter.userManagerSearch.includes(-1))
    )
}

const _filterUserWorkTimeAbsences = (event, bobFilter, groupMembers) => {
    const userLink = groupMembers.linkRGU.find(
        gm => gm?.id === event.userLinkId,
    )

    return (
        (userLink?.user &&
            bobFilter.userAttachedUserRole.includes(userLink.user.id)) ||
        (userLink?.userSubscribe &&
            bobFilter.userAttachedUserRole.includes(
                userLink.userSubscribe.id,
            )) ||
        (userLink?.user &&
            bobFilter.userAttachedRoleType.includes(userLink.user.id)) ||
        (userLink?.userSubscribe &&
            bobFilter.userAttachedRoleType.includes(
                userLink.userSubscribe.id,
            )) ||
        (bobFilter.userAttachedUserRole.length === 0 &&
            bobFilter.userAttachedRoleType.length === 0)
    )
}

const _filterUserAttachedRole = (event, bobFilter, groupMembers) => {
    return (
        bobFilter.userAttachedUserRole.length +
            bobFilter.userAttachedRoleType.length ===
            0 ||
        (event?.attachedTo &&
            event.attachedTo.length > 0 &&
            event.attachedTo.some(attached =>
                groupMembers?.linkRGU.some(
                    member =>
                        (member?.user?.id &&
                            attached?.user?.id &&
                            member.user.id === attached.user.id &&
                            bobFilter.userAttachedRoleType.find(
                                search => search === member.user.id,
                            )) ||
                        (member?.userSubscribe?.id &&
                            attached?.userSubscribe?.id &&
                            member.userSubscribe.id ===
                                attached.userSubscribe.id &&
                            bobFilter.userAttachedUserRole.find(
                                search => search === member.userSubscribe.id,
                            )),
                ),
            )) ||
        ((event?.attachedTo || event.attachedTo.length === 0) &&
            bobFilter.userAttachedRoleType.includes(-1))
    )
}

const filterEquipment = (event, bobFilter) => {
    return (
        bobFilter.equipmentType.length + bobFilter.userEquipment.length === 0 ||
        !bobFilter.equipmentType ||
        (event.checkup &&
            event.checkup.equipments &&
            Array.isArray(event.checkup.equipments) &&
            event.checkup.equipments.length > 0 &&
            event.checkup.equipments.some(
                equipment =>
                    equipment.equipmentType &&
                    bobFilter.equipmentType.includes(
                        equipment.equipmentType.id,
                    ),
            )) ||
        !bobFilter.userEquipment ||
        (event.checkup &&
            event.checkup.equipments &&
            Array.isArray(event.checkup.equipments) &&
            event.checkup.equipments.length > 0 &&
            event.checkup.equipments.some(
                equipment =>
                    equipment.userEquipment &&
                    bobFilter.userEquipment.includes(
                        equipment.userEquipment.id,
                    ),
            )) ||
        !bobFilter.equipmentType ||
        (event.checkup &&
            event.checkup.parts &&
            Array.isArray(event.checkup.parts) &&
            event.checkup.parts.length > 0 &&
            event.checkup.parts.some(
                part =>
                    part.equipment.equipmentType &&
                    bobFilter.equipmentType.includes(
                        part.equipment.equipmentType.id,
                    ),
            )) ||
        !bobFilter.userEquipment ||
        (event.checkup &&
            event.checkup.parts &&
            Array.isArray(event.checkup.parts) &&
            event.checkup.parts.length > 0 &&
            event.checkup.parts.some(
                part =>
                    part.equipment.userEquipment &&
                    bobFilter.userEquipment.includes(
                        part.equipment.userEquipment.id,
                    ),
            ))
    )
}
const filterSearchString = (event, bobFilter, isRepair = false, context) => {
    let title = event.title.toLowerCase()
    if (isRepair) {
        title = `${getNameFromRepair(event, context)?.toLowerCase()} - ${title}`
    }

    return (
        !bobFilter.searchString ||
        fncDeepFilterOptions(
            event,
            { inputValue: bobFilter.searchString },
            context,
        ) ||
        title.toLowerCase().includes(bobFilter.searchString.toLowerCase()) ||
        (event.checkup &&
            event.checkup.checkupType &&
            (event.checkup.checkupType.translation?.[context]
                .toLowerCase()
                .includes(bobFilter.searchString.toLowerCase()) ||
                (event.checkup.userCheckup &&
                    event.checkup.userCheckup.name
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase())))) ||
        (event.detail &&
            ((event.detail.equipment &&
                event.detail.equipment.equipmentType &&
                event.detail.equipment.equipmentType.translation?.[context]
                    .toLowerCase()
                    .includes(bobFilter.searchString.toLowerCase())) ||
                (event.detail.equipment &&
                    event.detail.equipment.userEquipment &&
                    event.detail.equipment.userEquipment.name
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase())) ||
                (event.detail.part &&
                    event.detail.part.partType &&
                    event.detail.part.partType.translation &&
                    event.detail.part.partType.translation?.[context]
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase())) ||
                (event.detail.part &&
                    event.detail.part.userPart &&
                    event.detail.part.userPart.name
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase())) ||
                (event.detail.brand &&
                    event.detail.brand
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase()))))
    )
}
const filterUserRole = (linkRGU, user) => {
    for (const link of linkRGU) {
        if (
            link.user?.email === user?.email ||
            link.userSubscribe?.mail === user?.email
        ) {
            return link.userRole
        }
    }
}

const _filterWorkTimeState = (event, bobFilter, userGroup) => {
    if (bobFilter.workTimeOptionsChecked.length === 0) return true

    if (!userGroup) return false

    const { totalMinutes } = calculateTotalTime(event)

    const day = new Date(
        event.delimitedDate.startDate,
    ).toLocaleDateString(`en-US`, { weekday: `long` })
    const daySetting = userGroup[day.toLowerCase() + `Setting`]
    if (!daySetting) {
        return false
    }
    const expectedTotalWorkTime = calculateTotalTime(daySetting)

    // 1 - Overtime
    if (
        bobFilter.workTimeOptionsChecked.indexOf(1) !== -1 &&
        Math.abs(totalMinutes) > expectedTotalWorkTime.totalMinutes
    ) {
        return true
    }

    // 2 - Undertime
    if (
        bobFilter.workTimeOptionsChecked.indexOf(2) !== -1 &&
        Math.abs(totalMinutes) < expectedTotalWorkTime.totalMinutes
    ) {
        return true
    }

    return false
}

const calculateTotalTime = (item, nbDays = 1) => {
    let { startTime, endTime, breakTimes } = item

    let returnValue = {
        hours: null,
        minutes: null,
        totalMinutes: null,
    }
    if (startTime && endTime) {
        if (!dayjs.isDayjs(startTime)) startTime = dayjs(startTime, `HH:mm:ss`)
        if (!dayjs.isDayjs(endTime)) endTime = dayjs(endTime, `HH:mm:ss`)

        if (dayjs(startTime).isAfter(dayjs(endTime)))
            endTime = dayjs(endTime).add(1, 'day')

        let total = dayjs(endTime).diff(dayjs(startTime))

        if (breakTimes && Array.isArray(breakTimes) && breakTimes.length > 0)
            breakTimes.forEach(b => {
                if (b.startTime && b.endTime) {
                    let breakStart = dayjs.isDayjs(b.startTime)
                        ? b.startTime
                        : dayjs(b.startTime, `HH:mm:ss`)
                    let breakEnd = dayjs.isDayjs(b.endTime)
                        ? b.endTime
                        : dayjs(b.endTime, `HH:mm:ss`)
                    let breakTime = dayjs(breakEnd).diff(dayjs(breakStart))
                    total -= breakTime
                }
            })

        total *= nbDays

        // Calcul le nombre d'heures
        let h = total / 3600000 - (total % 3600000) / 3600000

        // Calcul le reste des minutes
        const m = (total % 3600000) / 60000

        returnValue.hours = h ? h.toFixed().padStart(2, `0`) : 0
        returnValue.minutes = m
            ? m
                  .toFixed()
                  .toString()
                  .padStart(2, `0`)
            : 0
        returnValue.totalMinutes = total / 60000
    }
    return returnValue
}

const filterWorkEvents = (
    events,
    bobFilter,
    context,
    filterToUse = {
        userAttachedRole: true,
        rangeDate: true,
        workEventType: true,
        absenceState: true,
        workTime: true,
    },
    groupMembers,
    workTimeGroups,
    user,
) => {
    if (
        !events ||
        !bobFilter ||
        !user ||
        !groupMembers ||
        !workTimeGroups ||
        workTimeGroups.length === 0
    )
        return events

    const userLink = groupMembers.linkRGU.find(
        gm =>
            (user?.email && gm?.user?.email === user.email) ||
            gm?.userSubscribe?.mail === user.email,
    )

    const userGroup =
        workTimeGroups.find(wtg =>
            wtg.userLinks.find(ul => ul.id === userLink.id),
        ) ?? workTimeGroups.find(wtg => wtg.byDefault)

    return events.filter(event => {
        return (
            (!filterToUse.rangeDate || filterRangeDate(event, bobFilter)) &&
            (!filterToUse.userAttachedRole ||
                _filterUserWorkTimeAbsences(event, bobFilter, groupMembers)) &&
            (!filterToUse.workEventType ||
                bobFilter.workEventTypesChecked.length === 0 ||
                bobFilter.workEventTypesChecked.indexOf(2) > -1) && //Workaround pour filtrer par jour travaillé
            (!filterToUse.workTime ||
                _filterWorkTimeState(event, bobFilter, userGroup)) &&
            (!filterToUse.absenceState ||
                bobFilter.absenceStatesChecked.length === 0)
        )
    })
}

const filterAbsenceEvents = (
    events,
    bobFilter,
    context,
    filterToUse = {
        userAttachedRole: true,
        rangeDate: true,
        workEventType: true,
        absenceState: true,
        workTime: true,
    },
    groupMembers,
) => {
    if (!events || !bobFilter || !groupMembers) return events

    return events.filter(event => {
        return (
            (!filterToUse.rangeDate || filterRangeDate(event, bobFilter)) &&
            (!filterToUse.userAttachedRole ||
                _filterUserWorkTimeAbsences(event, bobFilter, groupMembers)) &&
            (!filterToUse.workEventType ||
                bobFilter.workEventTypesChecked.length === 0 ||
                bobFilter.workEventTypesChecked.includes(
                    event.absenceReasonType.id,
                )) &&
            (!filterToUse.absenceState ||
                bobFilter.absenceStatesChecked.length === 0 ||
                bobFilter.absenceStatesChecked.includes(
                    event.absenceStatus.id,
                )) &&
            (!filterToUse.workTime ||
                _filterWorkTimeState(event, bobFilter, null))
        )
    })
}

const filterBobState = (
    events,
    bobFilter,
    context,
    filterToUse = {
        searchString: false,
        rangeDate: false,
        checkupTypesChecked: false,
        amountRange: false,
        checkupEquipmentFamily: false,
        inventoryEquipmentFamily: false,
        equipment: false,
        commissioningDate: false,
        replacementDate: false,
        onlyActiveDetails: false,
        stock: false,
        userAttachedRole: false,
        managerSearch: false,
    },
    isRepair = false,
    groupMembers,
) => {
    if (!events || !bobFilter) return events

    return events.filter(event => {
        return (
            (!filterToUse.searchString ||
                filterSearchString(event, bobFilter, isRepair, context)) &&
            (!filterToUse.rangeDate || filterRangeDate(event, bobFilter)) &&
            (!filterToUse.checkupTypesChecked ||
                (event.eventType &&
                    (bobFilter.checkupTypesChecked.length === 0 ||
                        bobFilter.checkupTypesChecked.includes(
                            event.eventType.id,
                        )))) &&
            (!filterToUse.amountRange || filterAmountRange(event, bobFilter)) &&
            (!filterToUse.rangeHour || filterRangeHour(event, bobFilter)) &&
            (!filterToUse.checkupEquipmentFamily ||
                _filterCheckupEquipmentFamily(event, bobFilter)) &&
            (!filterToUse.inventoryEquipmentFamily ||
                _filterInventoryEquipmentFamily(event, bobFilter)) &&
            (!filterToUse.equipment || filterEquipment(event, bobFilter)) &&
            (!filterToUse.commissioningDate ||
                filterCommissioningDate(event, bobFilter)) &&
            (!filterToUse.replacementDate ||
                filterReplacementDate(event, bobFilter)) &&
            (!filterToUse.onlyActiveDetails ||
                (event.detail &&
                    !event.detail.archived &&
                    event.delimitedDate)) &&
            (!filterToUse.stock || filterStock(events, event, bobFilter)) &&
            (!filterToUse.userAttachedRole ||
                _filterUserAttachedRole(event, bobFilter, groupMembers)) &&
            (!filterToUse.managerSearch ||
                _filterManagerAttached(event, bobFilter, groupMembers)) &&
            (!filterToUse.calendarEquipmentFamily ||
                _filterCalendarEquipmentFamily(event, bobFilter)) &&
            (!filterToUse.workEventTypesChecked ||
                (event.eventType &&
                    (bobFilter.workEventTypesChecked.length === 0 ||
                        bobFilter.workEventTypesChecked.includes(
                            event.eventType.id,
                        )))) &&
            (!filterToUse.absenceStatesChecked ||
                (event.eventType &&
                    (bobFilter.absenceStatesChecked.length === 0 ||
                        bobFilter.absenceStatesChecked.includes(
                            event.eventType.id,
                        )))) &&
            (!filterToUse.workTimeOptionsChecked ||
                (event.eventType &&
                    (bobFilter.workTimeOptionsChecked.length === 0 ||
                        bobFilter.workTimeOptionsChecked.includes(
                            event.eventType.id,
                        ))))
        )
    })
}

const filterCheckup = (checkups, bobFilter, events, context) => {
    if (!checkups || !bobFilter) return checkups
    checkups = checkups.filter(
        checkup =>
            checkup &&
            ((checkup.checkupType &&
                checkup.checkupType.translation?.[context]
                    .toLowerCase()
                    .includes(bobFilter.searchString.toLowerCase())) ||
                (checkup.userCheckup &&
                    checkup.userCheckup.name
                        .toLowerCase()
                        .includes(bobFilter.searchString.toLowerCase()))),
    )

    if (
        events &&
        Array.isArray(events) &&
        bobFilter &&
        bobFilter.rangeDate &&
        (bobFilter.rangeDate.start || bobFilter.rangeDate.end)
    ) {
        checkups = checkups.filter(checkup => {
            return events.some(
                event =>
                    event.checkup &&
                    ((event.checkup.checkupType &&
                        event.checkup.checkupType.id ===
                            checkup.checkupType.id) ||
                        (event.checkup.userCheckup &&
                            event.checkup.userCheckup.id ===
                                checkup.userCheckup.id)),
            )
        })
    }

    return checkups
}

export const filterActions = {
    filterBobState,
    resetFilterBob,
    updateFilterBob,
    filterAction,
    filterCheckup,
    filterWorkEvents,
    filterAbsenceEvents,
    filterUserRole,
}
