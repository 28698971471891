import React from 'react'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/TopInventoryCss'
import InputLabel from '../../../../common/UsefullComponents/InputLabel'
import Typography from '@material-ui/core/Typography'
import dictionary from '../Dictionary/InventoryListDico'
import TextField from '@material-ui/core/TextField'
import DisplayerImgModal from '../../../DisplayerImgModal'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { typesActions } from '../../../../../actions/types.actions'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import LocaleEn from 'date-fns/locale/en-US'
import InputAdornment from '@material-ui/core/InputAdornment'
import RemoveIcon from '@material-ui/icons/Remove'
import { getImageToDisplay } from '../../../../../actions/bob.actions'
import {
    addFileToBobEvent,
    deleteFileFromBobEvent,
    postBOBEvents,
    putBOBEvents,
    setBOBStateEvent,
    updateStateEvent,
    setNewEvent,
    unSetNewEvent,
} from '../../../../../actions/bob.actions'
import BoatOnModal from '../../../../common/BoatOnModal'
import ModalAmountItCheck from '../../../../common/UsefullComponents/ModalAmountItCheck'
import BoatOnDateSelector from '../../../../common/UsefullComponents/BoatOnDateSelector'
import { activityActions } from '../../../../../actions/activity.actions'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import outDatedReplaceImg from '../../../../../images/outDatedReplace.svg'
import soonOutDatedStatusImg from '../../../../../images/soonOutDatedStatus.svg'

class InventoryModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            checkModalOpen: null,
            isConfirmed: false,
            events: [],
            event: props.event || null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            save: false,
            openDoc: false,
            deletedFiles: { event: [] },
            show: false,
            error: false,
            modalOpen: null,
            isEngineInSelection: false,
            tabIndex: 0,
            loadingImage: false,
            tax: 20,
        }
        this.onTagsChange = this.onTagsChange.bind(this)
        this.save = this.save.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.addNewEventDetail = this.addNewEventDetail.bind(this)
        this.isConfirmedToggler = this.isConfirmedToggler.bind(this)
        this.handleCloseCheckModal = this.handleCloseCheckModal.bind(this)
        this.handleUpdateEngineSelection = this.handleUpdateEngineSelection.bind(
            this,
        )
        this.onChangeAmount = this.onChangeAmount.bind(this)
        this.onChangeTax = this.onChangeTax.bind(this)
    }

    // CheckModal
    isConfirmedToggler() {
        this.setState({ isConfirmed: true })
    }

    handleCloseCheckModal() {
        this.setState({ checkModalOpen: null })
    }

    handleOpenModal(modalOpen) {
        this.setState({ modalOpen: modalOpen })
    }

    handleCloseModal() {
        this.setState({ modalOpen: null })
    }

    handleUpdateEngineSelection(value) {
        this.setState({ isEngineInSelection: value })
    }

    addNewEventDetail(callback) {
        this.setState({ modalOpen: null }, () => {
            callback()
        })
    }

    componentDidMount() {
        if (!this.state.event) setBOBStateEvent(this)

        if (this.state?.event?.id) {
            // Lancement du chargement de l'activité lorsque la modale est montée
            this.props.dispatch(
                activityActions.getBobEventActivity(this.state?.event?.id),
            )
        }

        if (this.state.event?.transaction) {
            const transaction = this.state.event.transaction

            if (transaction?.amountIt && transaction?.amountEt) {
                const amountEt = parseFloat(transaction.amountEt)
                const amountIt = parseFloat(transaction.amountIt)
                const tax = (amountIt / amountEt - 1) * 100
                const roundedTax = Math.round(tax * 100) / 100

                this.setState({ tax: roundedTax })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { loadingEvents, boat, newEvent, type } = this.props
        if (loadingEvents === 0 && newEvent && type === `INVENTORY_PART`) {
            const part = {
                equipment:
                    newEvent && newEvent.detail && newEvent.detail.equipment
                        ? newEvent.detail.equipment
                        : null,
            }
            if (part && part.equipment) {
                this.onTagsChange({ part })
                this.props.dispatch(unSetNewEvent())
            }
        }
        if (loadingEvents === 0 && prevProps.loadingEvents > 0 && boat) {
            this.props.dispatch(
                typesActions.requestUserEquipmentFamilies(boat.id || null),
            )
            this.props.dispatch(
                typesActions.requestUserEquipments(boat.id || null),
            )
            this.props.dispatch(typesActions.requestUserParts(boat.id || null))
        }
    }

    onTagsChange({ equipment, part }) {
        const { event } = this.state
        const detail = JSON.parse(JSON.stringify(event.detail))
        if (equipment) {
            detail.equipment = equipment
        }

        if (part) {
            detail.part = part
        }

        updateStateEvent(this, {
            detail: detail,
        })
    }

    _findNewEventDetail(result) {
        this.props.dispatch(setNewEvent(result))
    }

    _setTitle(detail) {
        if (detail) {
            if (detail.part) {
                return detail.part.partType
                    ? this.displayTextApi(detail.part.partType.translation)
                    : detail.part.userPart.name
            }
            if (detail.equipment) {
                return detail.equipment.equipmentType
                    ? this.displayTextApi(
                          detail.equipment.equipmentType.translation,
                      )
                    : detail.equipment.userEquipment.name
            }
        }
        return null
    }

    async _sendDate() {
        const { boat } = this.props
        const { event, deletedFiles, fileNoUrl } = this.state
        const { description, transaction, eventType, delimitedDate } = event
        let detail = JSON.parse(JSON.stringify(event.detail))
        if (detail.equipment && !detail.equipment.equipmentFamily) {
            detail.equipment = {
                ...detail.equipment,
                equipmentFamily: this.props.inventory?.checkup?.equipmentFamily,
            }
        }
        if (detail?.optionnalDetail?.capacity)
            detail.optionnalDetail.capacity = `${detail.optionnalDetail.capacity}`
        let result = null

        if (event.id) {
            result = await this.props.dispatch(
                putBOBEvents(event.id, this._setTitle(detail), {
                    newFiles: fileNoUrl.event,
                    files: event.files,
                    delimitedDate: delimitedDate,
                    transaction: transaction,
                    description: description,
                    eventTypeId: eventType.id,
                    deletedFiles: deletedFiles.event,
                    detail: detail,
                }),
            )
        } else {
            result = await this.props.dispatch(
                postBOBEvents(boat.id, 7, this._setTitle(detail), {
                    files: fileNoUrl.event,
                    duplicateFiles: event.files,
                    delimitedDate: delimitedDate,
                    transaction: transaction,
                    description: description,
                    detail: detail,
                }),
            )
        }
        return result
    }

    async save() {
        const { newElement } = this.props
        const { event, isConfirmed, isEngineInSelection } = this.state
        // show modal if event.transaction.amountIt is 0; not show if null or <0
        // continue saving data if confirmed on modal

        if (
            event &&
            isEngineInSelection === false &&
            ((event.delimitedDate && event.delimitedDate.startDate) ||
                newElement) &&
            event.detail &&
            ((event.detail.equipment &&
                (event.detail.equipment.equipmentType ||
                    event.detail.equipment.userEquipment)) ||
                (event.detail.part &&
                    (event.detail.part.partType ||
                        event.detail.part.userPart))) &&
            ((event?.transaction === null ||
            event?.transaction?.amountIt === undefined
                ? true
                : parseFloat(event.transaction.amountIt) !== 0) ||
                isConfirmed)
        ) {
            if (this.props.addNewEventDetail) {
                this.props.addNewEventDetail(async () => {
                    const result = await this._sendDate()
                    this._findNewEventDetail(result)
                })
            } else if (this.props.handleCloseAdd) {
                this._sendDate()
                this.props.handleCloseAdd()
            } else {
                this._sendDate()
                this.props.handleClose()
            }
        } else if (
            event &&
            isEngineInSelection === false &&
            ((event.delimitedDate && event.delimitedDate.startDate) ||
                newElement) &&
            event.detail &&
            ((event.detail.equipment &&
                (event.detail.equipment.equipmentType ||
                    event.detail.equipment.userEquipment)) ||
                (event.detail.part &&
                    (event.detail.part.partType ||
                        event.detail.part.userPart))) &&
            (parseFloat(event.transaction.amountIt) === 0 || !isConfirmed)
        ) {
            // show ModalAmountItCheck
            this.setState({ checkModalOpen: 'inventoryCheck' })
        } else {
            this.setState({ error: true })
        }
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    onChangeAmount(type, value) {
        const { tax } = this.state

        value = value.toString().replace(',', '.')
        const transaction =
            type === 'amountIt'
                ? {
                      amountIt: value,
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value,
                  }

        updateStateEvent(this, {
            transaction,
        })
    }

    onChangeTax(value) {
        const { event } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.onChangeAmount(
                    'amountEt',
                    event?.transaction?.amountEt ?? '',
                ),
        )
    }

    _renderAmount() {
        const { classes } = this.props
        const { event, tax } = this.state

        return (
            <div className={classes.amountContainer}>
                <BoatOnNumberField
                    isDecimal
                    id="amountEt"
                    variant="outlined"
                    label={this.displayText('amountEt')}
                    margin="dense"
                    className={classes.amountField}
                    value={event?.transaction?.amountEt}
                    onChange={e =>
                        this.onChangeAmount('amountEt', e.target.value)
                    }
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <RemoveIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    label={this.displayText('tax')}
                    margin="dense"
                    className={classes.taxField}
                    value={tax}
                    min={0}
                    max={100}
                    onChange={e => this.onChangeTax(e.target.value)}
                    InputProps={{
                        classes: {
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    id="amountIt"
                    variant="outlined"
                    label={this.displayText('amountIt')}
                    margin="dense"
                    className={classes.amountField}
                    value={event?.transaction?.amountIt}
                    onChange={e =>
                        this.onChangeAmount('amountIt', e.target.value)
                    }
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <RemoveIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    _body() {
        const {
            classes,
            type,
            onlyDetails,
            newElement,
            editionInfos,
        } = this.props
        const { event } = this.state
        const HtmlTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: '#3040a0',
                color: 'white',
                maxWidth: 300,
                border: '1px solid #dadde9',
                fontSize: '15px',
            },
        }))(Tooltip)

        let endDateWarranty = ''

        if (event?.detail?.warrantyDate?.endDate) {
            const endDate = new Date(event.detail.warrantyDate.endDate)
            const today = new Date()

            if (today > new Date(endDate).setMonth(endDate.getMonth() - 3))
                endDateWarranty = 'near'
            if (today > endDate) endDateWarranty = 'passed'
        }

        return (
            <>
                <Typography variant={`body1`}>
                    {this.displayText('completingInventory')}
                </Typography>
                {!onlyDetails && (
                    <InputLabel
                        event={this.state.event}
                        onTagsChange={this.onTagsChange}
                        error={this.state.error}
                        modalOpen={this.state.modalOpen}
                        handleOpenModal={this.handleOpenModal}
                        arrayFor={type}
                        inventories={this.props.inventories}
                        isEngineInSelection={this.state.isEngineInSelection}
                        handleUpdateEngineSelection={
                            this.handleUpdateEngineSelection
                        }
                    />
                )}

                {!editionInfos && (
                    <>
                        {!newElement && (
                            <div className={classes.espaces}>
                                <div className={classes.dateType}>
                                    <Typography className={classes.textAddDate}>
                                        {this.displayText('dateModal')}
                                    </Typography>

                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color="inherit">
                                                    {this.displayText(
                                                        'indicateDate',
                                                    )}
                                                </Typography>
                                            </>
                                        }
                                    >
                                        <InfoOutlinedIcon
                                            className={classes.outlinedIcon}
                                        />
                                    </HtmlTooltip>
                                </div>
                                <div className={classes.twoTextFieldDate}>
                                    <MuiPickersUtilsProvider
                                        utils={dateUtils}
                                        locale={
                                            this.getLanguage() === 'FR'
                                                ? LocaleFr
                                                : LocaleEn
                                        }
                                    >
                                        <BoatOnDateSelector
                                            id={'dateCommissioning'}
                                            required
                                            isDateCommissioning
                                            label={this.displayText(
                                                'dateCommissioning',
                                            )}
                                            value={
                                                event &&
                                                event.delimitedDate &&
                                                event.delimitedDate.startDate
                                                    ? new Date(
                                                          event.delimitedDate.startDate,
                                                      )
                                                    : null
                                            }
                                            onChange={value => {
                                                updateStateEvent(this, {
                                                    delimitedDate: {
                                                        startDate: value,
                                                    },
                                                })
                                            }}
                                            error={
                                                this.state.error &&
                                                !event?.delimitedDate?.startDate
                                            }
                                            helperText={
                                                this.state.error &&
                                                !event?.delimitedDate?.startDate
                                                    ? this.displayText(
                                                          'errorDate',
                                                      )
                                                    : ''
                                            }
                                        />
                                    </MuiPickersUtilsProvider>

                                    <MuiPickersUtilsProvider
                                        utils={dateUtils}
                                        locale={
                                            this.getLanguage() === 'FR'
                                                ? LocaleFr
                                                : LocaleEn
                                        }
                                    >
                                        <BoatOnDateSelector
                                            id={'dateReplacement'}
                                            isDateReplacement
                                            label={this.displayText(
                                                'dateReplacement',
                                            )}
                                            value={
                                                (event &&
                                                    event.delimitedDate &&
                                                    event.delimitedDate
                                                        .endDate) ||
                                                event?.detail?.archived
                                                    ? new Date(
                                                          event?.delimitedDate
                                                              ?.endDate ||
                                                              event?.detail
                                                                  ?.archived,
                                                      )
                                                    : null
                                            }
                                            minDate={
                                                event &&
                                                event.delimitedDate &&
                                                event.delimitedDate.startDate
                                                    ? new Date(
                                                          event.delimitedDate.startDate,
                                                      )
                                                    : undefined
                                            }
                                            onChange={value => {
                                                updateStateEvent(this, {
                                                    delimitedDate: {
                                                        endDate: value,
                                                    },
                                                })
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.flexBtn}></div>
                                <div className={classes.twoTextFieldDate}>
                                    <BoatOnNumberField
                                        isDecimal
                                        id="actuelNbrHours"
                                        variant="outlined"
                                        label={this.displayText(
                                            'actuelNbrHours',
                                        )}
                                        margin="dense"
                                        className={classes.textField}
                                        value={
                                            event &&
                                            event.detail &&
                                            event.detail &&
                                            event.detail.hourActual !== null &&
                                            event.detail.hourActual !==
                                                undefined
                                                ? this.state.event.detail
                                                      .hourActual
                                                : ``
                                        }
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                detail: {
                                                    hourActual:
                                                        e.target.value !== ''
                                                            ? parseInt(
                                                                  e.target
                                                                      .value,
                                                              )
                                                            : null,
                                                },
                                            })
                                        }}
                                    />
                                    <BoatOnNumberField
                                        isDecimal
                                        id="replaceHours"
                                        variant="outlined"
                                        label={this.displayText('replaceHours')}
                                        margin="dense"
                                        className={classes.textField}
                                        value={
                                            event &&
                                            event.detail &&
                                            event.detail &&
                                            event.detail.hourReplacement
                                                ? event.detail.hourReplacement
                                                : ``
                                        }
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                detail: {
                                                    hourReplacement:
                                                        e.target.value !== ''
                                                            ? parseInt(
                                                                  e.target
                                                                      .value,
                                                              )
                                                            : null,
                                                },
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={classes.espaces}>
                            <div className={classes.dateType}>
                                <Typography className={classes.textAddDate}>
                                    {this.displayText('details')}
                                </Typography>
                            </div>
                            <div className={classes.twoTextField}>
                                <TextField
                                    variant="outlined"
                                    label={this.displayText('brand')}
                                    margin="dense"
                                    className={classes.textField}
                                    value={
                                        event &&
                                        event.detail &&
                                        event.detail.brand
                                            ? event.detail.brand
                                            : ''
                                    }
                                    onChange={e => {
                                        updateStateEvent(this, {
                                            detail: {
                                                brand: e.target.value,
                                            },
                                        })
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    label={this.displayText('model')}
                                    margin="dense"
                                    className={classes.textField}
                                    value={
                                        event &&
                                        event.detail &&
                                        event.detail.model
                                            ? event.detail.model
                                            : ''
                                    }
                                    onChange={e => {
                                        updateStateEvent(this, {
                                            detail: {
                                                model: e.target.value,
                                            },
                                        })
                                    }}
                                />
                            </div>
                            <div className={classes.twoTextField}>
                                <TextField
                                    variant="outlined"
                                    label={
                                        <div>
                                            {this.displayText('serialNumber')}{' '}
                                        </div>
                                    }
                                    margin="dense"
                                    className={classes.textField}
                                    value={
                                        event &&
                                        event.detail &&
                                        event.detail.serialNumber
                                            ? event.detail.serialNumber
                                            : ''
                                    }
                                    onChange={e => {
                                        updateStateEvent(this, {
                                            detail: {
                                                serialNumber: e.target.value,
                                            },
                                        })
                                    }}
                                />
                                <Tooltip
                                    title={this.displayText('createReference')}
                                    classes={{
                                        tooltip: classes.testTool,
                                    }}
                                >
                                    <TextField
                                        id="supplier"
                                        variant="outlined"
                                        label={this.displayText(
                                            'internalReference',
                                        )}
                                        margin="dense"
                                        className={classes.textField}
                                        value={
                                            event &&
                                            event.detail &&
                                            event.detail.internalReference
                                                ? event.detail.internalReference
                                                : ''
                                        }
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                detail: {
                                                    internalReference:
                                                        e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div
                                className={`${classes.twoTextField} ${classes.verticalMobile}`}
                            >
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    label={this.displayText('supplier')}
                                    margin="dense"
                                    className={classes.textField}
                                    value={
                                        event &&
                                        event.detail &&
                                        event.detail.provider
                                            ? event.detail.provider
                                            : ''
                                    }
                                    onChange={e => {
                                        updateStateEvent(this, {
                                            detail: {
                                                provider: e.target.value,
                                            },
                                        })
                                    }}
                                />
                                {this._renderAmount()}
                            </div>
                            <div
                                className={
                                    this.state?.event?.detail?.equipment
                                        ?.equipmentType
                                        ?.optionnalDetailActivated?.power
                                        ? classes.twoTextField
                                        : {}
                                }
                            >
                                {event?.detail?.equipment?.equipmentType
                                    ?.optionnalDetailActivated?.power ? (
                                    <TextField
                                        id="power"
                                        variant="outlined"
                                        label={this.displayText('power')}
                                        margin="dense"
                                        className={classes.textField}
                                        value={
                                            this.state.event?.detail
                                                ?.optionnalDetail?.power || ''
                                        }
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                optionnalDetail: {
                                                    power: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <BoatOnDateSelector
                                    id={'datePurchase'}
                                    label={this.displayText('datePurchase')}
                                    isDatePurchase
                                    onChange={value => {
                                        updateStateEvent(this, {
                                            detail: {
                                                buyDate: value,
                                            },
                                        })
                                    }}
                                    value={
                                        event &&
                                        event.detail &&
                                        event.detail.buyDate
                                            ? new Date(event.detail.buyDate)
                                            : null
                                    }
                                />
                            </div>
                            <div className={classes.twoTextField}>
                                <BoatOnDateSelector
                                    variant="outlined"
                                    label={this.displayText('warrantyStart')}
                                    className={classes.textField}
                                    onChange={value => {
                                        updateStateEvent(this, {
                                            detail: {
                                                warrantyDate: {
                                                    ...event?.detail
                                                        .warrantyDate,
                                                    startDate: new Date(value),
                                                },
                                            },
                                        })
                                    }}
                                    value={
                                        event?.detail?.warrantyDate?.startDate
                                            ? new Date(
                                                  event.detail.warrantyDate.startDate,
                                              )
                                            : null
                                    }
                                />
                                <BoatOnDateSelector
                                    variant="outlined"
                                    label={this.displayText('warrantyEnd')}
                                    inputProps={{
                                        className:
                                            endDateWarranty === 'near'
                                                ? classes.nearDate
                                                : endDateWarranty === 'passed'
                                                ? classes.passedDate
                                                : null,
                                        InputProps:
                                            endDateWarranty === 'near'
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="end">
                                                              <img
                                                                  src={
                                                                      soonOutDatedStatusImg
                                                                  }
                                                                  alt={this.displayText(
                                                                      'nearDateReplace',
                                                                  )}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : endDateWarranty === 'passed'
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="end">
                                                              <img
                                                                  src={
                                                                      outDatedReplaceImg
                                                                  }
                                                                  alt={this.displayText(
                                                                      'outDatedReplace',
                                                                  )}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : undefined,
                                    }}
                                    onChange={value => {
                                        updateStateEvent(this, {
                                            detail: {
                                                warrantyDate: {
                                                    ...event?.detail
                                                        ?.warrantyDate,
                                                    endDate: value,
                                                },
                                            },
                                        })
                                    }}
                                    value={
                                        event?.detail?.warrantyDate?.endDate
                                            ? new Date(
                                                  event.detail.warrantyDate.endDate,
                                              )
                                            : null
                                    }
                                />
                            </div>
                            <div className={classes.twoTextField}>
                                {event?.detail?.equipment?.equipmentType
                                    ?.optionnalDetailActivated?.capacity && (
                                    <BoatOnNumberField
                                        isDecimal
                                        label={this.displayText('capacity')}
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {
                                                        event?.detail?.equipment
                                                            ?.equipmentType
                                                            ?.optionnalDetailActivated
                                                            ?.unit
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                detail: {
                                                    optionnalDetail: {
                                                        ...event?.detail
                                                            ?.optionnalDetail,
                                                        capacity:
                                                            e.target.value,
                                                    },
                                                },
                                            })
                                        }}
                                        value={
                                            event?.detail?.optionnalDetail
                                                ?.capacity
                                        }
                                    />
                                )}
                                {event?.detail?.equipment?.equipmentType
                                    ?.optionnalDetailActivated
                                    ?.fillingLevel && (
                                    <BoatOnNumberField
                                        label={this.displayText('level')}
                                        variant="outlined"
                                        margin="dense"
                                        max={100}
                                        min={0}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={e => {
                                            updateStateEvent(this, {
                                                detail: {
                                                    optionnalDetail: {
                                                        ...event.detail
                                                            .optionnalDetail,
                                                        fillingLevel: +e.target
                                                            .value,
                                                    },
                                                },
                                            })
                                        }}
                                        value={
                                            event?.detail?.optionnalDetail
                                                ?.fillingLevel
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.espaces}>
                            <div className={classes.dateType}>
                                <Typography className={classes.textAddDate}>
                                    {this.displayText('docPho')}
                                </Typography>
                            </div>
                            <div
                                className={classes.addDoc}
                                onClick={this.clickDocument}
                            >
                                <AddAPhotoIcon className={classes.doc} />
                            </div>
                        </div>
                        <input
                            onChange={this.addDocument}
                            type="file"
                            id="file"
                            ref="fileUploader"
                            style={{ display: 'none' }}
                            multiple
                        />
                        <DisplayerImgModal
                            file={getImageToDisplay(
                                event,
                                this.state.displayNewFiles.event,
                            )}
                            deleteDocumentNoUp={this.deleteDocumentNoUp}
                            loadingImage={this.state.loadingImage}
                        />
                        <div className={classes.espaces}>
                            <div className={classes.dateType}>
                                <Typography className={classes.textAddDate}>
                                    {this.displayText('notes')}
                                </Typography>
                            </div>
                            <TextField
                                id="details"
                                variant="outlined"
                                label={this.displayText('importantInformation')}
                                margin="dense"
                                multiline
                                minRows={2}
                                maxRows={5}
                                className={classes.textField}
                                value={(event && event.description) || ''}
                                onChange={e => {
                                    updateStateEvent(this, {
                                        description: e.target.value,
                                    })
                                }}
                            />
                        </div>
                    </>
                )}
            </>
        )
    }

    render() {
        const { title, type, event, activity } = this.props
        const { checkModalOpen } = this.state
        const SecondInventoryModal = connect(mapStateToProps)(
            withStyles(styles)(InventoryModal),
        )

        return (
            <>
                {type === `INVENTORY_PART` && (
                    <BoatOnModal
                        openCondition={this.state.modalOpen}
                        handleClose={this.handleCloseModal}
                        modalCores={{
                            equipmentAdd: (
                                <SecondInventoryModal
                                    addNewEventDetail={this.addNewEventDetail}
                                    type={`INVENTORY_EQUIPMENT`}
                                />
                            ),
                        }}
                        maxWidth={{
                            equipmentAdd: `md`,
                        }}
                        titles={{
                            equipmentAdd: `Ajout d'un nouvel équipement`,
                        }}
                    />
                )}

                {this._renderTitle(title)}
                {this._renderBody({
                    bodies: [
                        this._body(),
                        event?.id
                            ? this._renderActivity(activity, event)
                            : undefined,
                    ],
                })}
                {this._renderActions([
                    {
                        label: this.displayText('validate'),
                        action: this.save,
                        minWidth: `200px`,
                    },
                ])}

                <BoatOnModal
                    openCondition={checkModalOpen}
                    handleClose={this.handleCloseCheckModal}
                    maxWidth={{ inventoryCheck: `sm` }}
                    modalCores={{
                        inventoryCheck: (
                            <ModalAmountItCheck
                                isConfirmedToggler={this.isConfirmedToggler}
                                save={this.save}
                                handleCloseCheckModal={
                                    this.handleCloseCheckModal
                                }
                            />
                        ),
                    }}
                    titles={{
                        inventoryCheck: `Confirmer`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.boat.boats,
        boat: state.bob.boat,
        events: state.bob.events,
        loadingEvents: state.bob.loading,
        subscriptions: state.authentication.subscriptions,
        newEvent: state.bob.newEvent,
        inventories: state.bob.inventories,
        activity: state.activity.activity,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InventoryModal))
