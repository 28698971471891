import { groupConstants } from '../constants'

const initialState = {
    groupsMembers: null,
    loading: 0,
    currentGroupId: null,
    roles: null,
    fleetInfos: null,
    linkedGroups: null,
    additionalUserInfo: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case groupConstants.GET_GROUPS:
            return {
                ...prevState,
                groupsMembers: null,
                loading: prevState.loading + 1,
            }
        case groupConstants.GET_GROUPS_SUCCESS:
            if (!action.groups)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                groupsMembers: action.groups || [],
                currentGroupId: action.groupId || null,
                loading: prevState.loading - 1,
            }
        case groupConstants.GET_GROUPS_FAILURE:
            return {
                ...prevState,
                groupsMembers: null,
                loading: prevState.loading - 1,
            }
        case groupConstants.GET_ROLES:
            return {
                ...prevState,
                roles: null,
                loading: prevState.loading + 1,
            }
        case groupConstants.GET_ROLES_SUCCESS:
            if (!action.roles)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                roles: action.roles || [],
                loading: prevState.loading - 1,
            }
        case groupConstants.GET_ROLES_FAILURE:
            return {
                ...prevState,
                roles: null,
                loading: prevState.loading - 1,
            }
        case groupConstants.CHANGE_NAME:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case groupConstants.CHANGE_NAME_SUCCESS:
            if (!action.groups)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                groupsMembers: action.groups,
                loading: prevState.loading - 1,
            }
        case groupConstants.CHANGE_NAME_FAILURE:
            return {
                ...prevState,
                roles: null,
                loading: prevState.loading - 1,
            }
        case groupConstants.CREATE_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case groupConstants.CREATE_GROUP_SUCCESS:
            if (!action.groups)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                currentGroupId: action.groupId,
                loading: prevState.loading - 1,
            }
        case groupConstants.CREATE_GROUP_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case groupConstants.START_CHANGE_GROUP:
            if (!action.groupId)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                loading: prevState.loading + 1,
                currentGroupId: action.groupId || null,
            }
        case groupConstants.END_CHANGE_GROUP:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case groupConstants.REMOVE_USER_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case groupConstants.REMOVE_USER_GROUP_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case groupConstants.REMOVE_USER_GROUP_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case groupConstants.ADD_USER_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case groupConstants.ADD_USER_GROUP_SUCCESS:
            if (!action.groups)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                groupsMembers: action.groups || [],
                currentGroupId: action.groupId || null,
                loading: prevState.loading - 1,
            }
        case groupConstants.ADD_USER_GROUP_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        case groupConstants.EDIT_USER_GROUP:
            return {
                ...prevState,
            }

        case groupConstants.EDIT_USER_GROUP_SUCCESS:
            return {
                ...prevState,
                groupsMembers: action.groups || [],
                roles: action.roles,
            }
        case groupConstants.EDIT_USER_GROUP_FAILURE:
            return {
                ...prevState,
            }
        case groupConstants.GET_FLEET_INFOS:
            return {
                ...prevState,
                fleetInfos: null,
                loading: prevState.loading + 1,
            }
        case groupConstants.GET_FLEET_INFOS_SUCCESS:
            if (!action.fleetInfos)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                fleetInfos: action.fleetInfos || null,
                loading: prevState.loading - 1,
            }
        case groupConstants.GET_FLEET_INFOS_FAILURE:
            return {
                ...prevState,
                fleetInfos: null,
                loading: prevState.loading - 1,
            }
        case groupConstants.EDIT_USER_LINK:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case groupConstants.EDIT_USER_LINK_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case groupConstants.EDIT_USER_LINK_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        case groupConstants.GET_LINKED_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }

        case groupConstants.GET_LINKED_GROUP_SUCCESS:
            let linkedGroups = []

            if (prevState.linkedGroups) {
                linkedGroups = prevState.linkedGroups.filter(
                    item => item.id !== action.linkedGroup.id,
                )
            }
            linkedGroups = [...linkedGroups, action.linkedGroup]

            return {
                ...prevState,
                linkedGroups: linkedGroups,
                loading: prevState.loading - 1,
            }

        case groupConstants.GET_LINKED_GROUP_FAILURE:
            return {
                ...prevState,
                linkedGroups: [],
                loading: prevState.loading - 1,
            }

        case groupConstants.REQUEST_ADDITIONAL_USER_INFO:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }

        case groupConstants.GET_ADDITIONAL_USER_INFO_SUCCESS:
            return {
                ...prevState,
                additionalUserInfo: action.additionalUserInfo,
            }

        case groupConstants.GET_ADDITIONAL_USER_INFO_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        case groupConstants.UPDATE_ADDITIONAL_USER_INFO_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        case groupConstants.UPDATE_ADDITIONAL_USER_INFO_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        default:
            return prevState
    }
}
