import React, { useState } from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/BobFilterCss'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { filterActions } from '../../../actions/filter.actions'
import { typesActions } from '../../../actions/types.actions'
import ButtonFilterPopOver from '../../ParkingSearchPage/ButtonFilterPopOver'
import {
    FormControlLabel,
    TextField,
    Switch,
    Divider,
    Checkbox,
    Typography,
    Popover,
} from '@material-ui/core'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'
import dictionary from './Dictionary/BobFilterDico'
import RangeInput from '../../common/UsefullComponents/RangeInput'
import { getCycle, getCycleHours } from '../../../actions/bob.actions'
import { getContextFromUrl } from '../../../languages/LocalizerUtils'
import BoatOnDateSelector from '../../common/UsefullComponents/BoatOnDateSelector'
import BoatOnBoatSelector from '../../common/UsefullComponents/BoatOnBoatSelector'
import ButtonFilterPopOverDateRange from '../../ParkingSearchPage/ButtonFilterPopOverDateRange'
import { Button } from '../../common/BoatOnButton'
import FilterSearch from './FilterSearch'
import BoatOnBlock from '../../common/Blocks/BoatOnBlock'
import BoatOnCrewSelector from '../../common/UsefullComponents/BoatOnCrewSelector'

/**
 * BobFilter component for filtering and searching events in the logbook.
 *
 * @class BobFilter
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {ActiveButtonConfig} activeButton - Configuration for active filter buttons.
 * @param {BobFilterState} bobFilter - Current filter state.
 * @param {Object} buttonLabelOverride - Custom labels for filter buttons.
 * @param {boolean} searchInput - Whether to show the search input.
 * @param {Function} selectAll - Function to select all items.
 * @param {Function} handleUnfoldAll - Function to handle unfolding all items.
 * @param {React.ReactNode} rightComponent - Component to render on the right side.
 * @param {boolean} noDivider - Whether to hide the divider.
 * @param {Function} dispatch - Redux dispatch function.
 *
 * @region ```
 * Objects
 * ```
 *
 * @typedef {Object} ActiveButtonConfig
 * @property {boolean} stockPart - Enable stock part filter.
 * @property {boolean} equipmentFamily - Enable equipment family filter.
 * @property {boolean} checkupType - Enable checkup type filter.
 * @property {boolean} rangeDate - Enable date range filter.
 *
 * @typedef {Object} BobFilterState
 * @property {Array} equipmentFamilyType - Selected equipment family types.
 * @property {Array} checkupTypesChecked - Selected checkup types.
 * @property {RangeDate} rangeDate - Selected date range.
 * @property {string} searchString - Current search string.
 *
 * @typedef {Object} RangeDate
 * @property {Date|null} start - Start date of the range.
 * @property {Date|null} end - End date of the range.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 01/10/2023 - 14:14
 * @author Samuel.C
 */

export class BobFilter extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        const { bobFilter } = this.props

        this.state = {
            filters: false,
            equipmentFamilyType: bobFilter.equipmentFamilyType,
            equipmentPartsChecked: bobFilter.equipmentPartsChecked,
            userEquipmentFamily: bobFilter.userEquipmentFamily,
            userEquipment: bobFilter.userEquipment,
            equipmentType: bobFilter.equipmentType,
            toRenew: bobFilter.toRenew,
            inStock: bobFilter.inStock,
            missing: bobFilter.missing,
            commissioningDate: {
                start: bobFilter.commissioningDate.start,
                end: bobFilter.commissioningDate.end,
            },
            replacementDate: {
                start: bobFilter.replacementDate.start,
                end: bobFilter.replacementDate.end,
            },
            rangeDate: {
                start: bobFilter.rangeDate.start,
                end: bobFilter.rangeDate.end,
            },
            checkupTypesChecked: bobFilter.checkupTypesChecked,
            rangeAmount: bobFilter.rangeAmount,
            rangeHour: bobFilter.rangeHour,
            userAttachedUserRole: bobFilter.userAttachedUserRole,
            userAttachedRoleType: bobFilter.userAttachedRoleType,
            userManagerSearch: bobFilter.userManagerSearch,
            unregisteredManagerSearch: bobFilter.unregisteredManagerSearch,
            workEventTypesChecked: bobFilter.workEventTypesChecked,
            absenceStatesChecked: bobFilter.absenceStatesChecked,
            workTimeOptionsChecked: bobFilter.workTimeOptionsChecked,
            openPopOver: false,
            searchString: '',
            searchStringTimer: undefined,
            selectedBoat: bobFilter.selectedBoat,
            selectedBoatByFleet: bobFilter.selectedBoatByFleet,
            selectedBoatsByFleet: bobFilter.selectedBoatsByFleet,
            selectedCrew: bobFilter.selectedCrew,
            orderStatusTypesChecked: bobFilter.orderStatusTypesChecked,
            orderOperatorChecked: bobFilter.orderOperatorChecked,
            navigationStatusChecked: bobFilter.navigationStatusChecked,
        }

        this.handleFilters = this.handleFilters.bind(this)
        this.saveFilter = this.saveFilter.bind(this)
        this.handleOpenPopOver = this.handleOpenPopOver.bind(this)
        this.handleClosePopOver = this.handleClosePopOver.bind(this)
        this.resetRangeDate = this.resetRangeDate.bind(this)
        this.resetStockPart = this.resetStockPart.bind(this)
        this.resetEquipmentFamily = this.resetEquipmentFamily.bind(this)
        this.resetEquipment = this.resetEquipment.bind(this)
        this.resetCheckupType = this.resetCheckupType.bind(this)
        this.resetCommissionDate = this.resetCommissionDate.bind(this)
        this.resetReplacementDate = this.resetReplacementDate.bind(this)
        this.resetRangeAmount = this.resetRangeAmount.bind(this)
        this.resetRangeHour = this.resetRangeHour.bind(this)
        this.resetUserAttachedRole = this.resetUserAttachedRole.bind(this)
        this.resetManagerSearch = this.resetManagerSearch.bind(this)
        this.handleResetFilters = this.handleResetFilters.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleTextInputChange = this.handleTextInputChange.bind(this)
        this.resetWorkType = this.resetWorkType.bind(this)
        this.resetAbsenceState = this.resetAbsenceState.bind(this)
        this.resetWorkTime = this.resetWorkTime.bind(this)
        this.resetSelectedBoat = this.resetSelectedBoat.bind(this)
        this.resetSelectedBoatByFleet = this.resetSelectedBoatByFleet.bind(this)
        this.resetSelectedBoatsByFleet = this.resetSelectedBoatsByFleet.bind(
            this,
        )
        this.resetSelectedOrderStatus = this.resetSelectedOrderStatus.bind(this)
        this.resetSelectedOrderOperator = this.resetSelectedOrderOperator.bind(
            this,
        )
        this.resetSelectedEquipmentParts = this.resetSelectedEquipmentParts.bind(
            this,
        )
        this.resetNavigationStatus = this.resetNavigationStatus.bind(this)
        this.onChangeBoat = this.onChangeBoat.bind(this)
        this.onChangeBoatByFloat = this.onChangeBoatByFloat.bind(this)
        this.onChangeBoatsByFloat = this.onChangeBoatsByFloat.bind(this)
        this.resetSelectedCrew = this.resetSelectedCrew.bind(this)
        this.onChangeCrew = this.onChangeCrew.bind(this)
    }

    getElementsToFilter() {
        const { fieldName } = this.props

        if (fieldName) return this.props?.[fieldName]
        return this.props.events
    }

    resetRangeDate() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                rangeDate: {
                    start: null,
                    end: null,
                },
            }),
        )
    }

    componentWillUnmount() {
        this.props.dispatch(filterActions.resetFilterBob())
    }

    resetSelectedBoat() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                selectedBoat: [],
            }),
        )
    }

    resetSelectedBoatByFleet() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                selectedBoatByFleet: [],
            }),
        )
    }

    resetSelectedBoatsByFleet() {
        this.setState({
            selectedBoatsByFleet: [],
        })
        this.props.dispatch(
            filterActions.updateFilterBob({
                selectedBoatsByFleet: [],
            }),
        )
    }

    resetSelectedCrew() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                selectedCrew: [],
            }),
        )
    }

    resetStockPart() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                toRenew: false,
                missing: false,
                inStock: false,
            }),
        )
    }

    resetEquipmentFamily() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                equipmentFamilyType: [],
                userEquipmentFamily: [],
            }),
        )
    }

    resetEquipment() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                equipmentType: [],
                userEquipment: [],
            }),
        )
    }

    resetCheckupType() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                checkupTypesChecked: [],
            }),
        )
    }

    resetWorkType() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                workEventTypesChecked: [],
            }),
        )
    }

    resetAbsenceState() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                absenceStatesChecked: [],
            }),
        )
    }

    resetWorkTime() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                workTimeOptionsChecked: [],
            }),
        )
    }

    resetCommissionDate() {
        const reset = {
            commissioningDate: {
                start: null,
                end: null,
            },
        }
        this.props.dispatch(filterActions.updateFilterBob(reset))
    }

    resetReplacementDate() {
        const reset = {
            replacementDate: {
                start: null,
                end: null,
            },
        }
        this.props.dispatch(filterActions.updateFilterBob(reset))
    }

    resetRangeHour() {
        const reset = {
            rangeHour: {
                min: null,
                max: null,
            },
        }
        this.props.dispatch(filterActions.updateFilterBob(reset))
    }

    resetRangeAmount() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                rangeAmount: { min: null, max: null },
            }),
        )
    }

    resetUserAttachedRole() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                userAttachedUserRole: [],
                userAttachedRoleType: [],
            }),
        )
    }

    resetManagerSearch() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                userManagerSearch: [],
                unregisteredManagerSearch: [],
            }),
        )
    }

    resetSelectedOrderStatus() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                orderStatusTypesChecked: [],
            }),
        )
    }

    resetSelectedOrderOperator() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                orderOperatorChecked: [],
            }),
        )
    }

    resetSelectedEquipmentParts() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                equipmentPartsChecked: [],
            }),
        )
    }

    resetNavigationStatus() {
        this.props.dispatch(
            filterActions.updateFilterBob({
                navigationStatusChecked: [],
            }),
        )
    }

    handleFilters() {
        this.setState({ filters: !this.state.filters })
    }

    componentDidUpdate(prevProps, prevState) {
        const { bobFilter } = this.props
        if (JSON.stringify(bobFilter) !== JSON.stringify(prevProps.bobFilter)) {
            this.setState({
                equipmentFamilyType: bobFilter.equipmentFamilyType,
                equipmentPartsChecked: bobFilter.equipmentPartsChecked,
                userEquipmentFamily: bobFilter.userEquipmentFamily,
                userEquipment: bobFilter.userEquipment,
                equipmentType: bobFilter.equipmentType,
                toRenew: bobFilter.toRenew,
                inStock: bobFilter.inStock,
                missing: bobFilter.missing,
                commissioningDate: {
                    start: bobFilter.commissioningDate.start,
                    end: bobFilter.commissioningDate.end,
                },
                replacementDate: {
                    start: bobFilter.replacementDate.start,
                    end: bobFilter.replacementDate.end,
                },
                rangeDate: {
                    start: bobFilter.rangeDate.start,
                    end: bobFilter.rangeDate.end,
                },
                checkupTypesChecked: bobFilter.checkupTypesChecked,
                rangeAmount: bobFilter.rangeAmount,
                rangeHour: bobFilter.rangeHour,
                userAttachedUserRole: bobFilter.userAttachedUserRole,
                userAttachedRoleType: bobFilter.userAttachedRoleType,
                userManagerSearch: bobFilter.userManagerSearch,
                unregisteredManagerSearch: bobFilter.unregisteredManagerSearch,
                workEventTypesChecked: bobFilter.workEventTypesChecked,
                workTimeOptionsChecked: bobFilter.workTimeOptionsChecked,
                absenceStatesChecked: bobFilter.absenceStatesChecked,
                selectedBoatByFleet: bobFilter.selectedBoatByFleet,
                orderStatusTypesChecked: bobFilter.orderStatusTypesChecked,
                orderOperatorChecked: bobFilter.orderOperatorChecked,
            })
        }
    }

    saveFilter() {
        const {
            equipmentFamilyType,
            userEquipmentFamily,
            equipmentType,
            userEquipment,
            toRenew,
            inStock,
            missing,
            commissioningDate,
            replacementDate,
            rangeDate,
            checkupTypesChecked,
            rangeAmount,
            rangeHour,
            userAttachedUserRole,
            userAttachedRoleType,
            userManagerSearch,
            unregisteredManagerSearch,
            workEventTypesChecked,
            absenceStatesChecked,
            workTimeOptionsChecked,
            orderStatusTypesChecked,
            orderOperatorChecked,
            equipmentPartsChecked,
            navigationStatusChecked,
        } = this.state

        this.props.dispatch(
            filterActions.updateFilterBob({
                equipmentFamilyType,
                userEquipmentFamily,
                equipmentType,
                userEquipment,
                toRenew,
                inStock,
                missing,
                commissioningDate,
                replacementDate,
                rangeDate,
                checkupTypesChecked,
                rangeAmount,
                rangeHour,
                userAttachedUserRole,
                userAttachedRoleType,
                userManagerSearch,
                unregisteredManagerSearch,
                workEventTypesChecked,
                absenceStatesChecked,
                workTimeOptionsChecked,
                orderStatusTypesChecked,
                orderOperatorChecked,
                equipmentPartsChecked,
                navigationStatusChecked,
            }),
        )
    }

    handleOpenPopOver(e, child) {
        this.setState({
            openPopOver: true,
            anchorEl: e.currentTarget,
            childPopOver: child,
        })
    }

    handleClosePopOver() {
        this.saveFilter()
        this.setState({
            openPopOver: false,
            anchorEl: null,
            childPopOver: null,
        })
    }

    handleResetFilters() {
        this.props.dispatch(filterActions.resetFilterBob())
    }

    getHourLimit() {
        const { events, bobFilter } = this.props
        let max = -1

        if (events)
            events.forEach(event => {
                if (event.checkup) {
                    const cycle = getCycle({ event: event })
                    if (
                        !cycle ||
                        !cycle.cycleType ||
                        cycle.cycleType.id !== 1
                    ) {
                        max =
                            getCycleHours(event) ||
                            (cycle && cycle.cycleLength) ||
                            0
                    }
                }
            })

        return {
            min: 0,
            max: max !== -1 ? max : 0,
            value: [
                bobFilter.rangeHour.min
                    ? bobFilter.rangeHour.min.toString()
                    : '0',
                bobFilter.rangeHour.max
                    ? bobFilter.rangeHour.max.toString()
                    : max !== -1
                    ? max.toString()
                    : '0',
            ],
        }
    }

    getAmountLimit() {
        const { bobFilter } = this.props
        const elements = this.getElementsToFilter()
        const elementsWithTransaction = elements.filter(
            event => event.transaction,
        )
        let min = -1
        let max = -1

        if (elementsWithTransaction)
            elementsWithTransaction.forEach(event => {
                if (event.transaction.amountIt) {
                    if (event.transaction.amountIt > max || max === -1)
                        max = event.transaction.amountIt
                    if (event.transaction.amountIt < min || min === -1)
                        min = event.transaction.amountIt
                }
            })

        if (elementsWithTransaction && elementsWithTransaction.length === 1)
            min = 0

        return {
            min: min !== -1 ? min : 0,
            max: max !== -1 ? max : 0,
            value: [
                bobFilter.rangeAmount.min
                    ? bobFilter.rangeAmount.min.toString()
                    : min !== -1
                    ? min.toString()
                    : '0',
                bobFilter.rangeAmount.max
                    ? bobFilter.rangeAmount.max.toString()
                    : max !== -1
                    ? max.toString()
                    : '0',
            ],
        }
    }

    getActiveEquipment() {
        const { events, repair } = this.props
        const categories = []

        if (events && repair) {
            events.forEach(event => {
                if (
                    event.checkup &&
                    event.checkup.parts &&
                    Array.isArray(event.checkup.parts) &&
                    event.checkup.parts.length > 0
                )
                    event.checkup.parts.forEach(part => {
                        if (
                            !categories.find(
                                category =>
                                    category &&
                                    ((part?.equipment?.equipmentType &&
                                        category.equipmentType &&
                                        category.equipmentType.id ===
                                            part.equipment.equipmentType.id) ||
                                        (part?.equipment?.userEquipment &&
                                            category.userEquipment &&
                                            category.userEquipment.id ===
                                                part.equipment.userEquipment
                                                    .id)),
                            )
                        ) {
                            categories.push(part.equipment)
                        }
                    })
                else if (
                    event.checkup &&
                    event.checkup.equipments &&
                    Array.isArray(event.checkup.equipments) &&
                    event.checkup.equipments.length > 0
                )
                    event.checkup.equipments.forEach(equipment => {
                        if (
                            !categories.find(
                                category =>
                                    category &&
                                    ((equipment.equipmentType &&
                                        category.equipmentType &&
                                        category.equipmentType.id ===
                                            equipment.equipmentType.id) ||
                                        (equipment.userEquipment &&
                                            category.userEquipment &&
                                            category.userEquipment.id ===
                                                equipment.userEquipment.id)),
                            )
                        ) {
                            categories.push(equipment)
                        }
                    })
            })
        }
        return categories
    }

    _getCheckupCategories(events) {
        const categories = []

        events.forEach(event => {
            if (
                event.checkup &&
                event.checkup.parts &&
                Array.isArray(event.checkup.parts) &&
                event.checkup.parts.length > 0
            ) {
                event.checkup.parts.forEach(part => {
                    if (
                        !categories.find(
                            category =>
                                category &&
                                ((part?.equipment?.equipmentFamily?.type &&
                                    category.type &&
                                    category.type.id ===
                                        part.equipment.equipmentFamily.type
                                            .id) ||
                                    (part?.equipment?.equipmentFamily
                                        ?.userEquipmentFamily &&
                                        category.userEquipmentFamily &&
                                        category.userEquipmentFamily.id ===
                                            part.equipment.equipmentFamily
                                                .userEquipmentFamily.id)),
                        ) &&
                        part?.equipment?.equipmentFamily
                    ) {
                        categories.push(part.equipment.equipmentFamily)
                    }
                })
            } else if (
                event.checkup &&
                event.checkup.equipments &&
                Array.isArray(event.checkup.equipments) &&
                event.checkup.equipments.length > 0
            ) {
                event.checkup.equipments.forEach(equipment => {
                    if (
                        !categories.find(
                            category =>
                                category &&
                                ((equipment?.equipmentFamily?.type &&
                                    category.type &&
                                    category.type.id ===
                                        equipment.equipmentFamily.type.id) ||
                                    (equipment?.equipmentFamily
                                        ?.userEquipmentFamily &&
                                        category.userEquipmentFamily &&
                                        category.userEquipmentFamily.id ===
                                            equipment.equipmentFamily
                                                .userEquipmentFamily.id)),
                        )
                    ) {
                        categories.push(equipment.equipmentFamily)
                    }
                })
            }
        })
        return categories
    }

    _getInventoryCategories(events) {
        const categories = []

        events.forEach(event => {
            if (event.detail && event.detail.part) {
                const part = event.detail.part
                if (
                    !categories.find(
                        category =>
                            category &&
                            ((part?.equipment?.equipmentFamily?.type &&
                                category.type &&
                                category.type.id ===
                                    part.equipment.equipmentFamily.type.id) ||
                                (part?.equipment?.equipmentFamily
                                    ?.userEquipmentFamily &&
                                    category.userEquipmentFamily &&
                                    category.userEquipmentFamily.id ===
                                        part.equipment.equipmentFamily
                                            .userEquipmentFamily.id)),
                    ) &&
                    part?.equipment?.equipmentFamily
                ) {
                    categories.push(part.equipment.equipmentFamily)
                }
            } else if (event.detail && event.detail.equipment) {
                const equipment = event.detail.equipment
                if (
                    !categories.find(
                        category =>
                            category &&
                            ((equipment?.equipmentFamily?.type &&
                                category.type &&
                                category.type.id ===
                                    equipment.equipmentFamily.type.id) ||
                                (equipment?.equipmentFamily
                                    ?.userEquipmentFamily &&
                                    category.userEquipmentFamily &&
                                    category.userEquipmentFamily.id ===
                                        equipment.equipmentFamily
                                            .userEquipmentFamily.id)),
                    )
                ) {
                    categories.push(equipment.equipmentFamily)
                }
            }
        })
        return categories
    }

    getActiveCategories() {
        const { events, repair } = this.props
        let categories = []

        if (events && !repair) {
            categories = this._getInventoryCategories(events)
        } else if (events && repair) {
            categories = this._getCheckupCategories(events)
        }
        return categories
    }

    renderChildStock() {
        const { bobFilter } = this.props
        const { classes } = this.props

        return (
            <ButtonFilterPopOver
                body={
                    <div>
                        <div className={classes.filterParts}>
                            <div className={classes.textParts}>
                                <Typography>
                                    {this.displayText('parts')}
                                </Typography>
                            </div>
                            <CheckboxFilter
                                checked={bobFilter && bobFilter.inStock}
                                handleChange={value => {
                                    this.setState({
                                        inStock: value,
                                    })
                                }}
                            />
                        </div>

                        <div className={classes.filterParts}>
                            <div className={classes.textParts}>
                                <Typography>
                                    {this.displayText('missingParts')}
                                </Typography>
                            </div>
                            <CheckboxFilter
                                checked={bobFilter && bobFilter.missing}
                                handleChange={value => {
                                    this.setState({
                                        missing: value,
                                    })
                                }}
                            />
                        </div>

                        <div className={classes.filterParts}>
                            <div className={classes.textParts}>
                                <Typography>
                                    {this.displayText('renewedParts')}
                                </Typography>
                            </div>
                            <CheckboxFilter
                                checked={bobFilter && bobFilter.toRenew}
                                handleChange={value => {
                                    this.setState({
                                        toRenew: value,
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                save={this.saveFilter}
                reset={this.resetStockPart}
            />
        )
    }

    renderCheckupType() {
        const { eventTypesCheckup, bobFilter } = this.props
        return (
            <FilterSearch
                options={eventTypesCheckup}
                selected={[...bobFilter.checkupTypesChecked]}
                onChange={checkupTypesChecked => {
                    this.setState({
                        checkupTypesChecked: checkupTypesChecked,
                    })
                }}
            />
        )
    }

    renderTransactionType() {
        const { bobFilter, eventTypesTransaction, events } = this.props

        // Les options disponibles sont filtrées en fonction des événements
        // Si il n'y a que des dépenses, alors afficher uniquement les catégories des dépenses
        // Si il n'y a que des revenus, alors afficher uniquement les catégories des revenus
        // Sinon afficher les deux

        // On détermine les types des transactions
        let { showSpending, showEarning } = events.reduce(
            (t, e) => {
                if (e.eventType.earning) {
                    t.showEarning = true
                }
                if (e.eventType.spending) {
                    t.showSpending = true
                }
                return t
            },
            {
                showSpending: false,
                showEarning: false,
            },
        )

        if (!showSpending && !showEarning) {
            showSpending = true
            showEarning = true
        }

        const options = eventTypesTransaction.filter(
            e => (e.earning && showEarning) || (e.spending && showSpending),
        )

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.checkupTypesChecked]}
                onChange={checkupTypesChecked => {
                    this.setState({
                        checkupTypesChecked: checkupTypesChecked,
                    })
                }}
            />
        )
    }

    renderWorkEventType() {
        const { bobFilter, leaveTypes } = this.props

        const options = leaveTypes

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.workEventTypesChecked]}
                onChange={workEventTypesChecked => {
                    this.setState({
                        workEventTypesChecked: workEventTypesChecked,
                    })
                }}
            />
        )
    }

    // TODO
    // Remplacer les options en dur lorsque le back renverra les statuts
    renderAbsenceState() {
        const { bobFilter } = this.props

        const options = [
            {
                id: 1,
                translation: {
                    id: 1,
                    fr: 'En attente',
                    en: 'Pending',
                },
            },
            {
                id: 2,
                translation: {
                    id: 2,
                    fr: 'Validé',
                    en: 'Approved',
                },
            },
            {
                id: 3,
                translation: {
                    id: 3,
                    fr: 'Refusé',
                    en: 'Rejected',
                },
            },
        ]

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.absenceStatesChecked]}
                onChange={absenceStatesChecked => {
                    this.setState({
                        absenceStatesChecked: absenceStatesChecked,
                    })
                }}
            />
        )
    }

    // TODO
    // Remplacer les options en dur lorsque le back renverra les statuts
    renderWorkTime() {
        const { bobFilter } = this.props

        const options = [
            {
                id: 1,
                translation: {
                    id: 1,
                    fr: 'Temps de travail dépassé',
                    en: 'Work time exceeded',
                },
            },
            {
                id: 2,
                translation: {
                    id: 2,
                    fr: 'Temps de travail incomplet',
                    en: 'Work time incomplete',
                },
            },
        ]

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.workTimeOptionsChecked]}
                onChange={workTimeOptionsChecked => {
                    this.setState({
                        workTimeOptionsChecked: workTimeOptionsChecked,
                    })
                }}
            />
        )
    }

    onChangeBoat(_, value) {
        this.setState(
            {
                selectedBoat: value !== null ? value.id : null,
            },
            () => {
                this.handleClosePopOver()
                this.forceUpdate()
                this.props.dispatch(
                    filterActions.updateFilterBob({
                        selectedBoat:
                            value !== null ? [this.state.selectedBoat] : [],
                    }),
                )
            },
        )
    }

    onChangeBoatsByFloat(values) {
        this.setState(
            {
                selectedBoatsByFleet: values ? values.map(v => v.id) : [],
            },
            () => {
                this.props.dispatch(
                    filterActions.updateFilterBob({
                        selectedBoatsByFleet: values
                            ? values.map(v => v.id)
                            : [],
                    }),
                )
                this.forceUpdate()
            },
        )
    }

    onChangeBoatByFloat(value) {
        this.setState(
            {
                selectedBoatByFleet: value !== null ? value.id : null,
            },
            () => {
                this.handleClosePopOver()
                this.forceUpdate()
                this.props.dispatch(
                    filterActions.updateFilterBob({
                        selectedBoatByFleet:
                            value !== null
                                ? [this.state.selectedBoatByFleet]
                                : [],
                    }),
                )
            },
        )
    }

    renderBoatFilter() {
        const { classes, bobFilter } = this.props

        return (
            <ButtonFilterPopOverDateRange
                key={'selectedBoat'}
                classes={{ root: classes.paper }}
                save={this.saveFilter}
                reset={this.resetSelectedBoat}
                body={
                    <BoatOnBoatSelector
                        style={{ width: 200 }}
                        onChange={this.onChangeBoat}
                        value={bobFilter.selectedBoat}
                    />
                }
            />
        )
    }

    renderBoatsFilterByFloat() {
        const { classes, groupId } = this.props
        const { selectedBoatsByFleet } = this.state

        return (
            <ButtonFilterPopOverDateRange
                key={'selectedBoatsByFleet'}
                classes={{
                    root: `${classes.paper} ${classes.fullWidth}`,
                }}
                save={this.saveFilter}
                reset={this.resetSelectedBoatsByFleet}
                body={
                    <BoatOnBoatSelector
                        multiple
                        class={classes.boatsSelector}
                        groupId={groupId}
                        onChange={this.onChangeBoatsByFloat}
                        values={selectedBoatsByFleet}
                        required={false}
                        overrideLabel={this.displayText('boatsSelectorLabel')}
                    />
                }
            />
        )
    }

    renderBoatFilterByFloat() {
        const { classes, groupId } = this.props
        const { selectedBoatByFleet } = this.state

        return (
            <ButtonFilterPopOverDateRange
                key={'selectedBoatByFleet'}
                classes={{ root: classes.paper }}
                save={this.saveFilter}
                reset={this.resetSelectedBoatByFleet}
                body={
                    <BoatOnBoatSelector
                        groupId={groupId}
                        style={{ width: 200 }}
                        onChange={this.onChangeBoatByFloat}
                        value={
                            selectedBoatByFleet?.length > 0
                                ? selectedBoatByFleet[0]
                                : null
                        }
                    />
                }
            />
        )
    }

    onChangeCrew(_, value) {
        this.setState(
            {
                selectedCrew: value.map(elem => elem.id),
            },
            () => {
                this.handleClosePopOver()
                this.forceUpdate()
                this.props.dispatch(
                    filterActions.updateFilterBob({
                        selectedCrew: value.map(elem => elem.id),
                    }),
                )
            },
        )
    }

    renderCrewFilter() {
        const { classes, bobFilter } = this.props

        return (
            <ButtonFilterPopOverDateRange
                key={'selectedCrew'}
                classes={{ root: classes.paper }}
                save={this.saveFilter}
                reset={this.resetSelectedCrew}
                body={
                    <BoatOnCrewSelector
                        style={{ width: 300 }}
                        onChange={this.onChangeCrew}
                        value={bobFilter.selectedCrew}
                    />
                }
            />
        )
    }

    renderAmount() {
        const { bobFilter } = this.props

        return (
            <ButtonFilterPopOver
                title={''}
                body={
                    <RangeInput
                        onChange={data => {
                            this.setState({
                                rangeAmount: {
                                    min: data[0],
                                    max: data[1],
                                },
                            })
                        }}
                        unity="€"
                        {...this.getAmountLimit()}
                        label1={this.displayText('minAmount')}
                        label2={this.displayText('maxAmount')}
                    />
                }
                save={this.saveFilter}
                reset={this.resetRangeAmount}
            />
        )
    }

    renderChildEquipement() {
        const { bobFilter } = this.props
        const categories = this.getActiveEquipment()
        const keys = { secondary: 'userEquipment', primary: 'equipmentType' }
        const selected = {
            [keys.secondary]: [...bobFilter.userEquipment],
            [keys.primary]: [...bobFilter.equipmentType],
        }
        return (
            <FilterSearch
                options={categories}
                selected={selected}
                twoArrayToFill
                onChange={({ primary, secondary }) => {
                    this.setState({
                        equipmentType: primary,
                        userEquipment: secondary,
                    })
                }}
                keys={keys}
            />
        )
    }

    renderChildFamilyEquipement() {
        const { bobFilter } = this.props
        const categories = this.getActiveCategories()
        const keys = { secondary: 'userEquipmentFamily', primary: 'type' }
        const selected = {
            [keys.secondary]: [...bobFilter.userEquipmentFamily],
            [keys.primary]: [...bobFilter.equipmentFamilyType],
        }
        return (
            <FilterSearch
                options={categories}
                selected={selected}
                twoArrayToFill
                onChange={({ primary, secondary }) => {
                    this.setState({
                        equipmentFamilyType: primary,
                        userEquipmentFamily: secondary,
                    })
                }}
                keys={keys}
            />
        )
    }

    renderChildCommissioningDate() {
        const { bobFilter, classes } = this.props
        return (
            <ButtonFilterPopOverDateRange
                key={'dateRange'}
                classes={{ root: classes.paper }}
                save={this.saveFilter}
                reset={this.resetCommissionDate}
                body={
                    <BoatOnDateSelector
                        isDateRange
                        isCompact
                        onDateChange={(start, end) => {
                            this.setState({
                                commissioningDate: {
                                    start: start,
                                    end: end,
                                },
                            })
                        }}
                        startDate={
                            bobFilter ? bobFilter.commissioningDate.start : null
                        }
                        endDate={
                            bobFilter ? bobFilter.commissioningDate.end : null
                        }
                    />
                }
            />
        )
    }

    handleChangeDate = (start, end) => {
        this.setState(
            {
                rangeDate: {
                    start: start,
                    end: end,
                },
            },
            () => {
                this.handleClosePopOver()
                this.forceUpdate()
            },
        )
    }

    renderDateRange() {
        const { rangeDate } = this.state

        return (
            <ButtonFilterPopOverDateRange
                key={'dateRange'}
                save={this.saveFilter}
                reset={this.resetRangeDate}
                body={
                    <BoatOnDateSelector
                        isDateRange
                        isCompact
                        onDateChange={this.handleChangeDate}
                        startDate={rangeDate?.start || null}
                        endDate={rangeDate?.end || null}
                    />
                }
            />
        )
    }

    renderRangeHour() {
        return (
            <ButtonFilterPopOver
                body={
                    <RangeInput
                        onChange={data => {
                            this.setState({
                                rangeHour: {
                                    min: data[0] || null,
                                    max: data[1] || null,
                                },
                            })
                        }}
                        unity="h"
                        {...this.getHourLimit()}
                        label1={this.displayText('minHour')}
                        label2={this.displayText('maxHour')}
                    />
                }
            />
        )
    }

    renderChildDateReplacement() {
        const { bobFilter, classes } = this.props

        return (
            <ButtonFilterPopOverDateRange
                key={'dateRange'}
                classes={{ root: classes.paper }}
                save={this.saveFilter}
                reset={this.resetReplacementDate}
                body={
                    <BoatOnDateSelector
                        isDateRange
                        isCompact
                        onDateChange={(start, end) => {
                            this.setState({
                                replacementDate: {
                                    start: start,
                                    end: end,
                                },
                            })
                        }}
                        startDate={
                            bobFilter ? bobFilter.replacementDate.start : null
                        }
                        endDate={
                            bobFilter ? bobFilter.replacementDate.end : null
                        }
                    />
                }
            />
        )
    }

    renderUserAttachedRole() {
        const { bobFilter, groupMembers } = this.props
        const keys = { secondary: 'userSubscribe', primary: 'user' }
        const selected = {
            [keys.secondary]: [...bobFilter.userAttachedUserRole],
            [keys.primary]: [...bobFilter.userAttachedRoleType],
        }
        const url = window.location.pathname
        const context = getContextFromUrl(url)

        return (
            <FilterSearch
                isNotAttributed
                options={groupMembers
                    .map(member => ({
                        userRole: member?.userRole,
                        user: member?.user
                            ? {
                                  ...member.user,
                                  noTranslation:
                                      member?.user?.firstName &&
                                      member?.user.lastName
                                          ? member.user.firstName +
                                            ' ' +
                                            member.user.lastName
                                          : '',
                              }
                            : null,
                        userSubscribe: member?.userSubscribe
                            ? {
                                  ...member.userSubscribe,
                                  name: member?.userSubscribe?.mail || '',
                              }
                            : null,
                    }))
                    .sort((aMember, bMember) =>
                        aMember?.userRole?.id && bMember?.userRole?.id
                            ? aMember.userRole.id - bMember.userRole.id
                            : -1,
                    )}
                selected={selected}
                twoArrayToFill
                onChange={({ primary, secondary }) => {
                    this.setState({
                        userAttachedRoleType: primary,
                        userAttachedUserRole: secondary,
                    })
                }}
                keys={keys}
                groupBy={option => {
                    const findMember = groupMembers.find(
                        member =>
                            (member?.user?.id &&
                                option?.user?.id &&
                                option.user.id === member.user.id) ||
                            (member?.userSubscribe?.id &&
                                option?.userSubscribe?.id &&
                                option.userSubscribe.id ===
                                    member.userSubscribe.id),
                    )
                    return findMember?.userRole?.userRole
                        ? findMember.userRole.userRole
                        : findMember?.userRole?.translation
                        ? this.displayTextApi(findMember.userRole.translation)
                        : ''
                }}
                filterOptions={(options, input) => {
                    return options.filter(
                        option =>
                            (option?.user?.noTranslation &&
                                option.user.noTranslation
                                    .toLowerCase()
                                    .includes(
                                        input.inputValue.toLowerCase(),
                                    )) ||
                            (option?.userSubscribe?.name &&
                                option.userSubscribe.name
                                    .toLowerCase()
                                    .includes(
                                        input.inputValue.toLowerCase(),
                                    )) ||
                            groupMembers.find(
                                member =>
                                    (member?.userRole?.translation?.[context]
                                        ?.toLowerCase()
                                        ?.includes(
                                            input.inputValue.toLowerCase(),
                                        ) ||
                                        member?.userRole?.userRole
                                            ?.toLowerCase()
                                            ?.includes(
                                                input.inputValue.toLowerCase(),
                                            )) &&
                                    ((member?.user?.id &&
                                        option?.user?.id &&
                                        option.user.id === member.user.id) ||
                                        (member?.userSubscribe?.id &&
                                            option?.userSubscribe?.id &&
                                            option.userSubscribe.id ===
                                                member.userSubscribe.id)),
                            ),
                    )
                }}
            />
        )
    }

    renderManagerSearch() {
        const { groupMembers, bobFilter } = this.props
        const keys = { secondary: 'userSubscribe', primary: 'user' }
        const selected = {
            [keys.secondary]: [...bobFilter.unregisteredManagerSearch],
            [keys.primary]: [...bobFilter.userManagerSearch],
        }
        const url = window.location.pathname
        const context = getContextFromUrl(url)

        return (
            <FilterSearch
                options={groupMembers
                    .map(member => ({
                        userRole: member?.userRole,
                        user: member?.user
                            ? {
                                  ...member.user,
                                  noTranslation:
                                      member?.user?.firstName &&
                                      member?.user.lastName
                                          ? member.user.firstName +
                                            ' ' +
                                            member.user.lastName
                                          : '',
                              }
                            : null,
                        userSubscribe: member?.userSubscribe
                            ? {
                                  ...member.userSubscribe,
                                  name: member?.userSubscribe?.mail || '',
                              }
                            : null,
                    }))
                    .sort((aMember, bMember) =>
                        aMember?.userRole?.id && bMember?.userRole?.id
                            ? aMember.userRole.id - bMember.userRole.id
                            : -1,
                    )}
                isNotAttributed
                selected={selected}
                twoArrayToFill
                onChange={({ secondary, primary }) => {
                    this.setState({
                        userManagerSearch: primary,
                        unregisteredManagerSearch: secondary,
                    })
                }}
                keys={keys}
                groupBy={option => {
                    const findMember = groupMembers.find(
                        member =>
                            (member?.user?.id &&
                                option?.user?.id &&
                                option.user.id === member.user.id) ||
                            (member?.userSubscribe?.id &&
                                option?.userSubscribe?.id &&
                                option.userSubscribe.id ===
                                    member.userSubscribe.id),
                    )
                    return findMember?.userRole?.userRole
                        ? findMember.userRole.userRole
                        : findMember?.userRole?.translation
                        ? this.displayTextApi(findMember.userRole.translation)
                        : ''
                }}
                filterOptions={(options, input) => {
                    return options.filter(
                        option =>
                            (option?.user?.noTranslation &&
                                option.user.noTranslation
                                    .toLowerCase()
                                    .includes(
                                        input.inputValue.toLowerCase(),
                                    )) ||
                            (option?.userSubscribe?.name &&
                                option.userSubscribe.name
                                    .toLowerCase()
                                    .includes(
                                        input.inputValue.toLowerCase(),
                                    )) ||
                            groupMembers.find(
                                member =>
                                    (member?.userRole?.userRole
                                        ?.toLowerCase()
                                        ?.includes(
                                            input.inputValue.toLowerCase(),
                                        ) ||
                                        member?.userRole?.translation?.[context]
                                            ?.toLowerCase()
                                            ?.includes(
                                                input.inputValue.toLowerCase(),
                                            )) &&
                                    ((member?.user?.id &&
                                        option?.user?.id &&
                                        option.user.id === member.user.id) ||
                                        (member?.userSubscribe?.id &&
                                            option?.userSubscribe?.id &&
                                            option.userSubscribe.id ===
                                                member.userSubscribe.id)),
                            ),
                    )
                }}
            />
        )
    }

    _renderOrderStatus() {
        const { bobFilter, orderStatusTypes } = this.props
        const options = orderStatusTypes

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.orderStatusTypesChecked]}
                onChange={orderStatusTypesChecked =>
                    this.setState({ orderStatusTypesChecked })
                }
            />
        )
    }

    _renderOrderOperator() {
        const { bobFilter, orders } = this.props

        const seen = new Set()
        const options = orders
            .map(order => {
                if (!seen.has(order.operator)) {
                    seen.add(order.operator)
                    return {
                        name: order.operator,
                        id: order.operator,
                    }
                }
            })
            .filter(Boolean)

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.orderOperatorChecked]}
                onChange={orderOperatorChecked =>
                    this.setState({ orderOperatorChecked })
                }
            />
        )
    }

    _renderEquipmentParts() {
        const { bobFilter, parts } = this.props

        const options =
            (parts || []).map(part => ({
                id: part?.id,
                group: part?.equipment?.equipmentType?.translation,
                translation: part?.partType?.translation,
            })) ?? []

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.equipmentPartsChecked]}
                onChange={equipmentPartsChecked =>
                    this.setState({ equipmentPartsChecked })
                }
                groupBy={option => {
                    return this.displayTextApi(option.group)
                }}
            />
        )
    }

    _renderNavigationStatus() {
        const { bobFilter } = this.props

        const options = [
            {
                id: 'navigationInProgress',
                translation: {
                    id: 1,
                    fr: 'Navigation en cours',
                    en: 'Navigation in progress',
                },
            },
            {
                id: 'navigationStopped',
                translation: {
                    id: 2,
                    fr: `À l'arret`,
                    en: 'Stop',
                },
            },
        ]

        return (
            <FilterSearch
                options={options}
                selected={[...bobFilter.navigationStatusChecked]}
                onChange={navigationStatusChecked =>
                    this.setState({ navigationStatusChecked })
                }
            />
        )
    }

    filterButton() {
        const { activeButton, bobFilter, buttonLabelOverride = {} } = this.props
        const arrayButton = []

        if (activeButton) {
            const {
                stockPart,
                equipmentFamily,
                equipmentParts,
                comissionDate,
                replacementDate,
                rangeDate,
                checkupType,
                transactionType,
                rangeAmount,
                rangeHour,
                equipment,
                userAttachedRole,
                managerSearch,
                workEventType, //Type d'évenement
                absenceState, //Statut
                workTime, //Temps de travail
                crew,
                boat,
                boatByFloat,
                boatsByFloat,
                orderStatus, //Status de la commande
                orderOperator, //fournisseur de commande
                navigationStatus,
            } = activeButton

            if (stockPart) {
                arrayButton.push({
                    used:
                        bobFilter.toRenew ||
                        bobFilter.missing ||
                        bobFilter.inStock,
                    child: this.renderChildStock(),
                    name:
                        buttonLabelOverride.stockPart ??
                        this.displayText('filterBtn'),
                    reset: this.resetStockPart,
                    nbUsed:
                        bobFilter.toRenew +
                        bobFilter.missing +
                        bobFilter.inStock,
                })
            }
            if (equipmentFamily) {
                arrayButton.push({
                    used:
                        bobFilter.equipmentFamilyType.length +
                            bobFilter.userEquipmentFamily.length >
                        0,
                    child: this.renderChildFamilyEquipement(),
                    name:
                        buttonLabelOverride?.equipmentFamily ??
                        this.displayText('familyEquipement'),
                    reset: this.resetEquipmentFamily,
                    nbUsed:
                        bobFilter.equipmentFamilyType.length +
                        bobFilter.userEquipmentFamily.length,
                })
            }
            if (equipment) {
                arrayButton.push({
                    used:
                        bobFilter.equipmentType.length +
                            bobFilter.userEquipment.length >
                        0,
                    child: this.renderChildEquipement(),
                    name:
                        buttonLabelOverride?.equipment ??
                        this.displayText('equipments'),
                    reset: this.resetEquipment,
                    nbUsed:
                        bobFilter.equipmentType.length +
                        bobFilter.userEquipment.length,
                })
            }
            if (checkupType) {
                arrayButton.push({
                    used: bobFilter.checkupTypesChecked.length > 0,
                    child: this.renderCheckupType(),
                    name:
                        buttonLabelOverride?.checkupType ??
                        this.displayText('checkupType'),
                    reset: this.resetCheckupType,
                    nbUsed: bobFilter.checkupTypesChecked.length,
                })
            }
            if (transactionType) {
                arrayButton.push({
                    used: bobFilter.checkupTypesChecked.length > 0,
                    child: this.renderTransactionType(),
                    name:
                        buttonLabelOverride?.transactionType ??
                        this.displayText('transactionType'),
                    reset: this.resetCheckupType,
                    nbUsed: bobFilter.checkupTypesChecked.length,
                })
            }
            if (comissionDate) {
                arrayButton.push({
                    used:
                        bobFilter.commissioningDate.start ||
                        bobFilter.commissioningDate.end,
                    child: this.renderChildCommissioningDate(),
                    name:
                        buttonLabelOverride?.comissionDate ??
                        this.displayText('dateMise'),
                    reset: this.resetCommissionDate,
                    nbUsed:
                        bobFilter.commissioningDate.start ||
                        bobFilter.commissioningDate.end
                            ? 1
                            : 0,
                })
            }
            if (replacementDate) {
                arrayButton.push({
                    used:
                        bobFilter.replacementDate.start ||
                        bobFilter.replacementDate.end,
                    child: this.renderChildDateReplacement(),
                    name:
                        buttonLabelOverride?.replacementDate ??
                        this.displayText('dateRemplacement'),
                    reset: this.resetReplacementDate,
                    nbUsed:
                        bobFilter.replacementDate.start ||
                        bobFilter.replacementDate.end
                            ? 1
                            : 0,
                })
            }
            if (rangeDate) {
                arrayButton.push({
                    used: bobFilter.rangeDate.start || bobFilter.rangeDate.end,
                    child: this.renderDateRange(),
                    name:
                        buttonLabelOverride?.rangeDate ??
                        this.displayText('dates'),
                    reset: this.resetRangeDate,
                    nbUsed:
                        bobFilter.rangeDate.start || bobFilter.rangeDate.end
                            ? 1
                            : 0,
                })
            }
            if (rangeHour) {
                arrayButton.push({
                    used: bobFilter.rangeHour.min || bobFilter.rangeHour.max,
                    child: this.renderRangeHour(),
                    name:
                        buttonLabelOverride?.rangeHour ??
                        this.displayText('hours'),
                    reset: this.resetRangeHour,
                    nbUsed:
                        bobFilter.rangeHour.min || bobFilter.rangeHour.max
                            ? 1
                            : 0,
                })
            }
            if (rangeAmount) {
                arrayButton.push({
                    used:
                        bobFilter.rangeAmount.min || bobFilter.rangeAmount.max,
                    child: this.renderAmount(),
                    name:
                        buttonLabelOverride?.rangeAmount ??
                        this.displayText('amount'),
                    reset: this.resetRangeAmount,
                    nbUsed:
                        bobFilter.rangeAmount.min || bobFilter.rangeAmount.max
                            ? 1
                            : 0,
                })
            }
            if (userAttachedRole) {
                arrayButton.push({
                    used:
                        bobFilter.userAttachedRoleType.length +
                            bobFilter.userAttachedUserRole.length >
                        0,
                    child: this.renderUserAttachedRole(),
                    name:
                        buttonLabelOverride.userAttachedRole ??
                        this.displayText('userAttachedRole'),
                    reset: this.resetUserAttachedRole,
                    nbUsed:
                        bobFilter.userAttachedRoleType.length +
                        bobFilter.userAttachedUserRole.length,
                })
            }
            if (managerSearch) {
                arrayButton.push({
                    used:
                        bobFilter.userManagerSearch.length +
                            bobFilter.unregisteredManagerSearch.length >
                        0,
                    child: this.renderManagerSearch(),
                    name:
                        buttonLabelOverride?.managerSearch ??
                        this.displayText('managerSearch'),
                    reset: this.resetManagerSearch,
                    nbUsed:
                        bobFilter.userManagerSearch.length +
                        bobFilter.unregisteredManagerSearch.length,
                })
            }
            if (workEventType) {
                arrayButton.push({
                    used: bobFilter.workEventTypesChecked.length > 0,
                    child: this.renderWorkEventType(),
                    name:
                        buttonLabelOverride?.workEventType ??
                        this.displayText('workEventType'),
                    reset: this.resetWorkType,
                    nbUsed: bobFilter.workEventTypesChecked.length,
                })
            }
            if (absenceState) {
                arrayButton.push({
                    used: bobFilter.absenceStatesChecked.length > 0,
                    child: this.renderAbsenceState(),
                    name:
                        buttonLabelOverride?.absenceState ??
                        this.displayText('absenceState'),
                    reset: this.resetAbsenceState,
                    nbUsed: bobFilter.absenceStatesChecked.length,
                })
            }
            if (workTime) {
                arrayButton.push({
                    used: bobFilter.workTimeOptionsChecked.length > 0,
                    child: this.renderWorkTime(),
                    name:
                        buttonLabelOverride?.workTime ??
                        this.displayText('workTime'),
                    reset: this.resetWorkTime,
                    nbUsed: bobFilter.workTimeOptionsChecked.length,
                })
            }
            if (boat) {
                arrayButton.push({
                    used: bobFilter.selectedBoat?.length > 0,
                    child: this.renderBoatFilter(),
                    name:
                        buttonLabelOverride?.boat ??
                        this.displayText('boatSelector'),
                    reset: this.resetSelectedBoat,
                    nbUsed: bobFilter.selectedBoat?.length,
                })
            }
            if (boatByFloat) {
                arrayButton.push({
                    used: bobFilter.selectedBoatByFleet?.length > 0,
                    child: this.renderBoatFilterByFloat(),
                    name:
                        buttonLabelOverride?.boat ??
                        this.displayText('boatSelector'),
                    reset: this.resetSelectedBoatByFleet,
                    nbUsed: bobFilter.selectedBoatByFleet?.length,
                })
            }
            if (boatsByFloat) {
                arrayButton.push({
                    used: bobFilter.selectedBoatsByFleet?.length > 0,
                    child: this.renderBoatsFilterByFloat(),
                    name:
                        buttonLabelOverride?.boat ??
                        this.displayText('boatsSelector'),
                    reset: this.resetSelectedBoatsByFleet,
                    nbUsed: bobFilter.selectedBoatsByFleet?.length,
                })
            }
            if (crew) {
                arrayButton.push({
                    used: bobFilter.selectedCrew.length > 0,
                    child: this.renderCrewFilter(),
                    name:
                        buttonLabelOverride?.crew ??
                        this.displayText('crewSelector'),
                    reset: this.resetSelectedCrew,
                    nbUsed: bobFilter.selectedCrew.length,
                })
            }

            if (orderStatus) {
                arrayButton.push({
                    used: bobFilter.orderStatusTypesChecked?.length > 0,
                    child: this._renderOrderStatus(),
                    name:
                        buttonLabelOverride?.orderStatus ??
                        this.displayText('orderStatusSelector'),
                    reset: this.resetSelectedOrderStatus,
                    nbUsed: bobFilter.orderStatusTypesChecked?.length,
                })
            }

            if (orderOperator) {
                arrayButton.push({
                    used: bobFilter.orderOperatorChecked?.length > 0,
                    child: this._renderOrderOperator(),
                    name:
                        buttonLabelOverride?.orderOperator ??
                        this.displayText('orderOperatorSelector'),
                    reset: this.resetSelectedOrderOperator,
                    nbUsed: bobFilter.orderOperatorChecked?.length,
                })
            }

            if (equipmentParts) {
                arrayButton.push({
                    used: bobFilter.equipmentPartsChecked?.length > 0,
                    child: this._renderEquipmentParts(),
                    name:
                        buttonLabelOverride?.parts ??
                        this.displayText('equipmentPartsSelector'),
                    reset: this.resetSelectedEquipmentParts,
                    nbUsed: bobFilter.equipmentPartsChecked?.length,
                })
            }

            if (navigationStatus) {
                arrayButton.push({
                    used: bobFilter.navigationStatusChecked?.length > 0,
                    child: this._renderNavigationStatus(),
                    name:
                        buttonLabelOverride?.navigationStatus ??
                        this.displayText('navigationStatusSelector'),
                    reset: this.resetNavigationStatus,
                    nbUsed: bobFilter.navigationStatusChecked?.length,
                })
            }
        }

        if (arrayButton.length === 0) return null
        return arrayButton
    }

    isStockPartUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.stockPart &&
            (bobFilter.toRenew || bobFilter.missing || bobFilter.inStock)
        )
    }

    isEquipmentFamilyUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.equipmentFamily &&
            bobFilter.equipmentFamilyType.length +
                bobFilter.userEquipmentFamily.length >
                0
        )
    }

    isEquipmentUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.equipment &&
            bobFilter.equipmentType.length + bobFilter.userEquipment.length > 0
        )
    }

    isCheckupTypeUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.checkupType && bobFilter.checkupTypesChecked.length > 0
        )
    }

    isCommissionDateUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.comissionDate &&
            (bobFilter.commissioningDate.start ||
                bobFilter.commissioningDate.end)
        )
    }

    isReplacementDateUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.replacementDate &&
            (bobFilter.replacementDate.start || bobFilter.replacementDate.end)
        )
    }

    isRangeDateUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.rangeDate &&
            (bobFilter.rangeDate.start || bobFilter.rangeDate.end)
        )
    }

    isRangeHourUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.rangeHour &&
            (bobFilter.rangeHour.min || bobFilter.rangeHour.max)
        )
    }

    isRangeAmountUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.rangeAmount &&
            (bobFilter.rangeAmount.min || bobFilter.rangeAmount.max)
        )
    }

    isUserAttachedRoleUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.userAttachedRole &&
            bobFilter.userAttachedRoleType.length +
                bobFilter.userAttachedUserRole.length >
                0
        )
    }

    isManagerSearchUsed() {
        const { bobFilter, activeButton } = this.props
        return (
            activeButton.managerSearch &&
            bobFilter.userManagerSearch.length +
                bobFilter.unregisteredManagerSearch.length >
                0
        )
    }

    countNbElementsUsed() {
        const { activeButton } = this.props
        let nb = 0

        if (activeButton) {
            if (this.isStockPartUsed()) nb += 1
            if (this.isEquipmentFamilyUsed()) nb += 1
            if (this.isEquipmentUsed()) nb += 1
            if (this.isCheckupTypeUsed()) nb += 1
            if (this.isCommissionDateUsed()) nb += 1
            if (this.isReplacementDateUsed()) nb += 1
            if (this.isRangeDateUsed()) nb += 1
            if (this.isRangeHourUsed()) nb += 1
            if (this.isRangeAmountUsed()) nb += 1
            if (this.isUserAttachedRoleUsed()) nb += 1
            if (this.isManagerSearchUsed()) nb += 1

            return nb || null
        }
        return null
    }

    // Met à jour le bobFilter uniquement 300ms après que l'utilisateur ait terminé d'écrire
    // Permet d'améliorer la fluidité
    handleTextInputChange(e) {
        const { timer } = this.state

        this.setState({
            searchString: e.target.value,
        })

        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            this.props.dispatch(
                filterActions.updateFilterBob({
                    searchString: e.target.value,
                }),
            )
        }, 300)

        this.setState({
            timer: newTimer,
        })
    }

    _renderFilterButtons(mode, filtersButton) {
        const { classes, isMobile } = this.props

        return (
            <div
                className={
                    this.state.filters &&
                    ((mode === 'desktop' && !isMobile) ||
                        (mode === 'mobile' && isMobile))
                        ? classes.filtersBar
                        : classes.hidde
                }
            >
                {filtersButton &&
                    filtersButton.map((filter, i) => (
                        <Button
                            onCancel={filter.reset}
                            isCancelActived={filter.used}
                            selectedCount={filter.nbUsed || null}
                            key={`button${i}`}
                            label={filter.name}
                            type={filter.used ? 'primary' : 'light'}
                            variant="contained"
                            size="small"
                            style={{
                                marginLeft: 0,
                                marginBlock: '3px',
                            }}
                            onClick={e =>
                                this.handleOpenPopOver(e, filter.child)
                            }
                        />
                    ))}
            </div>
        )
    }

    render() {
        const {
            classes,
            checkedBoxes,
            checkedBoxesWorktime,
            unfoldAll,
            getNbEquipment,
            repairUnfoldChecked = false,
            rightComponent = undefined,
            editableButton,
            editableButtonDropDown,
            editableButtonBlockIds,
            editableButtonLabel,
            blockOnlyOneId,
            editableButtonOnClick,
            isAllChecked = false,
            noDivider = false,
            searchInput = true,
            totalUnfold,
            checkAll,
            nbCheckedEvents,
            isMobile,
        } = this.props

        const filtersButton = this.filterButton()
        const nbEquipment = getNbEquipment ? getNbEquipment() : 0
        const nbActiveFilters = this.countNbElementsUsed()

        return (
            <div className={classes.root}>
                <div className={classes.secondLine}>
                    <div className={classes.searchFilterUnfold}>
                        <div className={classes.searchAndFilter}>
                            {/* Partie rechercher */}
                            {searchInput && (
                                <div className={classes.recherche}>
                                    <TextField
                                        id="search"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder={this.displayText('search')}
                                        className={classes.field}
                                        onBlur={_ => fixIosHeaderBug()}
                                        value={this.state.searchString}
                                        onChange={this.handleTextInputChange}
                                    />
                                </div>
                            )}
                            {/* Fin partie rechercher */}

                            {/* Bouton "Plus de filtre" */}
                            {filtersButton && (
                                <div
                                    className={classes.filter}
                                    style={
                                        !searchInput && isMobile
                                            ? {
                                                  width: '100%',
                                              }
                                            : undefined
                                    }
                                >
                                    <Button
                                        classNameOverride={
                                            classes.filtersButton
                                        }
                                        onCancel={this.handleResetFilters}
                                        isCancelActived={nbActiveFilters > 0}
                                        selectedCount={nbActiveFilters}
                                        label={this.displayText('moreFilters')}
                                        variant="contained"
                                        type="primary"
                                        size="small"
                                        fullWidth={true}
                                        onClick={this.handleFilters}
                                        startIcon={
                                            !this.state.filters ? (
                                                <KeyboardArrowDown />
                                            ) : (
                                                <KeyboardArrowUp />
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {this._renderFilterButtons('mobile', filtersButton)}

                        {/* Injection du composant dans l'emplacement de droite */}
                        {rightComponent && rightComponent}

                        {/* Fin bouton "Plus de filtre" */}
                        {editableButton && (
                            <BoatOnBlock
                                blockIds={editableButtonBlockIds}
                                onlyOneId={blockOnlyOneId}
                                children={
                                    <Button
                                        classNameOverride={
                                            classes.editableButton
                                        }
                                        dropDown={editableButtonDropDown}
                                        variant="contained"
                                        color="primary"
                                        label={editableButtonLabel}
                                        size={`medium`}
                                        onClick={editableButtonOnClick}
                                    />
                                }
                            />
                        )}
                    </div>
                </div>
                {this._renderFilterButtons('desktop', filtersButton)}
                <div
                    className={classes.secondLine}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        {/* Section checkbox "Tout sélectionner" */}
                        {checkedBoxes && (
                            <>
                                <Checkbox
                                    checked={
                                        nbCheckedEvents !== undefined
                                            ? nbCheckedEvents >=
                                                  this.props.nbEvent &&
                                              this.props.nbEvent > 0
                                            : checkedBoxes.length >=
                                                  this.props.nbEvent &&
                                              this.props.nbEvent > 0
                                            ? true
                                            : false
                                    }
                                    onChange={e =>
                                        this.props.selectAll(e.target.checked)
                                    }
                                    color="primary"
                                />
                                {this.displayText('selectAll')}
                            </>
                        )}
                        {checkedBoxesWorktime && (
                            <>
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={e => checkAll(e.target.checked)}
                                    color="primary"
                                />
                                {this.displayText('selectAll')}
                            </>
                        )}
                    </div>
                    {unfoldAll && (
                        <div>
                            {this.displayText('unfoldAll')}
                            <FormControlLabel
                                classes={{ root: classes.controlLabel }}
                                control={
                                    <Switch
                                        color="primary"
                                        className={classes.controlLabel}
                                        onChange={e => {
                                            this.props.handleUnfoldAll(
                                                e.target.checked,
                                            )
                                        }}
                                        checked={
                                            (unfoldAll.length >= nbEquipment &&
                                                nbEquipment > 0) ||
                                            repairUnfoldChecked ||
                                            totalUnfold === unfoldAll.length
                                        }
                                    />
                                }
                            />
                        </div>
                    )}
                    {/* Fin section checkbox "Tout sélectionner" */}
                </div>

                {!noDivider && <Divider className={classes.divider} />}
                <Popover
                    id={'popover'}
                    open={this.state.openPopOver}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClosePopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ marginTop: '5px' }}
                >
                    {this.state.childPopOver}
                </Popover>
            </div>
        )
    }
}

function CheckboxFilter({ handleChange, checked }) {
    const [value, setChange] = useState(checked)
    return (
        <Checkbox
            checked={value}
            color="primary"
            onChange={e => {
                handleChange(e.target.checked)
                setChange(!value)
            }}
        />
    )
}

function mapStateToProps(state, myprops) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
        bobFilter: state.filter.bobFilter,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                rangeDate: true,
                checkupTypesChecked: false,
            },
            myprops.repair,
        ),
        boat: state.bob.boat,
        eventTypesCheckup: typesActions.getEventTypeCheckup(
            state.types.eventTypes,
        ),
        eventTypesTransaction: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        absenceStates: typesActions.getAbsenceStates(state.types.eventTypes),
        roles: state.group.roles || [],
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        leaveTypes: state.types.leaveTypes,
        orderStatusTypes: state.types.orderStatusTypes,
        groupId: state.group.currentGroupId,
        orders: state.bobOrder.orders,
        navigations: state.logbook.navigations,
        parts: state.types.userParts,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BobFilter))
