import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import BoatOnModal from '../../../../common/BoatOnModal'

import dictionary from '../Dictionary/LogBookListDico'
import LogBookModal from './LogBookModal'
import LogBookAISModal from './LogBookAISModal'
import LogBookManualSetupModal from './LogBookManualSetupModal'

import { addNewNavigation } from '../../../../../actions/bob/navigation.actions'
import { putBOBEvents } from '../../../../../actions/bob.actions'
import { boatActions } from '../../../../../actions/boat.actions'

import { withStyles } from '@material-ui/core/styles'
import styles from '../Styles/LogBookListCss'

class LogBookCreateNav extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedModal: this.props.selectedModal,
            createdNavigation: null,
        }

        this.cancelCreation = this.cancelCreation.bind(this)
        this.openNewNavigation = this.openNewNavigation.bind(this)
        this.closeNewNavigation = this.closeNewNavigation.bind(this)
        this.saveAISModal = this.saveAISModal.bind(this)
        this.cancelManualSetup = this.cancelManualSetup.bind(this)
        this.getUpdatedEvents = this.getUpdatedEvents.bind(this)
        this.saveManualSetup = this.saveManualSetup.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(boatActions.requestLinkedBoats())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedModal === false && this.props.selectedModal)
            this.setState({ selectedModal: this.props.selectedModal })

        if (
            prevState.selectedModal !== false &&
            this.state.selectedModal === false &&
            this.props.closeNavigation
        )
            this.props.closeNavigation()
    }

    openNewNavigation() {
        this.setState({ selectedModal: 'create' })
    }

    closeNewNavigation(navigation) {
        if (!navigation) {
            this.setState({
                selectedModal: false,
                createdNavigation: null,
            })
        } else {
            this.setState({
                selectedModal: 'AISUsed',
                createdNavigation: navigation,
            })
        }
    }

    saveAISModal(AIS) {
        let createdNavigation = { ...this.state.createdNavigation }

        createdNavigation.isAisUsed = AIS

        this.setState(
            {
                createdNavigation,
                selectedModal: AIS ? false : 'manualSetup',
            },
            () => {
                if (AIS) this.save()
            },
        )
    }

    cancelManualSetup() {
        this.setState({
            selectedModal: 'AISUsed',
        })
    }

    saveManualSetup(travelDetails) {
        let createdNavigation = { ...this.state.createdNavigation }

        createdNavigation = { ...createdNavigation, ...travelDetails }

        this.setState(
            {
                createdNavigation: createdNavigation,
                selectedModal: false,
            },
            this.save,
        )
    }

    getUpdatedEvents() {
        const { createdNavigation } = this.state
        const events = JSON.parse(JSON.stringify(this.props.events))

        const workingEquipmentHours = createdNavigation.workingEquipmentHours
        const workingEquipmentHoursID = workingEquipmentHours.map(
            item => item.equipment.id,
        )
        const eventsHours = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    workingEquipmentHoursID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            if (!equipment) return false
            if (equipment.hours <= event.detail.hourActual) return false

            return true
        })

        const fillingLevels = createdNavigation.fillingLevels
        const fillingLevelsID = fillingLevels.map(item => item.equipment.id)

        const eventsFilledLevel = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    fillingLevelsID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (!equipment) return false

            if (equipment.level === event.detail.optionnalDetail?.fillingLevel)
                return false

            return true
        })

        let eventsToUpdate = eventsHours.concat(eventsFilledLevel)

        eventsToUpdate = eventsToUpdate.reduce((map, event) => {
            map[event.id] = event
            return map
        }, {})

        eventsToUpdate = Object.values(eventsToUpdate)

        const updatedEvent = eventsToUpdate.map(event => {
            const equipmentHours = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (
                equipmentHours &&
                event.detail.hourActual < equipmentHours.hours
            )
                event.detail.hourActual = equipmentHours.hours

            const equipmentFillingLevel = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            const optionnalDetail = event.detail.optionnalDetail
            const optionnalDetailActivated =
                event.detail?.equipment?.equipmentType?.optionnalDetailActivated

            if (
                equipmentFillingLevel &&
                optionnalDetailActivated?.fillingLevel
            ) {
                if (
                    optionnalDetail?.fillingLevel &&
                    optionnalDetail.fillingLevel !== equipmentFillingLevel.level
                )
                    event.detail.optionnalDetail.fillingLevel =
                        equipmentFillingLevel.level
                else {
                    event.detail.optionnalDetail = {
                        ...event.detail.optionnalDetail,
                        fillingLevel: equipmentFillingLevel.level,
                    }
                }
            }

            return event
        })

        return updatedEvent
    }

    save() {
        const { createdNavigation } = this.state

        this.props.dispatch(addNewNavigation(createdNavigation))
        const eventsToUpdate = this.getUpdatedEvents()

        eventsToUpdate.forEach(event => {
            this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    detail: event.detail,
                }),
            )
        })
    }

    cancelCreation() {
        this.setState({
            selectedModal: false,
            createdNavigation: null,
        })
    }

    render() {
        const { selectedModal } = this.state

        return (
            <BoatOnModal
                openCondition={selectedModal}
                handleClose={this.cancelCreation}
                modalCores={{
                    create: (
                        <LogBookModal
                            onClose={this.closeNewNavigation}
                            noCross={false}
                        />
                    ),
                    AISUsed: (
                        <LogBookAISModal
                            handleValid={this.saveAISModal}
                            noCross={false}
                        />
                    ),
                    manualSetup: (
                        <LogBookManualSetupModal
                            onCancel={this.cancelManualSetup}
                            handleValid={this.saveManualSetup}
                            noCross={false}
                        />
                    ),
                }}
                maxWidth={{
                    create: 'sm',
                    AISUsed: 'sm',
                    manualSetup: 'sm',
                }}
                titles={{
                    create: this.displayText('newNavigation'),
                    AISUsed: this.displayText('AISUsed'),
                    manualSetup: this.displayText('manualSetup'),
                }}
            />
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600
    const bobFilter = state.filter.bobFilter

    return {
        isMobile,
        bobFilter,
        loading: state.logbook.loading,
        groupId: state.group?.currentGroupId,
        events: state.bob.events || [],
        ais: state.navigationApiData.ais || [],
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookCreateNav))
